import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";


export function EntitySelectDropdown({ entityPrompt = 'Entity', availableOptions, selectedOptions, multiselect = false, selectionsChangedCallback }) {
  const { t } = useTranslation();
  const [currentSelections, setCurrentSelections] = useState(selectedOptions);



  return (
    <FormControl sx={{ width: '100%', marginTop: 5 }}>
      <InputLabel id="prodselect-label">
        {t(entityPrompt)}
      </InputLabel>
      <Select
        labelId="optselect-label"
        id="opt-select"
        multiple={multiselect}
        value={currentSelections}
        label={t('Select')}
        onChange={async function (e) {
          console.log(`Selected option ${e.target.value}`);
          console.log(e.target);
          console.log(`Current selections ${currentSelections}`);

          //const newSelections=[].concat(currentSelections);
          //setCurrentSelections(newSelections);
          setCurrentSelections(e.target.value);
          selectionsChangedCallback(e.target.value);
        }}
      >
        {availableOptions.map((o, i) => <MenuItem key={o.uuid + entityPrompt} value={o.code}>{o.code + ' ' + o.name}</MenuItem>
        )}
      </Select>
    </FormControl>

  );
}
