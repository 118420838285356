import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {Checkbox, IconButton, Box, TextField} from "@mui/material";
import DownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import UpIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import Paper from "@mui/material/Paper";

import "./ProductCard.css";

import { useTranslation } from "react-i18next";

import { useUserContext } from "./UserContext";


import { getLocalizedCatTextIfExists, formatInEuros } from "./UtilFunctions";

import ImagePanel from './ImagePanel';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from "./TenderStore";



function CatManOptionsPanel({tender, product, dispatch}) {
  const { t } = useTranslation();
  const { catalogData, productOptions } = tender;
  const {
    state: { token,username },
  } = useUserContext();


  const [openedOptionDetails, setOpenedOptionDetails] = useState('none');


  catalogData.options?.sort((a,b) => { 
      // normalize codes by adding option code with trailing aaaa's
      return ((""+a.code+"                            ").substring(0,32) < (""+b.code+"                            ").substring(0,32)?-1:1);
    });

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 400, maxWidth: 800 }}
        aria-label="options table"
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell>{t('Code')}</TableCell>
            <TableCell align="center">{t('Select')}</TableCell>            
            <TableCell width="70%" align="left">{t('Option')}</TableCell>
            <TableCell align="right">{t('Price')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productOptions && catalogData.options?.map((option,index) => (
            (((""+option.code).split('_').length>1 && (""+option.code).split('_')[1]===""+product.code) ||
            ((""+option.code).split('_').length==1 && catalogData.options.find(o => o.code===option.code+"_"+product.code)===undefined)) &&
            <TableRow
              key={option.code}
            >
              <TableCell component="th" scope="row">
                {option.code}
                <Button onClick={()=> {
                  let newSelectedOptions=productOptions;
                  if(option.overrideForProduct===undefined) {
                    console.log("Override "+option.code+"_"+product.code);

                    const newOpt={
                      code: option.code+"_"+product.code,
                      name: option.name,
                      description: option.description,
                      image: option.image,
                      price: option.price,
                      overrideForProduct: product.code
                    };
                    catalogData.options.push(newOpt);
                    newSelectedOptions.push(newOpt);
                  } else {
                    // undo override
                    console.log("Undo override for option "+option.code);
                    catalogData.options=catalogData.options.filter((o)=> (o.code!==option.code));
                    newSelectedOptions=newSelectedOptions.filter((o)=> (o.code!==option.code));
                  }                  
                  catalogData.options?.sort((a,b) => { 
                    // normalize codes by adding option code with trailing aaaa's
                    return ((""+a.code+"                            ").substring(0,32) < (""+b.code+"                            ").substring(0,32)?-1:1);
                  });
                dispatch({type:'stageProductOptions', payload: {productOptions: newSelectedOptions}});
        
                } }>{option.overrideForProduct!==undefined?'Undo override':'Override'}</Button>
              </TableCell>
              <TableCell padding="checkbox">
                <Box style={{flexDirection:'column'}}>
                <Checkbox
                  color="primary"
                  checked={(productOptions.some(o => o.code === option.code))}
                  onChange={ (event) => {
                      console.log("Checkbox change for "+option.code);
                      console.log(productOptions);
                      console.log(option);
                      console.log("Product "+product?.code);

                      if(productOptions.includes(option)) {
                        const newSelectedOptions=productOptions.filter(o => o.code !== option.code);
                        dispatch({type:'stageProductOptions', payload: {productOptions: newSelectedOptions}});
                      } else {
                          const newSelectedOptions=productOptions.concat(catalogData.options.filter(o => o.code === option.code));
                          dispatch({type:'stageProductOptions', payload: {productOptions: newSelectedOptions}});
                      }

                    }}
                />
                </Box>
              </TableCell>
              <TableCell align="left">
                { option.overrideForProduct?
                <TextField
              key={option.code+"_"+product.code+"_name"}
              sx={{width:'90%', margin:1}}
              id={'opt-override-'+option.code+"_"+product.code+"_name"}
              label={t("Title")}
              disabled={false}                  
              multiline={false}
              defaultValue={getLocalizedCatTextIfExists(option.name,t,catalogData.name,{code:option.code},'OPT-TITLE')}
              onChange={(e) => {
                console.log("Override name change to: "+e.target.value);
                //setTextInputValue(i, e.target.value);
                }}
              />
                :
                getLocalizedCatTextIfExists(option.name,t,catalogData.name,{code:option.code},'OPT-TITLE') }
                { option.image && openedOptionDetails===option.code &&
                <>
                <IconButton color="inherit" onClick={ () => { setOpenedOptionDetails('none') }}><UpIcon /></IconButton>
                <ImagePanel width={300} imageSrc={(window.location.hostname==='localhost'?'https://tenderer.cappgw.com':'')+'/assets/catalogs/'+catalogData.name+'/assets/optionimages/'+option.image} />
                </>
                }
                { option.image && openedOptionDetails==='none' &&
                <IconButton color="inherit" onClick={ () => { setOpenedOptionDetails(option.code) }}><DownIcon /></IconButton>
                }
                
              </TableCell>
              <TableCell component="td" align="right" scope="row">
              { option.overrideForProduct?
                <TextField
              key={option.code+"_"+product.code+"_price"}
              sx={{width:'90%', margin:1}}
              id={'opt-override-'+option.code+"_"+product.code+"_price"}
              label={t("Price")}
              disabled={false}                  
              multiline={false}
              defaultValue={option.price}
              onChange={(e) => {
                console.log("Override price change to: "+e.target.value);
                option.price=e.target.value;
                dispatch({type:'saveEntity', payload: {entityType: "option", entity: option, setSelected:true, modifiedBy: username}});

                //setTextInputValue(i, e.target.value);
                }}
              />
                :
                formatInEuros(option.price)
                }
              </TableCell>
            </TableRow>
            
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const mapStateAndOwnPropsToProps = (state, ownProps) => {
  const tender = state.tender;
  return {
    tender,
    produc:ownProps.product, 
  }
};

export default connect(mapStateAndOwnPropsToProps, mapDispatchToProps)(CatManOptionsPanel);