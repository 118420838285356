import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@material-ui/core/Box";
import { connect } from 'react-redux';
import { mapDispatchToProps } from "./TenderStore";

import { useUserContext} from "./UserContext";
import {uploadImageToCloud} from "./TendererDataService";

import CloudUploadIcon from "@mui/icons-material/CloudUploadOutlined";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import AttachFileIcon from '@mui/icons-material/AttachFileOutlined';

import Resizer from "react-image-file-resizer";
import { makeStyles } from "@material-ui/core/styles";

//import {DropzoneArea} from 'react-mui-dropzone'

const useStyles = makeStyles((theme) => ({
    root: {
      height: "100%",
      textAlign: "center",
      border: '2px white solid', 
      padding: 5, 
      borderRadius: 10 
    },
    imgBox: {
      maxWidth: "80%",
      maxHeight: "80%",
      margin: "auto",
    },
    img: {
      height: "inherit",
      maxWidth: "inherit",
    },
    input: {
      display: "none",
    },
  }));


function CatManImagesSimplePanel({tender, dispatch, entity, entityType, defaultImage, onUploadDone}) {
    const classes = useStyles();
    const { state: { token }} = useUserContext();
    const { catalogData, selectedEntityModified } = tender;

    const [imageFileName, setImageFileName] = useState(undefined);
    const [imageFileData, setImageFileData] = useState(undefined);


    useEffect(()=> {
      if(defaultImage!==undefined) {
        const imageDataUrl=(window.location.hostname==='localhost'?'https://tenderer.cappgw.com':'')+'/assets/catalogs/'+catalogData.name+'/assets/'+entityType+'images/'+defaultImage;
        fetch(imageDataUrl).then(res => {
          res.arrayBuffer().then(buf => {
            const imgFilenameParts=defaultImage.split('.');
            const imgType=imgFilenameParts[imgFilenameParts.length-1];
            const file = new File([buf], defaultImage, { type: 'image/'+imgType })
            console.log("Loaded file "+imageDataUrl);
            setImageFileName(file)
          })
        
      })}
    },[]);
    
    /*
    function imagesModified(images) {
      console.log("Images Modified:");
      console.log(images);
      setImageFiles(images);
      dispatch({type:'stageImages', payload: {entityType:entityType, entity:entity, images: images}});

      console.log(images[0]?.path);
    }
*/


    function uploadImages() {
      if(imageFileName!==undefined) {
        const base64Data = new Buffer.from(imageFileData.replace(/^data:image\/\w+;base64,/, ""), 'base64');

          uploadImageToCloud(token,catalogData.name,entityType,imageFileName,base64Data, (success) => {
            console.log("Upload routine retuned:");
            console.log(success);
            flagModified();
            onUploadDone(imageFileName);
            setImageFileData(undefined);
            setImageFileName(undefined);
        });}
    }
    
    function flagModified() {
      if(selectedEntityModified===false)
        dispatch({type: 'flagEntityModified'});
    }
    const handleCapture = (target) => {
        if (target.files) {
          if (target.files.length !== 0) {
            const file = target.files[0];
            console.log("Selected file "+file.name);
    //        const newUrl = URL.createObjectURL(file);
    //        setSource(newUrl);
            // new impl
            try {
                Resizer.imageFileResizer(
                    file,
                    400,
                    400,
                    "PNG",
                    100,
                    0,
                    (uri) => {
                        console.log(uri);
                        setImageFileName(file.name);

                        setImageFileData(uri);
                    },
                    "base64"
                  );
              
            } catch(err) {
                console.log(err);
            }
          }
        }
      };
    
    return (
        <React.Fragment>
 
          {imageFileData && (
            <Box
              display="flex"
              justifyContent="center"
              border={1}
              className={classes.imgBox}
            >
              <img src={imageFileData} alt={"snap"} className={classes.img}></img>
            </Box>
          )}
                   <div style={{flexDirection:'row', justifyContent: 'center', textAlign:'center'}}>
        { imageFileData &&
          <IconButton color="inherit" onClick={() => { onUploadDone(undefined);
          }}><CancelIcon /><Typography variant="body2">Cancel</Typography></IconButton>
        }
          <input
            accept="image/*"
            className={classes.input}
            id="icon-button-file"
            type="file"
            capture="environment"
            onChange={(e) => handleCapture(e.target)}
          />
          <label htmlFor="icon-button-file">
            <IconButton
              color="primary"
              aria-label="choose image"
              component="span"
            >
              <AttachFileIcon color="primary" />
              <Typography variant="body2">Choose image</Typography>
            </IconButton>
          </label>
        
        { imageFileData &&
            <IconButton color="inherit" onClick={() => { uploadImages();
          }}><CloudUploadIcon /><Typography variant="body2">Upload</Typography></IconButton>
        }
          </div>
    </React.Fragment>
    )
}


const mapStateAndOwnPropsToProps = (state, ownProps) => {
  const tender = state.tender;
  return {
    tender,
    entityType: ownProps.entityType,
    defaultImage: ownProps.defaultImage,
    onUploadDone:ownProps.onUploadDone,
  }
};


export default connect(mapStateAndOwnPropsToProps, mapDispatchToProps)(CatManImagesSimplePanel);