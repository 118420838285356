import * as React from 'react'

const UserContext = React.createContext();

const initialState={ username: null, token: null, email: null, catalogs: [], roles: [], userDiscount:0.0 }

function userReducer(state, action) {
    switch (action.type) {
        case 'loginUser': {
            console.log("UserContext: Login user:  "+action.payload.username+" token: "+action.payload.token);
            // this means user has been successfully authenticated 
            return { ...state,username: action.payload.username, token: action.payload.token }
        }
        case 'logoutUser': {
            console.log("UserContext: Logging out user");
            return { initialState }
        }
        case 'setCatalogs': {
            console.log("Setting catalog access and claims");
            console.log(action.payload);
            return {...state, username: action.payload.username, token: action.payload.token, email: action.payload.email, catalogs: action.payload.catalogs }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function UserContextProvider({children}) {
    const [state, dispatchLogin] = React.useReducer(userReducer, initialState)

    const value = {state, dispatchLogin}

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

function useUserContext() {
    const context = React.useContext(UserContext);
    if(context===undefined) {
        throw new Error('useUserContext must be used withing a UserContextProvider')
    }
    return context;
}

export {UserContextProvider, useUserContext}
