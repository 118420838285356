export function formatInEuros(price,locale='de-DE') {
    if(price===undefined || isNaN(price))
        return '';
//    return (price*1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').toLocaleString('fi-FI')+'€';
    return (price*1.0).toLocaleString(locale,{ style: 'currency', currency: 'EUR' });
}

export function formatISODateTime(isodatetime,onlyDate=false) {
    if(isodatetime===undefined || isodatetime==='now')
        isodatetime=(new Date()).toISOString();
    return (onlyDate?isodatetime.substr(0,10):isodatetime.substr(0,10)+' '+isodatetime.substr(11,8));
}

export function asciiOnlyString(str) {
  //return str.replace(/[^\x00-\x7F]/g, "");
  return str?.replace(/[^A-Za-z 0-9]*/g, '');
}

export function sanitizeString(str) {
  //return str.replace(/[^\x00-\x7F]/g, "");
  return str?decodeURI(encodeURI(str)):undefined;
}

export   function getLocalizedCatTextIfExists(defaultText, transFunc, catalog, item, textId) {
    let transKey,ret;
    switch(textId) {
      case 'PART-TITLE':
        transKey=`PROD-${item.product.code}-PART-${item.part.name}-TITLE`;
        break;
      case 'PART-VALUE':
        transKey=`PROD-${item.product.code}-PART-${item.part.name}-VALUE`;
        break;
      case 'PROD-TITLE':
        transKey=`PROD-${item.code}-TITLE`;
        break;
      case 'OPT-TITLE':
        transKey=`OPT-${item.code}-TITLE`;
        break;
      case 'ATT-TITLE':
          transKey=`ATT-${item.code}-TITLE`;
          break;
        default:
        return '***ERROR TRANS LOC CAT TEXT***';
      }
      ret=transFunc(transKey, {ns: catalog});
      // console.log(defaultText+'->'+ret);
      if(ret!==transKey)
        return ret;
      else
        return defaultText;
  }
