import React, { useState } from "react";
import _ from "lodash";
import RGL, { WidthProvider, Responsive } from "react-grid-layout";
import { Box, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/EditOutlined";

import "./css/react-grid-layout/styles.css";
import "./css/react-resizable/styles.css";
import { YoutubeSearchedForOutlined } from "@mui/icons-material";

const ResponsiveReactGridLayout = WidthProvider(Responsive);


export default function DashboardLayout(props) {
  const [editingDashboard, setEditingDashboard] = useState(false);
  const defaultProps = {
    className: "layout",
    items: 10,
    rowHeight: 100,
    onLayoutChange: function () {},
  };
  const originalLayouts = {
    lg: [
      {
        w: 2,
        h: props.catalogCount,
        x: 0,
        y: 0,
        i: "create-tender",
        minW: 2,
        minH: 1,
        moved: false,
        static: !editingDashboard,
      },
      {
        w: 8,
        h: 5,
        x: 2,
        y: 0,
        i: "stored-tenders",
        minW: 6,
        minH: 5,
        moved: false,
        static: !editingDashboard,
      },
      {
        w: 2,
        h: 2,
        x: 11,
        y: 0,
        i: "kpi-view",
        minW: 1,
        minH: 1,
        moved: false,
        static: !editingDashboard,
      },
    ],
    sm: [
      {
        w: 2,
        h: props.catalogCount,
        x: 0,
        y: 2,
        i: "create-tender",
        minW: 2,
        minH: 1,
        moved: false,
        static: !editingDashboard,
      },
      {
        w: 2,
        h: 2,
        x: 0,
        y: 0,
        i: "kpi-view",
        minW: 2,
        minH: 1,
        moved: false,
        static: !editingDashboard,
      },
      {
        w: 4,
        h: 3,
        x: 2,
        y: 0,
        i: "stored-tenders",
        minW: 4,
        minH: 3,
        moved: false,
        static: !editingDashboard,
      },
    ],
    xss: [
      {
        w: 2,
        h: props.catalogCount,
        x: 0,
        y: 0,
        i: "create-tender",
        minW: 2,
        minH: 1,
        moved: false,
        static: !editingDashboard,
      },
      {
        w: 2,
        h: 2,
        x: 0,
        y: 10,
        i: "kpi-view",
        minW: 2,
        minH: 1,
        moved: false,
        static: !editingDashboard,
      },
      {
        w: 2,
        h: 6,
        x: 2,
        y: 4,
        i: "stored-tenders",
        minW: 2,
        minH: 6,
        moved: false,
        static: !editingDashboard,
      },
    ],
  };

  const [layouts, setLayouts] = useState(
    //JSON.parse(JSON.stringify(getFromLS("layouts"))) //JSON.stringify(originalLayouts))
    JSON.parse(JSON.stringify(originalLayouts))
    );

  function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
        if(ls && ls[key].xxs)
          return ls[key];
      } catch (e) {
        return originalLayouts;
      }
    }
    return originalLayouts;
  }

  function saveToLS(key, value) {
    if (global.localStorage) {
      global.localStorage.setItem(
        "rgl-8",
        JSON.stringify({
          [key]: value,
        })
      );
    }
  }

  function generateLayoutsDONOTUSE() {
    return [
      { i: "create-tender", x: 0, y: 0, w: 5, h: 5, static: false },
      {
        i: "stored-tenders",
        dataGrid: { x: 3, y: 0, h: 4, w: 3 },
      },
    ];
  }

  function onLayoutChange(layout, layouts) {
    setLayouts(layouts);
    console.log("Layout change:");
    console.log(layouts);
    //this.props.onLayoutChange(layout);
    saveToLS("layouts", layouts);
  }

  //console.log("Rendering dashboard..");
  return (
    <Box sx={{ ...props.sx, height: "auto", marginTop: 0, marginBottom: 0 }}>

      { false &&
      <IconButton
        size="small"
        edge="end"
        color="primary"
        aria-label="edit-dashboard"
        sx={{ position: "fixed", top: 60, right: 5 }}
        onClick={(e) => {
          console.log("Toggle edit dashboard");
          console.log(layouts);

          const newLayouts=layouts;
          newLayouts["lg"][0].static=!newLayouts["lg"][0].static;
          newLayouts["lg"][1].static=!newLayouts["lg"][1].static;
          newLayouts["lg"][2].static=!newLayouts["lg"][2].static;
              console.log(newLayouts);
              setEditingDashboard(!newLayouts["lg"][0].static);     
              onLayoutChange(null, newLayouts);         
        }}
      >
        { editingDashboard?
        <EditIcon />
        :
        <EditIcon />
        }
      </IconButton>
    }

      <ResponsiveReactGridLayout
        layouts={layouts}
        onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
        {...props}
        className="layout"
        autoSize={true}
        breakpoints={{ lg: 1200, sm: 768, xxs: 0 }}
        cols={{ lg: 12,  sm: 6, xxs: 2 }}
      
      >
        {props.children}
      </ResponsiveReactGridLayout>
    </Box>
  );
}
