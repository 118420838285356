import React from "react";
import ReactExport from "react-export-excel";
import { useTranslation } from 'react-i18next';
import { formatInEuros } from "./UtilFunctions";
import { connect } from 'react-redux';
import { mapStateToProps,mapDispatchToProps } from './TenderStore';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function ExportExcel({tender=[]}) {
    const {tenderingProduct,
        selectedOptions,
        additionalItems, 
        exchangeMachineryPrice,
        customerRef,
        calculatedPriceTables } = tender;
    const { t } = useTranslation();

    console.log("Generating tender excel with data:");
    console.log(tender);


    const stdPartsArray=[];
    tenderingProduct.standardParts.forEach((p) => stdPartsArray.push(['',p.name+': '+p.value,'included']));


    const optionsArray=[];
    selectedOptions.map((o,index) => optionsArray.push([''+o.code,o.name,formatInEuros(calculatedPriceTables.selectedOptionPrices[index][2])]));

    const dataSetsTender = [
        {
            columns: [t('Customer')],
            data: [[ { value: customerRef, style: { font: { bold: true }}}]]
        },
        {
            columns: [t('Product Code'),t('Title'),t('Base price')],
            data: [ 
                [
                   { value: ''+tenderingProduct.code, style: { font: { color: 'black'}, fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }} },
                   tenderingProduct.name,
                   formatInEuros(calculatedPriceTables.productPrice[2])
                ]
            ]
        }];
    if(stdPartsArray!==undefined && stdPartsArray.length>0)
        dataSetsTender.push(
        {
            columns: ['',t('Feature'),t('Price')],
            data: 
            stdPartsArray
                            
        }
    );
    if(optionsArray!==undefined && optionsArray.length>0)
        dataSetsTender.push(
        {
            columns: [t('Code'),t('Option'),t('Price')],
            data: 
                optionsArray
                            
        }
        );
    const priceSectionData=[[t('Freight price'),'',formatInEuros(calculatedPriceTables.freightPrice[2])]];
    additionalItems.forEach((additionalItem,index) => {
        priceSectionData.push([additionalItem.text,'',formatInEuros(calculatedPriceTables.additionalItemPrices[index][2])]);
    });
    priceSectionData.push([t('TOTAL'),'',formatInEuros(calculatedPriceTables.total[2])]);
    priceSectionData.push([t('Exchange Machinery'),'',formatInEuros(exchangeMachineryPrice)]);
    dataSetsTender.push(
        {
            columns: [t('Item'),'',t('Price')],
            data: priceSectionData
        }
    )

    console.log(tenderingProduct);
    console.log(dataSetsTender);

//   <ExcelColumn label="Customer ref." value={customerRef} />

        return (
            <ExcelFile>
                <ExcelSheet dataSet={dataSetsTender} name={t('Tender')}
                hideElement={true}
                >
                </ExcelSheet>
            </ExcelFile>
        );
}
export default connect(mapStateToProps, mapDispatchToProps)(ExportExcel);


