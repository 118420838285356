import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  IconButton
} from "@mui/material";
import CancelIcon from '@mui/icons-material/CancelOutlined';
import OkIcon from '@mui/icons-material/CheckCircleOutlined';

import uuid from "react-uuid";
import * as xlsx from "xlsx";
import { asciiOnlyString, formatISODateTime } from "./UtilFunctions";

import {
  uploadCatalogToCloud,
  fetchCatalogsFromCloud,
} from "./TendererDataService";

import { useUserContext } from "./UserContext";
import CatManMenu from "./CatManMenu";
import CatManPanel from "./CatManPanel";
import StatusAlert, { StatusAlertService } from "react-status-alert";
import "react-status-alert/dist/status-alert.css";
import { connect } from "react-redux";
import { ConstructionOutlined } from "@mui/icons-material";

// temp 
/*
const optImgsExistFor = [
  45120105, 45120172, 45120186, 45120232, 45120284, 45120296, 45120355,
  45120106, 45120173, 45120192, 45120236, 45120288, 45120302, 45120359,
  45120133, 45120174, 45120193, 45120240, 45120290, 45120303,
  45120142, 45120175, 45120196, 45120243, 45120291, 45120305,
  45120147, 45120176, 45120199, 45120255, 45120292, 45120327,
  45120162, 45120178, 45120203, 45120280, 45120293, 45120338,
  45120170, 45120179, 45120208, 45120281, 45120294, 45120339,
  45120171, 45120181, 45120210, 45120283, 45120295, 45120346
];
*/

function CatManScreen({ tender = [], dispatch }) {
  const {
    state: { token },
  } = useUserContext();
  const { catalogData, selectedEntity } = tender;

  const [showDeleted, setShowDeleted] = useState(false);
  const [managingCatalogs, setManagingCatalogs] = useState([]);
  const [phase, setPhase] = useState("CATALOGS_INDEX");

  const [diffReport,setDiffReport]=useState(undefined);
  const [reviewedCatalogData,setReviewedCatalogData]=useState(undefined);

  useEffect(() => {
    console.log("***** fetching catalogs from cloud *****");
    fetchCatalogsFromCloud(token, showDeleted, (catalogs) => {
      console.log("Fetched: ");
      console.log(catalogs);
      if (catalogs !== undefined && catalogs.length > 0) {
        catalogs.forEach((c) => {
          // test
          c.options = undefined;
          if (c.options === undefined) {
            console.log("Parsing options from product options");
            const optionsSet = {};
            const attachmentsSet = {};
            c.products.forEach((p) => {
              if (p.uuid === undefined) p.uuid = uuid();
              const removeProductOptions = [];
              const addProductOptions = [];
              p.options?.forEach(
                (o) => {
                  let optCode = o.code;
                  let overrideForProduct = o.overrideForProduct;
                  const oInSet = optionsSet[o.code];
                  if (oInSet !== undefined &&
                    (
                      oInSet.name !== o.name ||
                      oInSet.description !== o.description ||
                      oInSet.price !== o.price
                    )) {
                    optCode = o.code + '_' + p.code;
                    overrideForProduct = p.code;
                    removeProductOptions.push(o.code);
                    addProductOptions.push(optCode);
                    console.log("Creating product specific override for " + optCode);
                  }
                  //console.log("Option from product " + optCode+" img? "+Number(o.code));
                  // once match opts TEMP
                  /*
                  if (optImgsExistFor.find((oecode) => Number(oecode)==Number(o.code)))
                    o.image = "" + o.code + ".jpg";
                  */
                  optionsSet[optCode] = {
                    uuid: o.uuid !== undefined ? o.uuid : uuid(),
                    code: optCode,
                    name: o.name,
                    description: o.description,
                    image: o.image,
                    price: o.price,
                    overrideForProduct: overrideForProduct,
                  };
                }
              );
              if (p.productOptions === undefined) p.productOptions = [];
              p.attachments?.forEach(
                (a) => {
                  attachmentsSet[a.code] = {
                    uuid: a.uuid !== undefined ? a.uuid : uuid(),
                    code: a.code,
                    name: a.name,
                    description: a.description,
                    image: a.image,
                    price: a.price,
                  };
                  a.suitableForProducts = null; // cleaning from product scope
                }
              );
              if (p.productAttachments === undefined) p.productAttachments = [];
              p.standardParts?.forEach((sp) => {
                if (sp.uuid === undefined) sp.uuid = uuid();
              });
              // clear overrideen and add them
              removeProductOptions.forEach((ocode) => { p.options = p.options.filter((po) => po.code !== ocode) });
              addProductOptions.forEach((ocode) => { p.options.push(optionsSet[ocode]) });

            });
            c.options = Object.values(optionsSet);
            c.attachments = Object.values(attachmentsSet);


          } else {
            // make sure options have uuids
            c.options?.forEach((o) => {
              if (o.uuid === undefined) o.uuid = uuid();
            });
          }

          if (c.attachments === undefined) {
            // no parsing needed (will be removed for options also)
            c.attachments = [];
          } else {
            // make sure attachments have uuids
            c.attachments?.forEach((o) => {
              if (o.uuid === undefined) o.uuid = uuid();
            });
          }
          c.rules?.forEach((r) => {
            if (r.uuid === undefined) r.uuid = uuid();
          });
        });

        setManagingCatalogs(catalogs);
      } else {
        StatusAlertService.showInfo("No catalogs found!");
      }
    });
  }, [token, showDeleted, selectedEntity]); // selected entity changes if coming back from catalog editing

  // if cancelling catalog editing
  useEffect(() => {
    if (catalogData === undefined) setPhase("CATALOGS_INDEX");
  }, [catalogData]);

  useEffect(() => {
    console.log("Phase changed to " + phase);
  }, [phase]);

  function storeCatalog() {
    console.log("Trying to upload catalog to cloud...");


    uploadCatalogToCloud(token, catalogData, (success) => {
      if (success) {
        dispatch({ type: "catalogDataSaved" });
        console.log("Succeeded.");
        StatusAlertService.showSuccess("Catalog uploaded successfully.");
      } else {
        alert("ERROR SAVING CATALOG TO CLOUD!");
        console.log("Failed.");
        StatusAlertService.showError("Catalog upload failed!");
      }
    });
  }
  
  function importCatalog(updatingCatalogData) {
    console.log("Importing catalog from Excel");
    setPhase("UPDATE_CATALOG");
  }

  function exportCatalog() {
    console.log("Trying to upload catalog as excel");

    var workbook = xlsx.utils.book_new();
    // catalog metadata
    var data = [];
    data.push(["Code", catalogData.code]);
    data.push(["Catalog Name", catalogData.name]);
    data.push(["Manufacturer", catalogData.manufacturer]);
    data.push(["Description", catalogData.description]);
    data.push(["Catalog Version", catalogData.version]);
    data.push(["Prices Updated At", { s: "" + catalogData.pricesUpdatedAt }]);
    data.push(["Logo", catalogData.logo]);
    data.push(["Published",catalogData.published?"X":""]);
    data.push(["Marked for Deletion",catalogData.markedForDeletion?"X":""]);
    data.push(["Include product options in tender",catalogData.includeProductOptions?"X":""]);
    data.push(["Include product attachments in tender",catalogData.includeProductAttachments?"X":""]);
    var worksheet = xlsx.utils.aoa_to_sheet(data);
    xlsx.utils.book_append_sheet(workbook, worksheet, "Catalog");

    // products
    const productsData = [
      ["Group", "Code", "Name", "Description", "Image", "Price"],
    ];
    catalogData.products.forEach((p) => {
      var data = [
        [
          "Group",
          "Code",
          "Name",
          "Description",
          "Image",
          "Price",
          "Rule REQUIRES",
          "Rule NOTWITH",
          "Rule ATLEASTONE",
          "Rule ONLYONE",
        ],
      ];
      data.push(["PRODUCT", p.code, p.name, p.description, p.image, p.price]);
      productsData.push(["", p.code, p.name, p.description, p.image, p.price]);
      p.standardParts.forEach((s) => {
        data.push(["", "std", s.name, s.value, ""]);
      });
      data.push(["OPTIONS", "", "", "", ""]);
      p.options?.forEach((o) => {
        const ruleRequires = catalogData.rules
          .filter((r) => {
            return (
              r.oper === "REQUIRES" &&
              r.scope === "product" &&
              r.productCode === p.code &&
              r.optionCode === o.code
            );
          })
          .map((r) => r.optionCodes);
        const ruleNotWith = catalogData.rules
          .filter((r) => {
            return (
              r.oper === "NOTWITH" &&
              r.scope === "product" &&
              r.productCode === p.code &&
              r.optionCode === o.code
            );
          })
          .map((r) => r.optionCodes);
        const ruleAtLeastOne = catalogData.rules
          .filter((r) => {
            return (
              r.oper === "ATLEASTONE" &&
              r.scope === "product" &&
              r.productCode === p.code &&
              r.optionCode === o.code
            );
          })
          .map((r) => r.optionCodes);
        const ruleOnlyOne = catalogData.rules
          .filter((r) => {
            return (
              r.oper === "ONLYONE" &&
              r.scope === "product" &&
              r.productCode === p.code &&
              r.optionCode === o.code
            );
          })
          .map((r) => r.optionCodes);
        data.push([
          "",
          "" + o.code,
          o.name,
          o.description,
          o.image,
          o.price,
          ruleRequires.toString(),
          ruleNotWith.toString(),
          ruleAtLeastOne.toString(),
          ruleOnlyOne.toString(),
        ]);
      });
      worksheet = xlsx.utils.aoa_to_sheet(data);
      xlsx.utils.book_append_sheet(
        workbook,
        worksheet,
        "Product " + asciiOnlyString(p?.name).substring(0, 22)
      );
    });
    worksheet = xlsx.utils.aoa_to_sheet(productsData);
    xlsx.utils.book_append_sheet(workbook, worksheet, "Products");

    // options
    data = [["Group", "Code", "Name", "Description", "Image", "Price"]];
    catalogData.products.forEach((p) => {
      data[0].push(p.name);
    });
    catalogData.options?.forEach((o) => {
      const optionRow = ["", o.code, o.name, o.description, o.image, o.price];
      catalogData.products.forEach((p) => {
        let oforp = "";
        p.options.forEach((po) => {
          if (o.code === po.code) oforp = p.code;
        });
        optionRow.push(oforp);
      });
      data.push(optionRow);
    });
    worksheet = xlsx.utils.aoa_to_sheet(data);
    xlsx.utils.book_append_sheet(workbook, worksheet, "Options");

    // attachments
    data = [["Group", "Code", "Name", "Description", "Image", "Price"]];
    catalogData.products.forEach((p) => {
      data[0].push(p.name);
    });
    catalogData.attachments.forEach((a) => {
      const attachmentRow = [
        "",
        a.code,
        a.name,
        a.description,
        a.image,
        a.price,
      ];
      catalogData.products.forEach((p) => {
        let aforp = "";
        p.attachments?.forEach((pa) => {
          if (a.code === pa.code) aforp = p.code;
        });
        attachmentRow.push(aforp);
      });
      data.push(attachmentRow);
    });
    worksheet = xlsx.utils.aoa_to_sheet(data);
    xlsx.utils.book_append_sheet(workbook, worksheet, "Attachments");

    xlsx.writeFileXLSX(
      workbook,
      catalogData.name + "_export_" + formatISODateTime("now") + ".xlsx"
    );

    console.log("Succeeded.");
    StatusAlertService.showSuccess("Catalog exported successfully.");
  }

  //
  // diffCatalogs
  //
  // constructs diff report
  /*
        code: catalogID,
      name: catalogName,
      version: version,
      description: catalogDesc,
      manufacturer: manufacturer,
      pricesUpdatedAt: pricesUpdatedAt,
      logo: logo,
      inheritedFrom: "imported",
      products: products,
      options: options, //Object.values(optionsSet),
      attachments: attachments,
      rules: rules,
      */
  function diffCatalogs(catalogData,newCatalogData) {
    const diffReportRows=[];
    // diff catalog
    if(catalogData.code !== newCatalogData.code) diffReportRows.push({entity: "catalog", code: catalogData.code, field: "code", oldValue: catalogData.code, newValue: newCatalogData.code});

/*    if(catalogData.name !== newCatalogData.name) diffReportRows.push({entity: "catalog", code: catalogData.code, field: "name", oldValue: catalogData.name, newValue: newCatalogData.name});
    if(catalogData.version !== newCatalogData.version) diffReportRows.push({entity: "catalog", code: catalogData.code, field: "version", oldValue: catalogData.version, newValue: newCatalogData.version});
    if(catalogData.description !== newCatalogData.description) diffReportRows.push({entity: "catalog", code: catalogData.code, field: "description", oldValue: catalogData.description, newValue: newCatalogData.description});
    if(catalogData.manufacturer !== newCatalogData.manufacturer) diffReportRows.push({entity: "catalog", code: catalogData.code, field: "manufacturer", oldValue: catalogData.manufacturer, newValue: newCatalogData.manufacturer});
*/
    let fields=["name", "version", "description", "manufacturer","pricesUpdatedAt","logo",
      "published", "markedForDeletion", "includeProductOptions", "includeProductAttachments", "inheritedFrom","modifiedBy"];
    fields.map((f,i) =>
    {
      if(catalogData[f] !== newCatalogData[f]) diffReportRows.push({entity: "catalog", code: catalogData.code, field: f, oldValue: catalogData[f], newValue: newCatalogData[f]});
    });
    // diff every product
    catalogData.products.map((p,i) =>  {
      // find p product by code in new catalog
      let np=newCatalogData.products.find(s => s.code===p.code );
      if(np===undefined)
      {
        // if not found then p is deleted from new
        diffReportRows.push({entity: "product", code: p.code, field: "DELETED", oldValue: "", newValue: ""});
      } else {
        // if delete then no need to list inner parts changes
        fields=["name","description","image","price"];
        fields.map((f,i) =>
        {
          if(p[f] !== np[f]) diffReportRows.push({entity: "product", code: p.code, field: f, oldValue: p[f], newValue: np[f]});
        });
      }
      } 
      // diff product options for the product
    );
    // every in new to find new ones not found in catalogData
    newCatalogData.products.map((np,i) =>  {
      // find p product by code in new catalog
      let p=catalogData.products.find(s => s.code===np.code );
      if(p===undefined)
      {
        // if not found then p is new
        diffReportRows.push({entity: "product", code: np.code, field: "ADDED", oldValue: "", newValue: np.code});
        p={code: np.code};
      }
      // when adding also show diffs for all inner changes
        fields=["name","image","price"]; // not comparing uuids
        fields.map((f,i) =>
        {
          if(p[f] !== np[f]) diffReportRows.push({entity: "product", code: p.code, field: f, oldValue: p[f], newValue: np[f]});
        });
      
        // std parts
        p.standardParts.map((osp,i) => {
          let nsp=np.standardParts?.find(sp=>sp.name===osp.name);
          if(nsp===undefined)
          {
            diffReportRows.push({entity: "product:standardPart(name)", code: p.code +':'+ osp.name, field: "DELETED", oldValue: osp.name, newValue: ""});
          } else {
            fields=["value"]; // not comparing uuids
            fields.map((f,i) =>
            {
              if(osp[f] !== nsp[f]) diffReportRows.push({entity: "product:standardPart(name)", code: np.code+':'+nsp.name, field: f, oldValue: osp[f], newValue: nsp[f]});
            });  
          }
        });
        // std parts new
      np.standardParts.map((nsp,i) => {
        let osp=p.standardParts?.find(sp => sp.name===nsp.name);
        if(osp===undefined)
        {
          // stdpart was not
          diffReportRows.push({entity: "product:standardPart(name)", code: np.code +':'+ nsp.name, field: "ADDED", oldValue: "", newValue: nsp.name});
          osp={name: nsp.name};
        }        

          fields=["value"]; // not comparing uuids
          fields.map((f,i) =>
          {
            if(osp[f] !== nsp[f]) diffReportRows.push({entity: "product:standardPart(name)", code: np.code+':'+nsp.name, field: f, oldValue: osp[f], newValue: nsp[f]});
          });
      });
      
      // allowed options for the product
      p.options.map((oo,i) => {
        let no=np.options?.find(o=>o.code===oo.code);
        if(no===undefined)
        {
          diffReportRows.push({entity: "product:option", code: p.code +':'+ oo.code, field: "DELETED", oldValue: oo.code, newValue: ""});
        } else {
          fields=["name","price"]; // not comparing uuids
          fields.map((f,i) =>
          {
            if(oo[f] !== no[f]) diffReportRows.push({entity: "product:option", code: np.code+':'+no.code, field: f, oldValue: oo[f], newValue: no[f]});
          });
        }
      });
    np.options.map((no,i) => {
        let oo=p.options?.find(o => o.code===no.code);
        if(oo===undefined)
        {
          // option was not
          diffReportRows.push({entity: "product:option", code: np.code +':'+ no.code, field: "ADDED", oldValue: "", newValue: no.code});
          oo={code: no.code};
        }        

          fields=["name","price"]; // not comparing uuids
          fields.map((f,i) =>
          {
            if(oo[f] !== no[f]) diffReportRows.push({entity: "product:option", code: np.code+':'+no.code, field: f, oldValue: oo[f], newValue: no[f]});
          });
      });
      
    });



    // set diff report
    console.log(diffReportRows);


    // remove duplicate rows
    function uniqBy(a, key) {
      var seen = {};
      return a.filter(function(item) {
          var k = key(item);
          return seen.hasOwnProperty(k) ? false : (seen[k] = true);
      })
    }
    let uniqueDiffReportRows = uniqBy(diffReportRows,JSON.stringify);

    setDiffReport(uniqueDiffReportRows);
    setReviewedCatalogData(newCatalogData);
  }

  function applyChangesToCatalog() {
    dispatch({
      type: "initCatalogData",
      payload: { setSelected: true, catalogData: reviewedCatalogData },
    });

  }

  //
  // initializeCatalogWithExcelData
  //
  function initializeCatalogWithExcelData(data) {
    console.log("Creating new catalog from import excel");

    const workbook = xlsx.read(data, { type: "array" });

    // catalog
    const sheetName = workbook.SheetNames[0];
    let worksheet = workbook.Sheets[sheetName];
    const catMeta = xlsx.utils.sheet_to_json(worksheet, {
      header: ["Attribute", "Value"],
      range: 0,
    });
    // read catalog metadata from catMeta
    console.log(catMeta);
    const catalogID = catMeta[0]["Value"]; //"CastLoaders";
    const catalogName = catMeta[1]["Value"];
    const manufacturer = catMeta[2]["Value"];
    const catalogDesc = catMeta[3]["Value"];
    const version = catMeta[4]["Value"];
    const pricesUpdatedAt = catMeta[5]["Value"];
    const logo = catMeta[6]["Value"];
    const published = catMeta[7]["Value"]?true:false;
    const markedForDeletion = catMeta[8]["Value"]?true:false;
    const includeProductOptions=catMeta[9]["Value"]?true:false;
    const includeProductAttachments=catMeta[10]["Value"]?true:false;

    const products = [];
    const options = [];
    const attachments = [];
    //const optionsSet = {};
    const rules = [];


    // first read catalog level options
    worksheet = workbook.Sheets["Options"];
    const catOptions = xlsx.utils.sheet_to_json(worksheet, {
      range: 1,
      header: 1,
    });
    catOptions.forEach((r) => {
      //console.log(r);
      r.shift(); // skip empty group col


      const optCode=""+r.shift();
      const or4prodInx=optCode.indexOf("_");
      const or4prod=(or4prodInx<0?undefined:optCode.substring(or4prodInx+1)); 
      const catOpt = {
        code: '' + optCode, //[0],
        overrideForProduct: or4prod, 
        name: r.shift(), //[1],
        description: r.shift(), //[2],
        image: r.shift(), // r[3]
        price: r.shift(), //r[4],
        suitableForProducts: r.filter(ritem=>ritem!==""),
      };
      if (options.find((o) => (o.code === catOpt.code)) === undefined) {
        // add only if not already in cat options
        options.push(catOpt);
      }
    });
    console.log("Read following catalog level options:");
    console.log(options);



    // first read attachements to get suitability matrix data

    worksheet = workbook.Sheets["Attachments"];
    const catAttachments = xlsx.utils.sheet_to_json(worksheet, {
      range: 1,
      header: 1,
    });
    // cols:
    // 0 = Code
    // 1 = Name
    // 2 = Description
    // 3 = Image
    // 4 = Price
    // 5... = suitable for product codes
    catAttachments.forEach((r) => {
      //console.log(r);
      r.shift(); // skip empty group col
      const att = {
        code: '' + r.shift(), //[0],
        name: r.shift(), //[1],
        description: r.shift(), //[2],
        image: r.shift(), // r[3]
        price: r.shift(), //r[4],
        suitableForProducts: r,
      };
      //console.log(att);
      attachments.push(att);
    });
    console.log("Read following catalog level attachments:");
    console.log(attachments);

    // loop through product sheet(s)
    workbook.SheetNames.forEach((sheetName) => {
      if (sheetName.substring(0, 8) === "Product ") {
        let prod = undefined;
        let prodStdParts = [];
        let prodOptions = [];

        worksheet = workbook.Sheets[sheetName];
        const catProducts = xlsx.utils.sheet_to_json(worksheet, {
          header: [
            "Group",
            "Code",
            "Name",
            "Description",
            "Image",
            "Price",
            "Rule REQUIRES",
            "Rule NOTWITH",
            "Rule ATLEASTONE",
            "Rule ONLYONE",
          ],
          range: 0,
        }); // normally only one per sheet

        catProducts.forEach((r) => {
          //console.log(r);
          if (r["Group"] === "PRODUCT") {
            // new product starts.. close previous if any
            if (prod !== undefined) {
              prod.standardParts = prodStdParts;
              prod.productOptions = prodOptions;
              products.push(prod);
              console.log("IMPORTED PRODUCT");
              console.log(prod);
            }
            prod = {
              code: '' + r.Code,
              name: r.Name,
              description: r.Description,
              image: r.Image,
              price: r["Price"],
            };
            prodStdParts = [];
            prodOptions = [];
            prod.attachments = attachments.filter((a) =>
              a.suitableForProducts.includes(r.Code)
            );
          } else if (r.Code === "std" || r.Code === "") {
            prodStdParts.push({
              name: r.Name,
              value: r.Description !== undefined ? r.Description : "Standard",
            });
          } else if (
            r.Code !== undefined &&
            r.Code !== "" &&
            r.Code !== "Code"
          ) {
            // option
            let optCode=""+r.Code;
            // if already option for product then do not add prod code
            let or4prodInx=optCode.indexOf("_");
            const or4prod=(or4prodInx<0?undefined:optCode.substring(or4prodInx+1)); 
          let opt = {
              code: '' + optCode,
              overrideForProduct: or4prod,
              name: r.Name,
              image: r.Image,
              price: r["Price"],
            };
            // check if this product option is same as catalog level option
            const catOpt = options.find((o) => (opt.code === o.code && opt.name === o.name && opt.price === o.price));
            console.log(opt);
            console.log("Found option from catalog?");
            console.log(catOpt);
            if (catOpt === undefined) {

              opt = {
                code: '' + optCode, //[0],
                overrideForProduct: or4prod,
                 name: opt.name, image: opt.image, price: opt.price, suitableForProducts: [prod.code] };

              if (options.find((o) => (o.code === opt.code)) === undefined) {
                  // add only if not already in cat options
                 options.push(opt); // adding to catalog options this overrridden option
                 console.log("Added overridden option:");
                 console.log(opt);
                 }
            }
            //optionsSet["opt" + opt.code + opt.name + opt.price] = opt;
            //console.log( "Added option to set. Cnt=" + Object.values(optionsSet).length);
            prodOptions.push(opt);
            console.log("Rule REQ: " + r["Rule REQUIRES"]);
            if (r["Rule REQUIRES"] !== undefined && ""+r["Rule REQUIRES"]!=="") {
              const optCodesStr = "" + r["Rule REQUIRES"];
              const optCodes = optCodesStr.split(",");
              rules.push({
                uuid: uuid(),
                scope: "product",
                oper: "REQUIRES",
                productCode: prod.code,
                optionCode: r.Code,
                optionCodes: optCodes,
              });
            }
          }
        });
        // add also last (or only product)
        if (prod !== undefined) {
          prod.standardParts = prodStdParts;
          prod.options = prodOptions;
          products.push(prod);
          console.log("IMPORTED PRODUCT");
          console.log(prod);
        }
      } else if (sheetName === "Options") {
        console.log("Already imported options");
      } else if (sheetName === "Attachments") {
        console.log("Already imported attachments");
      }
    });

    const newCatalogData = {
      code: catalogID,
      name: catalogName,
      version: version,
      description: catalogDesc,
      manufacturer: manufacturer,
      pricesUpdatedAt: pricesUpdatedAt,
      logo: logo,
      published: published,
      markedForDeletion: markedForDeletion,
      includeProductOptions: includeProductOptions,
      includeProductAttachments: includeProductAttachments,
      inheritedFrom: "imported",
      products: products,
      options: options, //Object.values(optionsSet),
      attachments: attachments,
      rules: rules,
    };

    console.log("Importing...");
    console.log(newCatalogData);

    // test only
    let diffOnly=false;
    if(catalogData!==undefined)
      diffOnly=true;
    if(diffOnly) {
      diffCatalogs(catalogData,newCatalogData);
    } else {
      dispatch({
        type: "initCatalogData",
        payload: { setSelected: true, catalogData: newCatalogData },
      });
    }
  }

  return (
    <div style={{ alignContent: "center" }}>
      <StatusAlert />

      {
        {
          CATALOGS_INDEX: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                margin: "auto",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  margin: "auto",
                  justifyContent: "center",
                }}
              >
                <Box
                  key={-3}
                  width={200}
                  border={1}
                  margin={2}
                  boxShadow={"2px 2px"}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <FormGroup>
                    <FormControlLabel
                      sx={{ width: "100%", margin: 1 }}
                      control={
                        <Checkbox
                          id="chk-showDeleted"
                          checked={showDeleted}
                          onChange={(e) => {
                            setShowDeleted(!showDeleted);
                          }}
                        />
                      }
                      label="Show deleted catalogs"
                    />
                  </FormGroup>
                </Box>
                <Box
                  key={-1}
                  width={200}
                  border={1}
                  margin={2}
                  boxShadow={"2px 2px"}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Button
                    sx={{ flex: 1, width: "100%" }}
                    border={1}
                    color="inherit"
                    onClick={() => {
                      console.log("Creating new emplty catalog:");
                      const newCatalogData = {
                        code: undefined,
                        name: "- new -",
                        version: 0,
                        manufacturer: "",
                        pricesUpdatedAt: "",
                        inheritedFrom: undefined,
                        products: [],
                        options: [],
                      };
                      dispatch({
                        type: "initCatalogData",
                        payload: {
                          setSelected: true,
                          catalogData: newCatalogData,
                        },
                      });
                      setPhase("EDIT_CATALOG");
                    }}
                  >
                    Create new empty catalog
                  </Button>
                </Box>
                <Box
                  key={-2}
                  width={200}
                  border={1}
                  margin={2}
                  boxShadow={"2px 2px"}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <FormControl>
                    Create new empty catalog from Excel
                    <Input
                      type="file"
                      name="excelImport"
                      id="excelImport"
                      onChange={(e) => {
                        e.preventDefault();
                        if (e.target.files) {
                          const reader = new FileReader();
                          reader.onload = (e) => {
                            const data = e.target.result;
                            initializeCatalogWithExcelData(data);
                            setPhase("EDIT_CATALOG");
                          };
                          reader.readAsArrayBuffer(e.target.files[0]);
                        }
                      }}
                    />
                  </FormControl>
                </Box>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  margin: "auto",
                  justifyContent: "center",
                }}
              >
                {managingCatalogs &&
                  managingCatalogs.map((c, index) => (
                    <Box
                      key={index}
                      width={300}
                      border={1}
                      margin={2}
                      boxShadow={"2px 2px"}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {c.logo !== undefined && (
                        <Box
                          component="img"
                          width="80%"
                          maxHeight="80%"
                          margin={"auto"}
                          src={
                            (window.location.hostname === "localhost"
                              ? "https://tenderer.cappgw.com"
                              : "") +
                            "/assets/catalogs/" +
                            c.name +
                            "/assets/" +
                            c.logo
                          }
                          alt={c.name}
                        />
                      )}
                      <Typography variant="caption">
                        {c.name} {c.version}
                      </Typography>
                      <Button
                        sx={{ flex: 1, width: "100%" }}
                        border={1}
                        color="inherit"
                        onClick={() => {
                          console.log("Selecting catalog:");
                          console.log(c);
                          dispatch({
                            type: "initCatalogData",
                            payload: { setSelected: true, catalogData: c },
                          });
                          setPhase("EDIT_CATALOG");
                        }}
                      >
                        EDIT
                      </Button>
                    </Box>
                  ))}
              </div>
            </div>
          ),
          EDIT_CATALOG: (
            <div style={{ width: "100%", marginRight: 0, marginLeft: 0 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {catalogData && (
                  <>
                    <CatManMenu
                      storeCatalogCallback={storeCatalog}
                      exportCatalogCallback={exportCatalog}
                      importCatalogCallback={importCatalog}
                    />
                    <CatManPanel />
                  </>
                )}
              </div>
            </div>
          ),
          UPDATE_CATALOG: (
            <div style={{ width: "100%", marginRight: 0, marginLeft: 0 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>

                  { diffReport!==undefined?
              <div style={{ display: "flex", flexDirection: "column" }}>
              <table>
                      <thead><tr><th>Entity</th><th>Code</th><th>Field</th><th>Old value</th><th>New value</th></tr></thead><tbody>
                      { diffReport.map((r,i) => 
                        <tr>
                          <td style={{width:"20%"}}><Typography key={i} variant="caption">{ r.entity.toString()  }</Typography></td>
                          <td style={{width:"20%"}}><Typography key={i} variant="caption">{ r.code?.toString()  }</Typography></td>
                          <td style={{width:"20%"}}><Typography key={i} variant="caption">{ r.field?.toString()  }</Typography></td>
                          <td style={{width:"20%"}}><Typography key={i} variant="caption">{ r.oldValue?.toString()  }</Typography></td>
                          <td style={{width:"20%"}}><Typography key={i} variant="caption">{ r.newValue?.toString()  }</Typography></td>
                        </tr>
                      )}
                      </tbody>
                    </table>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <IconButton color="error" onClick={ () => { setDiffReport(undefined); setReviewedCatalogData(undefined); setPhase("EDIT_CATALOG"); }}><CancelIcon /> Cancel</IconButton>
                    <IconButton color="success" onClick={ () => { applyChangesToCatalog(); setDiffReport(undefined); setReviewedCatalogData(undefined); setPhase("EDIT_CATALOG"); }}><OkIcon /> Apply</IconButton>
                    </div>
                    </div>
                    :
                    <>
                                    <h1>Select catalog excel file</h1>
                <FormControl>
                    Update current catalog from Excel
                    <Input
                      type="file"
                      name="excelImport"
                      id="excelImport"
                      onChange={(e) => {
                        e.preventDefault();
                        if (e.target.files) {
                          const reader = new FileReader();
                          reader.onload = (e) => {
                            const data = e.target.result;
                            initializeCatalogWithExcelData(data);
                            // setPhase("EDIT_CATALOG");
                          };
                          reader.readAsArrayBuffer(e.target.files[0]);
                        }
                      }}
                    />
                  </FormControl>
                  </>
                  }

              </div>
            </div>
          ),
        }[phase]
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  const tender = state.tender;
  return { tender };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) =>
    dispatch({ type: action.type, payload: action.payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CatManScreen);
