import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import { MenuProps } from "./CatManRulesPanel";


export function EntitySelectMultiChips({ entityPrompt = 'Entity', availableOptions, selectedOptions, multiselect = true, selectionsChangedCallback }) {
  const { t } = useTranslation();

  const [currentSelections, setCurrentSelections] = useState(selectedOptions);



  return (
    <FormControl sx={{ width: '100%', marginTop: 5 }}>
      <InputLabel id="prodselect-label">
        {t(entityPrompt)}
      </InputLabel>
      <Select
        labelId="optselect-label"
        id="opt-select"
        multiple={multiselect}
        value={multiselect ? currentSelections : currentSelections[0]}
        label={t('Select')}
        onChange={async function (event) {
          console.log(`Selected option ${event.target.value}`);
          console.log(event.target);
          console.log(`Current selections ${currentSelections}`);

          const {
            target: { value },
          } = event;
          const setValue = typeof value === 'string' ? value.split(',') : value;
          setCurrentSelections(
            // On autofill we get a stringified value.
            setValue
          );
          selectionsChangedCallback(setValue);
        }}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value,index) => {
              if(value!==undefined)
                return (<Chip key={value} label={value} />);
              else
                return (<React.Fragment key={index}></React.Fragment>);
          })}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {availableOptions.map((o, i) => <MenuItem key={o.uuid + entityPrompt} value={o.code}>{o.code + ' ' + o.name}</MenuItem>
        )}
      </Select>
    </FormControl>

  );
}
