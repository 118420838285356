import React, { useEffect, useState } from "react";
import "./App.css";

import CatalogCard from "./CatalogCard";
import ProductSelectScreen from "./ProductSelectScreen";
import StoredTendersPanel from "./StoredTendersPanel";
import StatisticsPanel from "./StatisticsPanel";
import TranslatorScreen from "./TranslatorScreen";
import CatManScreen from "./CatManScreen";

import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Divider
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { useTranslation } from "react-i18next";
import { useUserContext } from "./UserContext";

import {
  deleteTenderFromCloud,
  fetchUserPreferences,
  fetchUsersStoredTenders,
} from "./TendererDataService";
import StatusMessagePopup from "./StatusMessagePopup";

import { connect } from "react-redux";
import uuid from 'react-uuid';
import { formatISODateTime } from "./UtilFunctions";

import DashboardLayout from "./DashboardLayout";

import useMediaQuery from '@mui/material/useMediaQuery';





function App({ tender = [], dispatch }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const widthSmallerThan767=useMediaQuery('(max-width: 767px)');
  const widthSmallerThan413=useMediaQuery('(max-width: 413px)');
  const workWidth=widthSmallerThan413?300:widthSmallerThan767?400:600;

  const menuOpen = Boolean(anchorEl);
  const handleMenuButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [isTenderPreloaded, setIsTenderPreloaded] = useState(false);
  const [storedTenders, setStoredTenders] = useState([]);
  const [storedTendersUpdateCnt, setStoredTendersUpdateCnt] = useState(0); // counter forcing update
  const { dispatchLogin } = useUserContext();
  const {
    state: { username, token, catalogs, roles },
  } = useUserContext();
  const { t, i18n } = useTranslation();

  const [phase, setPhase] = useState("LOGIN");
  const [selectedCatalog, setSelectedCatalog] = useState(undefined);
  const [lite, setLite] = useState(false);
  const [preSelectedProduct, setPreSelectedProduct] = useState(undefined);
  const [showMessage, setShowMessage] = useState("");

  const statusIDs = Array("INQUIRY", "NEW", "TENDERED", "WON", "LOST", "CANCELLED", "ARCHIVED");

  useEffect(() => {
    if (token === undefined) setPhase("LOGGINGIN");
    console.log("QUERYSTRING: " + window.location.href);
    const queryString = require("query-string");
    console.log("QUERYSTRINGOARAMS: ");
    console.log(queryString);
    // checking if application was started with already logged in user
    const queryParams = queryString.parse(window.location.hash);
    console.log(Object.values(queryParams));
    console.log("TOKEN: " + queryParams.id_token);
    if (queryParams.id_token !== undefined)
      fetchUserPreferences(queryParams.id_token, (userPrefs) => {
        dispatchLogin(userPrefs);
        setPhase("LOGGEDIN");
      });
    if (queryParams.lite !== undefined) {
      setPhase("TENDERING");
      setLite(true);
      setSelectedCatalog("CastLoaders");
      setPreSelectedProduct(queryParams.product);
      dispatch({
        type: "initTender",
        payload: {
          catalogName: "CastLoaders", /// std name? or from param?
          tender: undefined, // new tender
          liteOn: true,
        },
      });
    }
  }, [dispatchLogin]); // one time only

  useEffect(() => {
    if (token) {
      console.log("Fetching tenders with token: " + token);
      fetchUsersStoredTenders(token, (tenders) => {
        console.log("Sorting stored tenders");
        console.log(tenders);

        // fix codes to be string in tenderingProduct options and selectedOptions
        tenders.forEach(t => {
          t.selectedOptions.forEach(o => o.code=''+o.code);
          
          t.tenderingProduct.options.forEach(o => o.code=''+o.code);
        });

        const newStoredTenders=tenders.sort(function(a,b) { return statusIDs.findIndex((s)=> s==b.tenderStatus) > statusIDs.findIndex((s)=>a.tenderStatus==s)?1:-1});
        console.log(tenders);
        setStoredTenders(newStoredTenders);
      });
    }
  }, [token, storedTendersUpdateCnt]);


  return (
    <div className="App" style={{ margin:0 }}>
      <Box sx={{ flexGrow: 1,margin:0 }}>
        <AppBar position="fixed" color="primary">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="secondary"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleMenuButtonClick}
            >
              <MenuIcon />
            </IconButton>
            {username && (
              <Menu
                id="home-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setIsTenderPreloaded(false);
                    setStoredTendersUpdateCnt(storedTendersUpdateCnt + 1);
                    setPhase("LOGGEDIN");
                    handleMenuClose();
                  }}
                >
                  {t("Home")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setShowMessage("Profile clicked");
                    handleMenuClose();
                  }}
                >
                  {t("Profile")}
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    setPhase("TRANSLATING");
                    handleMenuClose();
                  }}
                >
                  {t("Translate tool")}
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    setPhase("MANAGING_CATALOGS");
                    handleMenuClose();
                  }}
                >
                  {t("Catalog Manager")}
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    setPhase("LOGIN");
                    dispatchLogin({ type: "logoutUser" });
                    handleMenuClose();
                  }}
                >
                  {t("Logout")}
                </MenuItem>
              </Menu>
            )}

            <Box
              color="primary"
              sx={{ flexGrow: 1, textAlign: "left", wordSpacing: "normal" }}
            >
              <Typography variant="h6" color="secondary" component="span">
                Machinery&nbsp;
              </Typography>
              <Typography variant="h6" color="accent" component="span">
                Dealer&nbsp;
              </Typography>
              <Typography variant="h6" color="secondary" component="span">
                PRO
              </Typography>
            </Box>

            <div className="select" style={{ marginRight: 5 }}>
              <select
                value={i18n.language}
                onChange={(e) => i18n.changeLanguage(e.target.value)}
              >
                <option value="en">English</option>
                <option value="fi">Suomi</option>
                { roles?.findIndex((r)=>r==="TRANSLATOR")>-1 &&
                <option value="cimode">String IDs</option>
                }
              </select>
            </div>

            {username !== "" ? (
              username
            ) : (
              <Button
                color="inherit"
                onClick={(event) => {
                  window.location.href =
                    "https://tenderer.auth.eu-central-1.amazoncognito.com/login?client_id=4p5mipoi6rmducn1q4jpccs6ei&response_type=token&scope=email+openid+phone+profile&redirect_uri=" +
                    window.location.href;
                }}
              >
                'LOG IN'
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Toolbar />
      </Box>
      {showMessage.ttl > 0 && <StatusMessagePopup message={showMessage} />}
      {
        {
          LOGIN: (
            <Box>
              <Typography variant="h5" component="div">
                {t("WelcomeTitle")}
              </Typography>
              <Button
                size="large"
                color="inherit"
                onClick={(event) => {
                  window.location.href =
                    "https://tenderer.auth.eu-central-1.amazoncognito.com/login?client_id=4p5mipoi6rmducn1q4jpccs6ei&response_type=token&scope=email+openid+phone+profile&redirect_uri=" +
                    window.location.href;
                  setPhase("LOGGINGIN");
                }}
              >
                <Box>
                  <img src="/MDPLogoVino192.png" alt="MDPLogo" />
                  <Typography variant="body" component="div">
                    {t("LOG IN")}
                  </Typography>
                </Box>
              </Button>
              <Typography variant="body1" component="div">
                {t("WelcomeText")}
              </Typography>
              <Typography variant="caption" component="div">
                <a
                  href="https://www.machinerydealerpro.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("Additional information about the Service")}
                </a>
              </Typography>
            </Box>
          ),
          LOGGINGIN: (
            <Box>
              <Typography variant="h5" component="div">
                {t("Logging in...")}
              </Typography>
            </Box>
          ),
          LOGGEDIN: (
            <DashboardLayout
              sx={{
                backgroundColor: "#f0f0f0",
                marginLeft: "0",
                marginRight: "0",
                //height: "auto",
                padding: 1,
              }}
              catalogCount={ (catalogs===undefined?0:catalogs.length) }
              
            >
              <Box
                key="kpi-view"><StatisticsPanel /></Box>
              
              <Box
                key="create-tender"
                  sx={{
                    backgroundColor: "#ffffff",
                    margin: "auto",
                    borderRadius: 3,
                    display: "block   ",
                    cols: 2,
                    margin: "auto",
                    justifyContent: "center",
                  }}
                >
                  {catalogs &&
                    catalogs.map((catalog, index) => {
                      return (
                        <CatalogCard
                          key={index}
                          catalogInfo={catalog}
                          onClick={() => {
                            dispatch({
                              type: "initTender",
                              payload: {
                                tender: { catalogName: catalog.name, catalogVersion: catalog.version },
                              },
                            });
                            dispatch({
                              type: "setDealerDiscountPercentage",
                              payload: {
                                value: catalog.dealerDiscountPercentage,
                              },
                            });
                            console.debug("Selecting catalog");
                            setSelectedCatalog(catalog.name);
                            i18n.loadNamespaces(catalog.name);
                            setPhase("TENDERING");
                          }}
                        />
                      );
                    })}
                
              </Box>

              <Box
                key="stored-tenders"
                sx={{
                  backgroundColor: "#f0f0f0",
                  maxWidth: '95%',
                  marginLeft: "auto",
                  marginRight: "auto",
                  OBSOpadding: 3,
                  paddingTop:0,
                  flex:1, 
                  minHeight:'100%',
                  maxHeight:'100%'
                  //overflow:"auto"
                }}
              >
                <StoredTendersPanel
                  sx={{ borderRadius: 3
                   }}
                  username={username}
                  storedTenders={storedTenders}
                  selectStoredTenderCallback={(tenderId, action) => {
                    // find tender by tenderId
                    const tender=storedTenders.find((t)=> t.tenderId===tenderId);
                    if(tender===undefined) {
                      alert("Tender not found for action!");
                      return;
                    }
                    if (action === "DELETE") {
                      deleteTenderFromCloud(
                        token,
                        tender.tenderId,
                        (succeeded) => {
                          if (succeeded) {
                            console.log("Deleted tender");
                            //setStoredTenders(storedTenders.filter(t => t.tenderId !== tender.tenderId));
                            setStoredTendersUpdateCnt(
                              storedTendersUpdateCnt + 1
                            );
                          } else {
                            alert("ERROR: delete tender.");
                          }
                        }
                      );
                    } else if (action === "OPEN") {
                      console.log("Initializing tender with loaded data.");
                      console.log(tender);
                      setIsTenderPreloaded(true);
                      setSelectedCatalog(tender.catalogName);
                      dispatch({
                        type: "initTender",
                        payload: {
                          catalogName: tender.catalogName,
                          tender: tender,
                        },
                      });
                      setPhase("TENDERING");
                    } else if (action === "OPENCOPY") {
                      console.log("Initializing tender with loaded data BUT new tenderId.");
                      tender.tenderId=uuid(); // this forces new instance of the tender
                      tender.createdAt=formatISODateTime();
                      tender.createdBy=username;
                      tender.modifiedBy=username;
                      tender.modifiedAt=formatISODateTime();
                      tender.tenderStatus='NEW';

                      console.log(tender);
                      setIsTenderPreloaded(true);
                      setSelectedCatalog(tender.catalogName);
                      dispatch({
                        type: "initTender",
                        payload: {
                          catalogName: tender.catalogName,
                          tender: tender,
                        },
                      });
                      setPhase("TENDERING");
                    }
                  }}
                />
              </Box>
            </DashboardLayout>
          ),
          TENDERING: (
            <ProductSelectScreen
              loadCatalogName={selectedCatalog}
              isTenderPreloaded={isTenderPreloaded}
              lite={lite}
              preSelectProduct={preSelectedProduct}
            />
          ),
          TRANSLATING: <TranslatorScreen />,
          MANAGING_CATALOGS: <CatManScreen />,
        }[phase]
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  tender: state.tender,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) =>
    dispatch({ type: action.type, payload: action.payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
