import React,{ useState } from "react";

import { IconButton} from '@mui/material';
import ImageIcon from '@mui/icons-material/ImageOutlined';



export default function ImagePanel({showPopup=false, width='80%', imageSrc}) {
  const [isOpen,setIsOpen]=useState(false);



    return (
        (showPopup===false)?
                <img
                className="image"
                src={imageSrc}
                width={width}
                alt=""
              />
        :         
        <>
        <IconButton color="inherit" onClick={ () => { setIsOpen(true) }}><ImageIcon /></IconButton>
        {isOpen && (
          <dialog
            className="dialog"
            style={{ position: "absolute" }}
            open
            onClick={() => setIsOpen(false)}
          >
            <img
              className="image"
              src={imageSrc}
              onClick={() => setIsOpen(false)}
              alt=""
            />
          </dialog>
        )}
    </>
    );
        }  
