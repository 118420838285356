import React, { useState, useEffect } from "react";

import { Typography, IconButton, Tooltip } from "@mui/material";
import Box from "@material-ui/core/Box";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";

import { makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import { formatISODateTime } from "./UtilFunctions";
import { useUserContext } from './UserContext';
import { fetchStatisticsFromCloud } from './TendererDataService';
import { WrapText } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    border: "2px white solid",
    padding: 5,
    borderRadius: 10,
    width: "100%",
    backgroundColor: "#ffffff",
    fontFamily: "'Roboto', 'Arial', 'Sans Serif'"
  },
  statBox: {
    maxWidth: "90%",
    maxHeight: "90%",
    margin: "auto",
    justifyContent: "center",
    border: 1,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  kpiBox: {
    width: '90px',
    heigth: '100px',
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    border: "1px gray solid",
    borderRadius: 2,
    padding: 4,
    margin:2, boxShadow:"2px 2px",
  },
  img: {
    height: "inherit",
    maxWidth: "inherit",
  },
  input: {
    display: "none",
  },
  header: {
    display: "flex",
    flexDirection: "row",
  },
  rightCornerButton: {
    height: '16px',
    justifyContent: "right",
    marginRight: 0,
    marginTop: 0
  },
}));



export default function StatisticsPanel(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);

  const statusValues = [
    { code: "NEW", name: t("New") },
    { code: "TENDERED", name: t("Tendered") },
    { code: "WON", name: t("Won") },
    { code: "LOST", name: t("Lost") },
    { code: "CANCELLED", name: t("Cancelled") },
    { code: "ARCHIVED", name: t("Archived") },
    { code: "INQUIRY", name: t("Inquiry") },
  ];

  const {
    state: { username, token, catalogs, roles },
  } = useUserContext();

  const [statistics, setStatistics] = useState({refreshedAt: '-', inquiryCount: '-', tenderStatuses:[{code: 'NEW',count:0},{code: 'TENDERED',count:0}]});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function updateStatistics() {
    fetchStatisticsFromCloud(token, (data) => {

      if(data) {
        const tst=[];
        let inquiryCount="-";
        statusValues.forEach(s => {
          const tsi=data.tenderStatuses?.find(dts=>dts.code===s.code);
          if(tsi)
            if(tsi.code==='INQUIRY')
              inquiryCount=tsi.count;
            else
              tst.push({name: s.name, count: tsi.count});
        });

        setStatistics({
          refreshedAt: data.refreshedAt,
          dailyLogins: data.dailyLogins,
          dailyLiteVisitors: data.dailyLiteVisitors,
          inquiryCount: inquiryCount,
          tenderStatuses: tst,
          
        });    
      }
    });
  }

  useEffect(() => {
    updateStatistics();
  }, []);

  return (
    <div className={classes.root}>
     <Box className={classes.header}> 
        <Typography variant="body" component="div" color="primary">
          Statistics
        </Typography>
        <IconButton className={classes.rightCornerButton} color="inherit" onClick={ updateStatistics } ><RefreshIcon/></IconButton>
        </Box>
        <Box className={classes.statBox}>
        <Box className={classes.kpiBox}>
          <Typography variant="h3" component="div" color="primary">
            {statistics.dailyLogins}
          </Typography>
          <Typography variant="caption" component="div" color="primary">
            {t("Daily Logins")}
          </Typography>
        </Box>
        <Box className={classes.kpiBox}>
          <Typography variant="h3" component="div" color="primary">
            {statistics.dailyLiteVisitors}
          </Typography>
          <Typography variant="caption" component="div" color="primary">
            {t("Daily Lite Visitors")}
          </Typography>
        </Box>
        <Box className={classes.kpiBox}>
          <Typography variant="h3" component="div" color="primary">
            { statistics?.inquiryCount }
          </Typography>
          <Typography variant="caption" component="div" color="primary">
            {t("Open inquiries")}
          </Typography>
        </Box>
        { statistics && statistics.tenderStatuses &&
        <Box className={classes.kpiBox}>
            {  statistics.tenderStatuses.map((s,i) => (
          <Typography key={i} sx={{textAlign:'left'}} variant="caption" component="div" color="primary">
            {s?.name}:  {s?.count}
          </Typography>
          ))}
        </Box>
        }
      </Box>

      <Typography variant="caption" component="div" color="gray">
        { statistics.refreshedAt}
      </Typography>
    </div>
  );
}
