import React, { useEffect, useState } from "react";
import { Typography, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUserContext } from "./UserContext";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import PlusIcon from "@mui/icons-material/AddCircleOutlined";
import MinusIcon from "@mui/icons-material/RemoveCircleOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";


import Paper from "@mui/material/Paper";

import { StatusAlertService } from 'react-status-alert';
import 'react-status-alert/dist/status-alert.css'

import { updateCatalogUserToCloud, downloadCatalogUsersFromCloud, sendUserInvitation, removeCatalogUserFromCloud } from "./TendererDataService";
import uuid from 'react-uuid';
import { EntitySelectDialog } from "./EntitySelectDialog";
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from "./TenderStore";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function CatManUsersPanel({tender, dispatch }) {
  const { t } = useTranslation();
  const {
    state: { token },
  } = useUserContext();

  const { catalogData } = tender;

  const [catalogUsers, setCatalogUsers] = useState(undefined);

  const [editingCatalogUser, setEditingCatalogUser] = useState(undefined);

  function fetchCatalogUsers() {
    const cus=[];
    console.log("Fetching catalog users");
    downloadCatalogUsersFromCloud(token, catalogData.name, (users) => {
      if(users!==undefined && users.length>0) {
        users.forEach(u => {
          cus.push(
            {
              uuid: (u.uuid!==undefined?u.uuid:uuid()),
              username: u.username,
              email: u.email,
              status: u.status,
              catalogName: catalogData.name,
              roles: JSON.parse(JSON.stringify(u.roles)),
              dealerDiscountPercentage: u.dealerDiscountPercentage
            }
          );
        });
        setCatalogUsers(cus);
      } else {
        console.log("No catalog users found.");
        setCatalogUsers([]);
      }  
    });
  }

  useEffect(() => {
    // fetch catalogusers
    if(catalogUsers===undefined) {
      fetchCatalogUsers();
    }
  },[]);

  return (
    <>
      {
        editingCatalogUser !== undefined && catalogUsers?.map((cu, icu) => {
          console.log(editingCatalogUser);
          const entityInputFields=[
              {
                fieldType: "TextInput",
                entityPrompt: "ID",
                defaultValue: cu.uuid,
                readOnly: true
              },
              {
                fieldType: "TextInput",
                entityPrompt: "Username",
                defaultValue: cu.username,
                readOnly: (cu.username!=='-enter username-')
              },
              {
                fieldType: "TextInput",
                entityPrompt: "Email",
                defaultValue: cu.email,
                readOnly: false
              },
              {
                fieldType: "TextInput",
                entityPrompt: "Discount%",
                defaultValue: `${cu.dealerDiscountPercentage}`,
                readOnly: false
              },
              {
                fieldType: "Select",
                entityPrompt: "Roles for Catalog User",
                availableOptions: [
                  {code:'Administrator',name:''},
                  {code:'Manufacturer',name:''},
                  {code:'Importer',name:''},
                  {code:'Dealer',name:''},
                  {code:'Translator',name:''}],
                selectedOptions: cu.roles,
                multiselect: true
              },
              (cu.status==='InvitingNOTINUSE'?
              {
                fieldType: "TextInput",
                entityPrompt: "Status",
                defaultValue: "Inviting",
                readOnly: true
              }
              :
              {
                fieldType: "Select",
                entityPrompt: "Status",
                availableOptions: [
                  {code:'', name:''},
                  {code:'Inviting',name:''},
                  {code:'Invitation approved',name:''},
                  {code:'Active',name:''},
                  {code:'Locked',name:''}],
                selectedOptions: [cu.status],
                multiselect: false
              }),
              {
                fieldType: "TextInput",
                multilineRows: 3,
                entityPrompt: "Invitation email message",
                defaultValue: cu.invitation,
                readOnly: false
              },
              {
                fieldType: "Checkbox",
                entityPrompt: "Send invitation email",
                checked: false,
                readOnly: false
              }

          ];
          return (
            <React.Fragment key={icu}>
              {editingCatalogUser === cu.uuid+'EDIT' &&
                <EntitySelectDialog
                  dialogPrompt="Catalog User Details"
                  entityInputFields={entityInputFields}
                  selectionsChangedCallback={(cancelled,selectionValues) => {
                    console.log("Changes from dialog...");
                    if(cancelled) {
                      console.log('Cancelled');
                    } else {
                      console.log("User "+cu.username+" set new selections: ");
                      console.log(selectionValues);
                      // ID is in index 0
                      cu.username=selectionValues[1][0];
                      cu.email=selectionValues[2][0];
                      cu.dealerDiscountPercentage=parseFloat(selectionValues[3][0]);
                      
                      cu.roles=selectionValues[4];
                      cu.status=selectionValues[5]?selectionValues[5][0]:'Inviting';
                      cu.invitation=selectionValues[6]?selectionValues[6][0]:undefined;
                      
                      const newCatalogUsers=JSON.parse(JSON.stringify(catalogUsers));
                      setCatalogUsers(newCatalogUsers);
  //                    dispatch({ type: 'stageCatalogUsers', payload: { catalogUsers: newCatalogUsers } });

                      setEditingCatalogUser('NONEEDIT');
                      const sendInvitation=selectionValues[7]?selectionValues[7][0]:false;
                      console.log("Send invitation? "+sendInvitation);
                      if(sendInvitation) {
                        sendUserInvitation(token, cu.catalogName, cu.username, cu.email, cu.invitation, (success) =>
                        {
                          if(success) {
                            console.log("Succeeded.");
                            StatusAlertService.showSuccess('User invited successfully.');

                          } else {
                            console.log("Failed.");
                            StatusAlertService.showError('User invitation failed!');
                            return; // do not try to upload catalog anymore
                          }
                        }
                        );
                      }
                      console.log("Trying to upload catalog to cloud...");
                      updateCatalogUserToCloud(token,cu.catalogName,cu,(success) =>
                        {
                  
                          if(success)
                          {
                            console.log("Succeeded.");
                            StatusAlertService.showSuccess('Catalog user updated successfully.');
                            
                          }
                          else
                          {
                            alert("ERROR SAVING CATALOG USER TO CLOUD!");
                            console.log("Failed.");
                            StatusAlertService.showError('Catalog user update failed!');
                          }
                        }
                      );
                  
                    }
                  }
                }
                />
              }
            </React.Fragment>
          )
        }
        )}


      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 400, maxWidth: "100%" }}
          aria-label="catalog users table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell component="th" colSpan={5}>
                <Typography variant="caption" style={{color:"yellow"}}>
                {t('Notice that access rights are in effect immediately after changed!')}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" style={{ width: 100 }}>Username</TableCell>
              <TableCell component="th" style={{ width: 100 }}>Status</TableCell>
              <TableCell component="th" style={{ width: 200 }}>Catalog Roles</TableCell>
              <TableCell component="th" style={{ width: 100 }}>Dealer Discount %</TableCell>
              <TableCell component="th"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {catalogUsers?.map((cu, icu) =>
              <TableRow key={cu.username}>
                <TableCell style={{ verticalAlign: 'top',width:100 }}>
                  <>
                    {cu.username}
                  </>
                </TableCell>
                <TableCell style={{ width: 100, verticalAlign: 'top' }}>
                  <>
                    {cu.status &&
                      <Tooltip title="Please, check status details from user guide.">
                        <Button>{cu.status}</Button>
                      </Tooltip>
                    }
                  </>
                </TableCell>
                <TableCell style={{ verticalAlign: 'top' }}>
                  <>
                    {cu.roles?.map((cur, icur) =>
                      <Tooltip key={cu.username+cur} title="Please, refer user guide for role descriptions.">
                        <Button>{cur}</Button>
                      </Tooltip>
                    )}
                  </>
                </TableCell>
                <TableCell style={{ verticalAlign: 'top', width: 100 }}>
                  <>
                    {cu.dealerDiscountPercentage} %
                  </>
                </TableCell>
                <TableCell style={{ verticalAlign: 'top', width: 100 }}>
                  <IconButton id={cu.username + 'DEL'} color="inherit" onClick={() => {
                    console.log("Removing user.");

                    // call DELETE /catalogusers/{catalogName}/{username}
                    removeCatalogUserFromCloud(token,catalogData.name,cu.username,(success) => {
                      if(success)
                      {
                        console.log("Succeeded.");
                        StatusAlertService.showSuccess('Catalog user removed successfully.');
                        // fetch catalog users after the operation
                        fetchCatalogUsers();
                        
                      }
                      else
                      {
                        console.log("Failed.");
                        StatusAlertService.showError('Catalog user remove failed!');
                      }
                    });

/* no staging anymore
                    const newCatalogUsers = catalogUsers.filter(cu2 => cu2.username !== cu.username);
                    setCatalogUsers(newCatalogUsers);
                    dispatch({ type: 'stageCatalogUsers', payload: { catalogUsers: newCatalogUsers } });
                    console.log("Removed user " + cu.username);
*/
                  }} ><MinusIcon /></IconButton>
                  <IconButton id={cu.user + 'EDIT'} color="inherit" onClick={() => {
                    console.log("Editing user " + cu.username +" "+cu.uuid);
                    setEditingCatalogUser(cu.uuid+"EDIT");

                  }} ><EditIcon /></IconButton>
                </TableCell>
              </TableRow>
            )
            }
            <TableRow>
              <TableCell colSpan="5">
                <IconButton color="inherit" onClick={() => {
                  if(catalogUsers===undefined)
                    return;
                  console.log("Adding catalog user.");
                  const newCatalogUserUuid=uuid();

                  setCatalogUsers(catalogUsers.concat([{ uuid: newCatalogUserUuid, catalogName: catalogData.name, username:'-enter username-', status: 'Inviting', dealerDiscountPercentage:0, roles:[] }]));
                  setEditingCatalogUser(newCatalogUserUuid + 'EDIT');

                }} ><PlusIcon /><Typography variant="body2">Invite new user</Typography></IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>

  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CatManUsersPanel);