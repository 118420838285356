import uuid from "react-uuid";
import * as xlsx from "xlsx";

import { getLocalizedCatTextIfExists, formatISODateTime, formatInEuros } from "./UtilFunctions";





export function exportTender(tender, t) {
    console.log("Trying to export tender as excel");
 
    var workbook = xlsx.utils.book_new();

    var data=[
        [t('Customer')+' '+t('ref.'),""],
        [tender.customerRef.customerName,tender.customerRef.customerEmail],
        [tender.customerRef.customerAddress,tender.customerRef.customerCountry],
        [tender.customerRef.customerPhone,""]
    ];
    data.push([t("Product"),t("Code"),t("Name"),t("Price")]);
    data.push(["",tender.tenderingProduct.code, getLocalizedCatTextIfExists(tender.tenderingProduct.name,t,tender.catalogName,tender.tenderingProduct,'PROD-TITLE'),formatInEuros(tender.calculatedPriceTables.productPrice[2])]);
    
    // std parts
    data.push([t("Standard parts"),""]);
    tender.tenderingProduct.standardParts.forEach((part) => {
      data.push(["","std",getLocalizedCatTextIfExists(part.name,t,tender.catalogName,{product:tender.tenderingProduct,part:part},'PART-TITLE')+': '+getLocalizedCatTextIfExists(part.value, t, tender.catalogName,{product:tender.tenderingProduct,part:part},'PART-VALUE'),t('included')]);
    });

    // options
    data.push([t("Options"),""]);
    tender.selectedOptions.map((opt,index) => {
      data.push(["",opt.code,getLocalizedCatTextIfExists(opt.name,t,tender.catalogName,{product:tender.tenderingProduct,option:opt,code:opt.code},'OPT-TITLE'),formatInEuros(tender.calculatedPriceTables.selectedOptionPrices[index][2])]);
    });

    var worksheet = xlsx.utils.aoa_to_sheet(data);
    worksheet["!cols"] = [ { wch: 20 },{ wch: 10 },{ wch: 60 },{ wch: 20 } ];
    xlsx.utils.book_append_sheet(workbook, worksheet, t('Tender'));

    xlsx.writeFileXLSX(
      workbook,
      tender.tenderName + "_export_" + formatISODateTime("now") + ".xlsx"
    );

    console.log("Succeeded.");
  }

