/*

Remember to fix initTender and initialData if adding new fields to tender data to support old stored tenders with default or calculated values.


TODO:
Refactor calculations for list prices... dealer discount, dealer margin, customer prices, and also exchange deductin



*/

//import * as React from 'react'
import { createStore, combineReducers} from 'redux'
import uuid from 'react-uuid'
import { formatISODateTime } from './UtilFunctions';



//const TenderContext = React.createContext();

const initialState={ 
    tenderId: undefined,
    tenderName: undefined,
    catalogName: undefined,
    tenderStatus: 'NEW',
    customerRef: {},
    tenderingProduct: null, // tenderingProduct.price is list price
    selectedOptions: [], // option: code, name, price
    additionalItems: [{text:'', price:0}], // place holder for input (price is dealerPrice?)
    dealerDiscountPercentage: 0,
    dealerMarginPercentage: 0,
    dealerFreightPrice: 0,
    validationRules: [],
    calculatedPriceTables: {
        productPrice: [0,0,0],
        selectedOptionPrices: [], // for each [0,0,0]
        selectedOptionsSum: [0,0,0],
        exwFactorySum: [0,0,0],
        freightPrice: [null,0,0],
        additionalItemPrices: [], // for each [0,0,0]
        additionalItemsSum: [null,0,0],
        subTotal: [null,0,0],
        exchangeMachineryPrice: [null,null,0],
        total: [null,null,0] // subtotal - exhange machinery price
    },

    liteOn: false,
}


function calculatePrices( {
    state,
    tenderingProduct=state.tenderingProduct,
    selectedOptions=state.selectedOptions,
    dealerFreightPrice=state.calculatedPriceTables.freightPrice[1],
    additionalItems=state.additionalItems,
    dealerDiscountPercentage=state.dealerDiscountPercentage,
    dealerMarginPercentage=state.dealerMarginPercentage,
    customerExchangeMachineryPrice=state.calculatedPriceTables.exchangeMachineryPrice[2]
}
) 
{
    console.log("Using deader discount% "+dealerDiscountPercentage+" and margin% "+dealerMarginPercentage);
    const exchangeMachineryPrice=[null,null,customerExchangeMachineryPrice?customerExchangeMachineryPrice:0.0];
    // return calculatedPriceTables object
    const additionalItemPrices=[];
    const additionalItemsSum=[null,0,0];
    const freightPrice=[null, dealerFreightPrice,
        dealerFreightPrice?dealerFreightPrice*((100.0+dealerMarginPercentage)/100.0):0.0
    ];
    const selectedOptionPrices=[];
    const selectedOptionsSum=[0,0,0];
    const productPrice=[tenderingProduct.price, tenderingProduct.price*((100.0-dealerDiscountPercentage)/100.0),
        tenderingProduct.price*((100.0-dealerDiscountPercentage)/100.0)*((100.0+dealerMarginPercentage)/100.0)
    ];
    selectedOptions.forEach(o => {
        const d=o.price*((100.0-dealerDiscountPercentage)/100.0);
        const c=d*((100.0+dealerMarginPercentage)/100.0);
        selectedOptionPrices.push([o.price,d,c]);
        selectedOptionsSum[0]+=o.price;
        selectedOptionsSum[1]+=d;
        selectedOptionsSum[2]+=c;
    });
    const exwFactorySum=[
        productPrice[0]+selectedOptionsSum[0],
        productPrice[1]+selectedOptionsSum[1],
        productPrice[2]+selectedOptionsSum[2]
    ];
    additionalItems.forEach(i => {
        const d=i.price;
        const c=d*((100.0+dealerMarginPercentage)/100.0);
        additionalItemPrices.push([null,d,c]);
        additionalItemsSum[1]+=d;
        additionalItemsSum[2]+=c;
    });
    const subTotal=[
        null,
        exwFactorySum[1]+freightPrice[1]+additionalItemsSum[1],
        exwFactorySum[2]+freightPrice[2]+additionalItemsSum[2]
    ];
    const total=[null, null,subTotal[2]+exchangeMachineryPrice[2]];

    const retPriceTables = {
        productPrice: productPrice,
        selectedOptionPrices: selectedOptionPrices,
        selectedOptionsSum: selectedOptionsSum,
        exwFactorySum: exwFactorySum,
        freightPrice: freightPrice,
        additionalItemPrices: additionalItemPrices,
        additionalItemsSum: additionalItemsSum,
        subTotal: subTotal,
        exchangeMachineryPrice: exchangeMachineryPrice,
        total: total 
    };

    console.log("Calculated prices tables:");
    console.log(retPriceTables);
    return JSON.parse(JSON.stringify(retPriceTables));
}


// list prices

// dealer prices calculated from list prices (dealerDiscountPercentage) and net prices

// tendered prices are customer prices calculated from dealer prices + profit margin

// exchange machine is deducted from tendered price sum

// tendered total price is the final price

// how to calculate leasing prices? Based on tendered total price?




export const tender = (state = [], action) => {
    switch (action.type) {
        case 'setProduct': {
            console.log("TenderStore: Setting product "+action.payload.product.code);
            const newTenderData=initialState;
            //if(state.productaction.payload.product.code!==state.product.code) {
                console.log("Selecting product resets tenderId.");
                newTenderData.tenderId=uuid();
                newTenderData.tenderName=undefined;
                newTenderData.catalogId=action.payload.catalogId;
                newTenderData.catalogName=action.payload.catalogName;
                newTenderData.tenderStatus='NEW';
                newTenderData.customerRef={};
                newTenderData.additionalItems=[{text:'', price:0}];
                newTenderData.dealerDiscountPercentage=state.dealerDiscountPercentage;
                newTenderData.dealerMarginPercentage=0;
                newTenderData.dealerFreightPrice=0;
                newTenderData.selectedOptions=[];
                newTenderData.tenderingProduct=action.payload.product;
                newTenderData.calculatedPriceTables=calculatePrices({state:newTenderData});
            //}
            return { ...newTenderData, validationRules: state.validationRules, liteOn: state.liteOn }
        }
        case 'setProductAndOptions': {
            console.log("TenderContext: Setting selected options:");
            console.log(action.payload.options);
            return { ...state, 
                tenderingProduct: action.payload.product, 
                selectedOptions: action.payload.options,
                calculatedPriceTables: JSON.parse(JSON.stringify(calculatePrices({state:state, tenderingProduct:action.payload.product,selectedOptions:action.payload.options})))
            }
        }
        case 'setFreightPrice': {
            console.log("TenderContext: Setting freight price  "+action.payload.freightPrice);
            console.log(state);
            
            return { ...state,
                //dealerFreightPrice: action.payload.dealerFreightPrice,
                calculatedPriceTables: calculatePrices({state:state,dealerFreightPrice:action.payload.freightPrice})
            }
        }
        case 'setAdditionalPrice': {
            const newAdditionalItems=state.additionalItems;
            const index=action.payload.additionalItemIndex;
            
            if(state.additionalItems.length>index) {
                const newItem={ 
                    text: state.additionalItems[index].text,
                    price: action.payload.additionalPrice };
                newAdditionalItems[index]=newItem;

            } else {
                console.log("INFO TenderContext: trying to set price to non existing additional item row "+index);
            }
            return { ...state,additionalItems: newAdditionalItems,
                calculatedPriceTables: calculatePrices({state:state,additionalItems:newAdditionalItems})
            }
        }
        case 'setAdditionalText': {
            const newAdditionalItems=state.additionalItems;
            const index=action.payload.additionalItemIndex;
            if(state.additionalItems.length>index) {
                const newItem={ 
                    text: action.payload.additionalText,
                    price: state.additionalItems[index].price };
                newAdditionalItems[index]=newItem;
            } else {
                console.log("INFO TenderContext: trying to set text to non existing additional item row "+index);
            }
            return { ...state,additionalItems: newAdditionalItems }
        }
        case 'addAdditionalItem': {
            const newAdditionalItems=state.additionalItems;
            if(true) {
                console.log("TenderContext: Adding additional item.");
                const newItem={ text: '', price:0 };
                newAdditionalItems.push(newItem);

            }
            return { ...state,additionalItems: newAdditionalItems,
                calculatedPriceTables: calculatePrices({state:state,additionalItems:newAdditionalItems})
            }
        }

        case 'setExchangeMachineryPrice': {
            return { ...state,
                calculatedPriceTables: calculatePrices({state:state, customerExchangeMachineryPrice: action.payload.exchangeMachineryPrice})
            }
        }
        case 'setCustomerRef': {
            console.log("TenderStore: Setting customer ref  "+action.payload);
            const newCustomerRef=
                {
                    customerName: (action.payload.customerName!==undefined)?action.payload.customerName:state.customerRef.customerName,
                    customerEmail: (action.payload.customerEmail!==undefined)?action.payload.customerEmail:state.customerRef.customerEmail,
                    customerCountry: (action.payload.customerCountry!==undefined)?action.payload.customerCountry:state.customerRef.customerCountry,
                    customerAddress: (action.payload.customerAddress!==undefined)?action.payload.customerAddress:state.customerRef.customerAddress,
                    customerPhone: (action.payload.customerPhone!==undefined)?action.payload.customerPhone:state.customerRef.customerPhone,
                    customerMessage: (action.payload.customerMessage!==undefined)?action.payload.customerMessage:state.customerRef.customerMessage
                };
            return { ...state,customerRef: newCustomerRef }
        }
        case 'clearOptions': {
            console.log("TenderContext: clear product and Options for new product selection");
            return { ...state, selectedOptions: [] }            
        }
        case 'initTender': {
            console.log("Initializing tender with payload data**********************FIX!!!!!");

            console.log(action.payload);
            if (action.payload.tender!==undefined) {
                console.log("Catalog: "+action.payload.tender.catalogName);
                return ({ ...initialState,...action.payload.tender
                });
                
               //throw new Error("TRYING TO INIT TENDER WITH DATA!!!!");
            }
            else
                return {...initialState,catalogName: action.payload.catalogName, liteOn: action.payload.liteOn}
            }
        case 'initValidationRules': {
            console.log("Initializing catalog validation rules");
            console.log(action.payload.rules);
            
            return {...state, validationRules: (action.payload.rules!==undefined?[].concat(action.payload.rules):[]) }
        }
        case 'setDealerMarginPercentage': {
            console.log("Setting dealer margin percentage: "+action.payload.value);
            return ({...state, dealerMarginPercentage: action.payload.value,
                calculatedPriceTables: calculatePrices({state:state, dealerMarginPercentage:action.payload.value})} );
        }
        case 'setDealerDiscountPercentage': {
            console.log("Setting dealer discount percentage: "+action.payload.value);
            return {...state, dealerDiscountPercentage: action.payload.value }
                // no need to calc since setting only when selecting new product calculatedPriceTables: calculatePrices({state:state, dealerDiscountPercentage:action.payload.value}) }
        }
        case 'updatePricesForProduct': {
            console.log("Updating prices for product: "+action.payload.value);
            // 
            const tenderingProduct=state.tenderingProduct;
            tenderingProduct.price=action.payload.catalogProduct.price;
            const selectedOptions=state.selectedOptions;
            selectedOptions.forEach((so)=> {
                so.price=action.payload.catalogProduct.options.find((o)=>o.code===so.code)?.price;
            });
            return { ...state, 
                tenderingProduct: tenderingProduct, 
                selectedOptions: selectedOptions,
                calculatedPriceTables: JSON.parse(JSON.stringify(calculatePrices({state:state, tenderingProduct:tenderingProduct,selectedOptions:selectedOptions})))
            }            
        }
        case 'useLiteVersion': {
            console.log("Setting lite version on");
            return { ...state, liteOn:true }
        }
        case 'logTenderData': {
            console.log("Looging only additional items for now:");
            for (let i=0; i < state.additionalItems.length;i++) {
                console.log(state.additionalItems[i].text+" price="+state.additionalItems[i].price);
            }
            return { ...state }
        }



        //
        //
        // CATALOG MANAGER ACTIONS
        //
        case 'initCatalogData': {
            console.log("CatalogManagerContext: setting catalog data");
            console.log(action.payload.catalogData);
            return {...state, catalogData: JSON.parse(JSON.stringify(action.payload.catalogData)),
                selectedEntity:(action.payload.setSelected?state.catalogData:state.selectedEntity)
            }
        }

        case 'resetStagedChanges': {
            console.log("CatalogManagerContext: resetting staged changes for entity: ");
            console.log(state.selectedEntity);
            // selected entity options and standard parts arrays are undefined if not a product
            return {...state, selectedEntityModified:false, productOptions:[].concat[state.selectedEntity.options], productAttachments:[].concat[state.selectedEntity.attachments], productStandardParts:[].concat[state.selectedEntity.standardParts] }
        }
        

        case 'flagEntityModified': {
            console.log("CatalogManagerContext: flagging currently editing entity to be modified");
            return {...state, selectedEntityModified:true }
        }

        case 'addEntity': {
            const newCatData=state.catalogData;
            const entity=validateEntity(action.payload.entityType,{ code: action.payload.entityCode}); // adds required fields uuid etc.
            if(action.payload.entityType==='rule') {
                newCatData.rules.push(entity);
                return {...state, catalogData: newCatData,
                    editingProduct:undefined,
                    editingOption:undefined,
                    editingAttachment:undefined,
                    editingCatalog:state.catalogData.code,
                    selectedEntity:entity,
                }
            }            
            if(action.payload.entityType==='option') {
                newCatData.options.push(entity);
                return {...state, catalogData: newCatData,
                    editingProduct:undefined,
                    editingOption:entity.code,
                    editingAttachment:undefined,
                    editingCatalog:undefined,
                    selectedEntity:entity,
                }
            }            
            if(action.payload.entityType==='attachment') {
                newCatData.attachments.push(entity);
                return {...state, catalogData: newCatData,
                    editingProduct:undefined,
                    editingOption:undefined,
                    editingAttachment:entity.code,
                    editingCatalog:undefined,
                    selectedEntity:entity,
                }
            }            
            if(action.payload.entityType==='product') {
                const index=newCatData.products.push(entity);
                return {...state, catalogData: newCatData,
                    editingProduct:entity.code,
                    editingOption:undefined,
                    editingAttachment:undefined,
                    editingCatalog:undefined,
                    selectedEntity:(action.payload.setSelected?newCatData.products[index]:state.selectedEntity)
                }
            }
            break;            
        }
        case 'saveEntity': {
            console.log("Saving entity "+action.payload.entityType+" to memory:");
            const entity=validateEntity(action.payload.entityType,action.payload.entity); // validation of data?
            console.log(entity);
            if(action.payload.entityType==='catalog')
            {
                // special
                const newCatalogData=state.catalogData;
                newCatalogData.name=action.payload.entity.code;
                newCatalogData.name=action.payload.entity.name;
                newCatalogData.manufacturer=action.payload.entity.manufacturer;
                newCatalogData.description=action.payload.entity.description;
                newCatalogData.version=action.payload.entity.version;
                newCatalogData.pricesUpdatedAt=action.payload.entity.pricesUpdatedAt;
                newCatalogData.logo=action.payload.entity.logo;
                newCatalogData.published=action.payload.entity.published;
                newCatalogData.markedForDeletion=action.payload.entity.markedForDeletion;
                newCatalogData.includeProductOptions=action.payload.entity.includeProductOptions;
                newCatalogData.includeProductAttachments=action.payload.entity.includeProductAttachments;
                
                newCatalogData.modifiedBy=action.payload.modifiedBy;
                newCatalogData.modifiedAt=formatISODateTime('now');

/*                const resultDiffRules = state.catalogRules?.filter(({ uuid: id1 }) => !entity.rules?.some(({ uuid: id2 }) => id2 === id1));
                if(resultDiffRules!==undefined && resultDiffRules.length>0) {
                    console.log("Including staged rule changes");
                    newCatalogData.rules=[].concat(resultDiffRules);
                }
*/
                // Special when saving a catalog the user must be in Administrators group


                return {...state, 
                    catalogData: newCatalogData,
                    editingProduct:undefined,
                    editingOption:undefined,
                    editingAttachment:undefined,
                    editingCatalog:newCatalogData.code,
                    selectedEntityModified: false,
                    selectedEntity:(action.payload.setSelected?state.catalogData:state.selectedEntity)}
            }
            if(action.payload.entityType==='product')
            {
                const newCatalogData=state.catalogData;
                console.log("Saving entity");
                console.log(entity);
                console.log(newCatalogData);

                let index=state.catalogData.products.findIndex(e => e.code===entity.code);
                if(index===-1)
                    index=newCatalogData.products.findIndex(e=>e.code==='- enter code -'); // if this does not return then error
                newCatalogData.products[index]=entity;
                // check if staged some changes for product options or standard parts
                const resultDiffOptions = state.productOptions.filter(({ code: id1 }) => !entity.options.some(({ code: id2 }) => id2 === id1));
                if(resultDiffOptions.length>0) {
                    console.log("Including staged option selection changes");
                    entity.options=[].concat(state.productOptions);
                }
                const resultDiffAttachments = state.productAttachments?.filter(({ code: id1 }) => !entity.attachments.some(({ code: id2 }) => id2 === id1));
                if(resultDiffAttachments!==undefined && resultDiffAttachments.length>0) {
                    console.log("Including staged attachment selection changes");
                    entity.attachments=[].concat(state.productAttachments);
                }
                const resultDiffStdParts = state.productStandardParts.filter(({ code: id1, name: n1, value: v1 }) => 
                    !entity.standardParts.some(({ code: id2, name: n2, value: v2 }) => (id2 === id1 && n1===n2 && v1===v2)));
                if(resultDiffStdParts.length>0) {
                    console.log("Including staged standard parts changes");
                    entity.standardParts=[].concat(state.productStandardParts);
                }

                newCatalogData.products[index]=entity;
                    return {...state, 
                    catalogData: newCatalogData,
                    editingProduct:entity.code,
                    editingOption:undefined,
                    editingCatalog:undefined,
                    editingAttachment:undefined,
                    selectedEntityModified: false,
                    selectedEntity:(action.payload.setSelected?state.catalogData.products[index]:state.selectedEntity)
                }
            }
            if(action.payload.entityType==='option')
            {
                const newCatalogData=state.catalogData;
                let index=state.catalogData.options.findIndex(e => e.code===action.payload.entity.code);
                if(index===-1)
                    index=newCatalogData.options.findIndex(e=>e.code==='- enter code -'); // if this does not return then error
                newCatalogData.options[index]=JSON.parse(JSON.stringify(action.payload.entity));
                return {...state, 
                    catalogData: newCatalogData,
                    editingProduct:undefined,
                    editingOption:action.payload.entity.code,
                    editingAttachment:undefined,
                    editingCatalog:undefined,
                    selectedEntityModified: false,
                    selectedEntity:(action.payload.setSelected?state.catalogData.options[index]:state.selectedEntity)
                }
            }
            if(action.payload.entityType==='attachment')
            {
                const newCatalogData=state.catalogData;
                let index=state.catalogData.attachments.findIndex(e => e.code===action.payload.entity.code);
                if(index===-1)
                    index=newCatalogData.attachments.findIndex(e=>e.code==='- enter code -'); // if this does not return then error
                newCatalogData.attachments[index]=JSON.parse(JSON.stringify(action.payload.entity));
                return {...state, 
                    catalogData: newCatalogData,
                    editingProduct:undefined,
                    editingOption:undefined,
                    editingAttachment:action.payload.entity.code,
                    editingCatalog:undefined,
                    selectedEntityModified: false,
                    selectedEntity:(action.payload.setSelected?state.catalogData.attachments[index]:state.selectedEntity)
                }
            }
            if(action.payload.entityType==='rule')
            {
                const newCatalogData=state.catalogData;
                let index=state.catalogData.rules.findIndex(e => e.uuid===action.payload.entity.uuid);
                if(index===-1)
                    newCatalogData.rules.push(JSON.parse(JSON.stringify(action.payload.entity)));
                else
                    newCatalogData.rules[index]=JSON.parse(JSON.stringify(action.payload.entity));
                return {...state, 
                    catalogData: newCatalogData,
                    editingProduct:undefined,
                    editingOption:undefined,
                    editingAttachment:undefined,
                    editingCatalog:newCatalogData.code,
                    selectedEntityModified: false,
                    selectedEntity:(action.payload.setSelected?state.catalogData:state.selectedEntity)}
                }

            throw new Error(`Unhandled action saveEntity for entityType: ${action.payload.entity.entityType}`);
        }
        case 'selectEntity': {
            console.log("Selecting "+JSON.stringify(action.payload));
            console.log(action.payload.entityCode);
            if(action.payload.entityType==='catalog') {
                return {...state, editingOption: undefined,editingProduct:undefined,
                    editingCatalog:state.catalogData.name,
                    editingAttachment:undefined,
                    selectedEntity: state.catalogData,
                    selectedEntityModified: false,
                    productStandardParts: [], productOptions: [], productAttachments: [],
                    editingRuleSet: (action.payload.subEntity==='ruleSet'),
                    managingAccounts: (action.payload.subEntity==='managingAccounts')
                }
            }
            if(action.payload.entityType==='option') {
                const optInx=state.catalogData.options.findIndex(e=>e.code===action.payload.entityCode);
                console.log("Selected "+(optInx===-1?'none':state.catalogData.options[optInx].name));
                return {...state, editingOption: action.payload.entityCode,
                    editingCatalog:undefined, editingProduct:undefined,
                    editingAttachment:undefined,
                    selectedEntityModified: false,
                    selectedEntity: state.catalogData.options[optInx],
                    productStandardParts: [], productOptions: [], productAttachments: []
                }
            }
            if(action.payload.entityType==='attachment') {
                const attInx=state.catalogData.attachments.findIndex(e=>e.code===action.payload.entityCode);
                console.log("Selected "+(attInx===-1?'none':state.catalogData.attachments[attInx].name));
                return {...state, editingAttachment: action.payload.entityCode,
                    editingCatalog:undefined, editingProduct:undefined,
                    editingOption:undefined,
                    selectedEntityModified: false,
                    selectedEntity: state.catalogData.attachments[attInx],
                    productStandardParts: [], productOptions: [], productAttachments: []
                }
            }
            if(action.payload.entityType==='product') {
                const prodInx=state.catalogData.products.findIndex(p=>p.code===action.payload.entityCode);
                console.log("Selected "+(prodInx===-1?'none':state.catalogData.products[prodInx].name));
                return {...state, editingProduct: action.payload.entityCode, editingCatalog:undefined, editingOption: undefined,
                        selectedEntity: state.catalogData.products[prodInx],
                        editingAttachment:undefined,
                        selectedEntityModified: false,
                        productStandardParts: [].concat(prodInx>-1?state.catalogData.products[prodInx].standardParts:[]),
                        productOptions: [].concat(prodInx>-1?state.catalogData.products[prodInx].options:[]),
                        productAttachments: [].concat(prodInx>-1&&state.catalogData.products[prodInx].attachments!==undefined?state.catalogData.products[prodInx].attachments:[]),
                 }
            }

            break;
        }

        case 'stageCatalogRules': {
            console.log("Staging catalog rules modification:");
            console.log(action.payload.catalogRules);
            return {...state, selectedEntityModified: true, catalogRules: action.payload.catalogRules}
        }

        case 'stageCatalogUsers': {
            console.log("Staging catalog users modification:");
            console.log(action.payload.catalogUsers);
            return {...state, selectedEntityModified: true, catalogUsers: action.payload.catalogUsers}
        }

        case 'stageProductStandardParts': {
            console.log("Staging standard parts modification:");
            console.log(action.payload.productStandardParts);
            return {...state, selectedEntityModified: true, productStandardParts: action.payload.productStandardParts}
        }
        case 'stageProductOptions': {
            console.log("Staging product options:");
            console.log(action.payload.productOptions);
            return {...state, selectedEntityModified: true, productOptions: action.payload.productOptions}
        }
        case 'stageProductAttachments': {
            console.log("Staging product attachments:");
            console.log(action.payload.productAttachments);
            return {...state, selectedEntityModified: true, productAttachments: action.payload.productAttachments}
        }

        case 'stageImages': {
            console.log("Staging images for entity "+action.payload.entity?.code);
            console.log(action.payload.images);
            const newCatalogData=state.catalogData;
            if(action.payload.entityType==='product' && action.payload.images?.length>0)
            {
                let index=state.catalogData.products.findIndex(e => e.code===action.payload.entity.code);
                if(index===-1)
                    index=newCatalogData.products.findIndex(e=>e.code==='- enter code -'); // if this does not return then error
                const prod=newCatalogData.products[index];
                prod.image=action.payload.images[0].name;
                newCatalogData.products[index]=prod;
            } else if(action.payload.entityType==='option' && action.payload.images?.length>0)
            {
                let index=state.catalogData.options.findIndex(e => e.code===action.payload.entity.code);
                if(index===-1)
                    index=newCatalogData.options.findIndex(e=>e.code==='- enter code -'); // if this does not return then error
                const opt=newCatalogData.options[index];
                if(opt===undefined)
                    console.error("Error opt is undefined!");
                opt.image=action.payload.images[0].name;
                newCatalogData.options[index]=opt;
            } else if(action.payload.entityType==='catalog' && action.payload.images?.length>0) {
                newCatalogData.logo=action.payload.images[0].name;
            }
            return {...state, catalogData: newCatalogData, selectedEntityModified:true, images: action.payload.images}
        }

        case 'catalogDataSaved': {
            // saved to cloud successfully... select catalog and reset all other context variables
            return {...state, selectedEntityModified: false,
                editingCatalog:state.catalogData.name,
                editingProduct:undefined,
                editingOption:undefined,
                editingAttachment:undefined,
                selectedEntity: state.catalogData,
                productStandardParts: [], productOptions: [],  productAttachments: [], images:[]}
        }

        case 'cancelCatalogEditing': {
            return {...initialCatalogState}
        }

        default: {
            console.log(action.type.substr(0,7));
            if(action.type.substr(0,7)!=='@@redux' && action.type.substr(0,6)!=='@@INIT')
                console.error("TenderStore called with unhandled type:");
            console.log(action);
            return state;
        }
    }
}

function validateEntity(entityType,entity) {
    if(entity.id===undefined)
        entity.id=uuid();
    if(entityType==='product') {
        if(entity.standardParts===undefined)
            entity.standardParts=[];
        if(entity.options===undefined)
            entity.options=[];
        if(entity.attachments===undefined)
            entity.attachments=[];
    }
    return entity;
}

const initialCatalogState={ 
    updateCounter: 1,
    catalogData: undefined, // data itself
    editingCatalog: true, // catalog name ie. code
    editingProduct: undefined, // product code
    editingOption: undefined,  // option code
    editingAttachment: undefined, // attachment code
    selectedEntity: undefined, // current selection
    productStandardParts: [], // this stores the standard parts for product that is currenly edited but not committed
    productOptions: [], // this stores the options for product that is currenly edited but not committed
    productAttachments: [], // this stores the attachments for product that is currenly edited but not committed
    images: [],
    catalogRules: [],
    selectedEntityModified: false,
    editingRuleSet: false,
    managingAccounts: false,
    catalogUsers: []
}



//

export const mapStateToProps = state => ({
    tender: state.tender,
//    catalog: state.catalog,
  });
  
export const mapDispatchToProps = dispatch => ({
    dispatch: (action) => dispatch({type:action.type, payload:action.payload})});

// store construction

const reducers = { tender };

const rootReducer = combineReducers(reducers);

export const configureStore = () => createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
