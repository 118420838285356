import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './css/react-grid-layout/styles.css';
import './css/react-resizable/styles.css';

import './i18n/config';

import { UserContextProvider } from './UserContext';
import { Provider } from 'react-redux';
import { configureStore } from './TenderStore';



import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#262626',
    },
    secondary: {
      main: '#ffcc01',
    },
    
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});


// tender store (redux)
const tenderStore = configureStore();

window.addEventListener("popstate", e => {
  // Nope, go back to your page
  this.props.history.go(1);
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <Suspense fallback={<h1>Loading...</h1>}>
    <UserContextProvider>
      <Provider store={tenderStore} >
    <App />
    </Provider>
    </UserContextProvider>
    </Suspense>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.unregister();
