import React, { useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {Checkbox, IconButton, Box} from "@mui/material";
import DownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import UpIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import Paper from "@mui/material/Paper";

import "./ProductCard.css";

import { useTranslation } from "react-i18next";

import { getLocalizedCatTextIfExists } from "./UtilFunctions";

import ImagePanel from './ImagePanel';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from "./TenderStore";



function CatManAttachmentsPanel({tender, dispatch}) {
  const { t } = useTranslation();
  const { catalogData, productAttachments } = tender;


  const [openedAttachmentDetails, setOpenedAttachmentDetails] = useState('none');

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 400, maxWidth: 800 }}
        aria-label="options table"
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell>{t('Code')}</TableCell>
            <TableCell align="center">{t('Select')}</TableCell>            
            <TableCell width="70%" align="left">{t('Attachment')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { console.log(productAttachments) }
          {productAttachments && catalogData.attachments?.map((attachment,index) => (
            <TableRow
              key={attachment.code}
            >
              <TableCell component="th" scope="row">
                {attachment.code}
              </TableCell>
              <TableCell padding="checkbox">
                <Box style={{flexDirection:'column'}}>
                <Checkbox
                  color="primary"
                  checked={(productAttachments.some(o => o.code === attachment.code))}
                  onChange={ (event) => {
                      console.log("Checkbox change for "+attachment.code);
                      console.log(productAttachments);
                      console.log(attachment);
                      if(productAttachments.includes(attachment)) {
                        const newSelectedAttachments=productAttachments.filter(o => o.code !== attachment.code);
                        dispatch({type:'stageProductAttachments', payload: {productAttachments: newSelectedAttachments}});
                      } else {
                          const newSelectedAttachments=productAttachments.concat(catalogData.attachments.filter(o => o.code === attachment.code));
                          dispatch({type:'stageProductAttachments', payload: {productAttachments: newSelectedAttachments}});
                      }

                    }}
                />
                </Box>
              </TableCell>
              <TableCell align="left">
                {getLocalizedCatTextIfExists(attachment.name,t,catalogData.name,{code:attachment.code},'ATT-TITLE') }
                { attachment.image && openedAttachmentDetails===attachment.code &&
                <>
                <IconButton color="inherit" onClick={ () => { setOpenedAttachmentDetails('none') }}><UpIcon /></IconButton>
                <ImagePanel width={300} imageSrc={(window.location.hostname==='localhost'?'https://tenderer.cappgw.com':'')+'/assets/catalogs/'+catalogData.name+'/assets/attachmentimages/'+attachment.image} />
                </>
                }
                { attachment.image && openedAttachmentDetails==='none' &&
                <IconButton color="inherit" onClick={ () => { setOpenedAttachmentDetails(attachment.code) }}><DownIcon /></IconButton>
                }
                
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CatManAttachmentsPanel);