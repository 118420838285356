import { Card, CardContent, CardMedia, Button, CardHeader, Typography, Box } from '@mui/material';

import React, { useState } from 'react';

import './ProductCard.css';

import { useTranslation } from 'react-i18next';
import { getLocalizedCatTextIfExists } from "./UtilFunctions";




export default function ProductCard({ catalog, product, showDetails=true, showOptions=false, size }) {
    const { t } = useTranslation();
    const [showDetailsPane,setShowDetailsPane]=useState(showDetails);
    const productImage = product.image;

    return (
        <Card sx={{ 
            width: size.width, height: size.height, margin: 0, boxShadow:0 }}>
                { false &&
            <CardHeader title={ getLocalizedCatTextIfExists(product.name,t,catalog,product,'PROD-TITLE') } sx={{
                border: 0, backgroundColor:'transparent', width: size.width }}
                subheader={ product!==undefined && product.price!==undefined?(product.price*1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+' €':'' } />
            }   
                <Box sx={{ flowDirection: 'row', position: 'absolute', marginTop: -12, marginLeft: 5, boxShadow: 1, color: 'white', backgroundColor: 'rgba(0,0,0,0.5)' }} >
                <Typography variant="h4" sx={{
                    color: 'white',
                    textShadowColor: 'rgba(0, 0, 0, 0.75)',
                    textShadowOffset: {width: -1, height: 1},
                    textShadowRadius: 10 
                    }}>{ getLocalizedCatTextIfExists(product.name,t,catalog,product,'PROD-TITLE') }</Typography>
                <Typography variant="subtitle2">{t('Base Price')}</Typography>
                <Typography variant="body" sx={{marginLeft: 2, marginRight: 2}}>{ product!==undefined && product.price!==undefined?(product.price*1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+' €':'' }</Typography>
                </Box>
                 { false && <CardMedia
                    component="img"
                    sx={{ maxHeight: 400 }}
                    image={(window.location.hostname==='localhost'?'https://tenderer.cappgw.com':'')+'/assets/catalogs/'+catalog+'/assets/productimages/'+productImage}
                    alt={getLocalizedCatTextIfExists(product.name,t,catalog,product,'PROD-TITLE')}
                />          
                 }      
            <CardContent>
{ showDetailsPane?
                <div style={{ }}>
                <table>
                    <tbody>
                    <tr><th>{t('Title')}</th><th>{t('Description')}</th></tr>
                    { product.standardParts.map((part,index) => {
                        return (
                            <tr key={index} ><td>{ getLocalizedCatTextIfExists(part.name,t,catalog,{product:product,part:part},'PART-TITLE') }</td><td>{ getLocalizedCatTextIfExists(part.value,t,catalog,{product:product,part:part},'PART-VALUE') }</td></tr>
                        );
                    })}
                    </tbody>
                </table>
                <br/>
                </div>
                :
                null
}
            { false &&
                <Button onClick={()=> { setShowDetailsPane(!showDetailsPane) }} >{ t(showDetailsPane?'Hide Details':'Show Details') }</Button>
            }
            </CardContent>
         
        </Card>
    );
}

