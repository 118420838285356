import React,{useState, useEffect} from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {TextField,Button,IconButton, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";

import CalcIcon from '@mui/icons-material/CalculateOutlined';
import EditIcon from "@mui/icons-material/EditOutlined";
import PlusIcon from '@mui/icons-material/AddCircleOutlined';

import "./ProductCard.css";
import { formatInEuros, getLocalizedCatTextIfExists } from "./UtilFunctions";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { mapStateToProps,mapDispatchToProps} from './TenderStore';





//export default 
function TenderDetailsPanel({
  tender=[], dispatch,
    readOnly = false,
    showDealerPrices=true,
    size = { width: 800 },
}) {
  //const {dispatch} = useTender();
  const {tenderingProduct,
        selectedOptions,
        dealerDiscountPercentage, 
        dealerMarginPercentage,
        additionalItems, 
        exchangeMachineryPrice,
        freightPrice,
        customerRef,
        catalogName,
        calculatedPriceTables,
      liteOn } = tender;

  const { t } = useTranslation(['appcommon','CastLoaders']); // t defaults to appcommon


  const [enteringDealerMarginInEuros,setEnteringDealerMarginInEuros]=useState(false);
  const [enteredDME, setEnteredDME] = useState(0);
  
  //const [showCustomerRef,setShowCustomerRef] = useState(false); // testing
  //const [showPrices,setShowPrices] = useState(false); // testing

    // force scroll to top when loading
    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }, []);
  
  
  return (
    tenderingProduct===null?
    <Typography variant="h4">{t('Initializing...')}</Typography>
    :
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 300, maxWidth: size.width, border: 1, marginTop:2, marginBottom:2 }}
        aria-label="tender details table"
        size="small"
      >
        <TableHead>
        { !liteOn &&
        <>
        <TableRow>
            <TableCell component="th" colSpan={showDealerPrices?4:3}>{t('Customer')}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" >{t('ref.')}</TableCell>
            <TableCell component="td"  colSpan={showDealerPrices?3:2} align="left">
            { readOnly?
                    <>
                    <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
                      { customerRef?.customerName }
                    </Typography>
                    <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
                      { customerRef?.customerEmail }
                    </Typography>
                    <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
                      { customerRef?.customerPhone }
                    </Typography>
                    <Typography variant="caption" style={{whiteSpace: 'pre-line'}}>
                      { customerRef?.customerAddress }
                    </Typography>
                    <Typography variant="caption" style={{whiteSpace: 'pre-line'}}>
                      { customerRef?.customerCountry }
                    </Typography>
                    <Typography variant="caption" style={{whiteSpace: 'pre-line'}}>
                      { customerRef?.customerMessage }
                    </Typography>
                    </>
                    :
                    <>
                    <TextField id={'customer-ref-name'} label={t('Customer name')} variant="standard"
                    multiline minRows={2} maxRows={Infinity}
                    fullWidth
                    defaultValue={customerRef?.customerName}
                    onChange={(e) => { console.log("Customer ref: "+e.target.value); dispatch({ type: 'setCustomerRef', payload: { customerName: e.target.value }})}} />
                    <TextField id={'customer-ref-email'} label={t('Customer email')} variant="standard"
                    fullWidth
                    defaultValue={customerRef?.customerEmail}
                    onChange={(e) => { console.log("Customer ref: "+e.target.value); dispatch({ type: 'setCustomerRef', payload: { customerEmail: e.target.value }})}} />
                    <TextField id={'customer-ref-phone'} label={t('Customer phone')} variant="standard"
                    fullWidth
                    defaultValue={customerRef?.customerPhone}
                    onChange={(e) => { console.log("Customer ref: "+e.target.value); dispatch({ type: 'setCustomerRef', payload: { customerPhone: e.target.value }})}} />
                    <TextField id={'customer-ref-address'} label={t('Customer address')} variant="standard"
                    multiline minRows={2} maxRows={4}
                    fullWidth
                    defaultValue={customerRef?.customerAddress}
                    onChange={(e) => { console.log("Customer ref: "+e.target.value); dispatch({ type: 'setCustomerRef', payload: { customerAddress: e.target.value }})}} />
                    <TextField id={'customer-ref-message'} label={t('Customer message')} variant="standard"
                    multiline minRows={2} maxRows={Infinity}
                    fullWidth
                    defaultValue={customerRef?.customerMessage}
                    onChange={(e) => { dispatch({ type: 'setCustomerRef', payload: { customerMessage: e.target.value }})}} />
                    </>
           }
            </TableCell>
          </TableRow>
          </>
          }
        <TableRow>
            <TableCell component="th" >{t('Code')}</TableCell>
            <TableCell component="th"  align="left">{t('Product')}</TableCell>
            { !liteOn &&
            <>
            { showDealerPrices &&
            <TableCell component="th"  align="right">{t('List Price')}</TableCell>
            }
            <TableCell component="th"  align="right">{t('Price')}</TableCell>
            </>
            }
          </TableRow>
          </TableHead>
        <TableBody>
        <TableRow>
              <TableCell component="th" scope="row"  style={{
                      whiteSpace: "normal",
                      wordBreak: "break-word"
                    }} >
                { tenderingProduct.code }
              </TableCell>
              <TableCell align="left">{getLocalizedCatTextIfExists(tenderingProduct.name,t,catalogName,tenderingProduct,'PROD-TITLE')}</TableCell>
              { !liteOn &&
              <>
              { showDealerPrices &&
              <TableCell align="right">{calculatedPriceTables.productPrice && formatInEuros(calculatedPriceTables.productPrice[0])}</TableCell>
              }
              <TableCell align="right">{calculatedPriceTables.productPrice && formatInEuros(calculatedPriceTables.productPrice[2])}</TableCell>
              </>
              }
            </TableRow>

          {tenderingProduct.standardParts.map((part,index) => (
            <TableRow
              key={index}
            >
              <TableCell component="th" scope="row">
                
              </TableCell>
              <TableCell align="left">{getLocalizedCatTextIfExists(part.name,t,catalogName,{product:tenderingProduct,part:part},'PART-TITLE')+': '+getLocalizedCatTextIfExists(part.value, t, catalogName,{product:tenderingProduct,part:part},'PART-VALUE')}</TableCell>
              { !liteOn &&
            <>
            { showDealerPrices &&
              <TableCell align="right">{t('included')}</TableCell>
              }
              <TableCell align="right">{t('included')}</TableCell>
            </>
              }
            </TableRow>
          ))}
          </TableBody>
            <TableHead>
            <TableRow>
                <TableCell component="th">{t('Code')}</TableCell>
                <TableCell component="th">{t('Option')}</TableCell>
                { !liteOn &&
            <>
               { showDealerPrices &&
                <TableCell component="th" align="right">{t('List Price')}</TableCell>
                }
                <TableCell component="th" align="right">{t('Price')}</TableCell>
              </>
            }
              </TableRow>
            </TableHead>
            <TableBody>
                      {selectedOptions.map((option,index) => (
            <TableRow
              key={index}
            >
              <TableCell component="th" scope="row">
                { option.code }
              </TableCell>
              <TableCell align="left">{getLocalizedCatTextIfExists(option.name,t,catalogName,{code:option.code},'OPT-TITLE')}</TableCell>
              { !liteOn &&
            <>
            { showDealerPrices &&
              <TableCell align="right">{formatInEuros(calculatedPriceTables.selectedOptionPrices[index][0])}</TableCell>
              }
              <TableCell align="right">{formatInEuros(calculatedPriceTables.selectedOptionPrices[index][2])}</TableCell>
          </>
            }
            </TableRow>
          ))}

            </TableBody>
            { !liteOn &&
            <>
            <TableHead>
              {showDealerPrices &&
            <TableRow>
                <TableCell component="th" colSpan={showDealerPrices?4:3}>{t('Dealer price details')}</TableCell>
            </TableRow>
              }
            </TableHead>
            <TableBody>
              { showDealerPrices &&
              <React.Fragment>
            <TableRow>
                <TableCell component="th" align="left" colSpan={2}>{t('List price in factory')}</TableCell>
                <TableCell component="td" align="right">{formatInEuros(calculatedPriceTables.exwFactorySum[0])}</TableCell>
                <TableCell component="td">&nbsp;</TableCell>
            </TableRow>
            <TableRow>
                <TableCell component="th" align="left" colSpan={2}>{t('Discount %')}</TableCell>
                <TableCell component="td" align="right">{(dealerDiscountPercentage*1.0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+' %'}</TableCell>
                <TableCell component="td">&nbsp;</TableCell>
            </TableRow>
            </React.Fragment>
          }
          
            <TableRow  sx={{ border: 2 }}>
                <TableCell component="th" align="left" colSpan={showDealerPrices?2:2}>{t('Exw price total in factory')}</TableCell>
                { showDealerPrices &&
                <TableCell component="td" align="right">{formatInEuros(calculatedPriceTables.exwFactorySum[1])}</TableCell>
                }
                <TableCell component="td" align="right">{formatInEuros(calculatedPriceTables.exwFactorySum[2])}</TableCell>
            </TableRow>

            </TableBody>
            <TableHead>
            <TableRow>
                <TableCell component="th" colSpan={showDealerPrices?4:3}>{t('Others')}</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            <TableRow>
                <TableCell component="th"></TableCell>
                <TableCell component="td">{t('Freight')}</TableCell>
                { showDealerPrices &&
                <TableCell component="td" align="right">
                { readOnly?
                    formatInEuros(calculatedPriceTables.freightPrice[1])
                    :
                    <TextField id="freight-price" label={t('Freight cost')} variant="standard" defaultValue={freightPrice} onChange={(e) => { console.log("Freight price: "+e.target.value); dispatch({ type: 'setFreightPrice', payload: { freightPrice: (1.0*e.target.value) }})}} />
                    }
                </TableCell>
                }
                <TableCell component="td" align="right">{formatInEuros(calculatedPriceTables.freightPrice[2])}</TableCell>
            </TableRow>
            { additionalItems.map((additionalItem,index) => (
            <TableRow key={index}>
                <TableCell component="th">
                  { readOnly===false && index===additionalItems.length-1 &&
                    <IconButton color="inherit" onClick={ () => {
                      console.log("Adding additional item row.");
                      dispatch({ type: 'addAdditionalItem', payload: { additionalItemIndex: index+1 }});
                    }} ><PlusIcon/></IconButton>
                  }               
                </TableCell>
                <TableCell component="td">
                    { readOnly?
                    additionalItem.text
                    :
                    <TextField id={"additional-info-"+index} label={t('Additional information')} variant="standard"
                    multiline minRows={2} maxRows={Infinity}
                    fullWidth
                    defaultValue={additionalItem.text}
                    onChange={(e) => { console.log("Additional text: "+e.target.value+" at index "+index); 
                    dispatch({ type: 'setAdditionalText', payload: { additionalText: e.target.value, additionalItemIndex:index }})
                  }} />
                    }
                </TableCell>

                { showDealerPrices &&

                <TableCell component="td" align="right">
                { readOnly?
                    additionalItem.text?formatInEuros(additionalItem.price):null
                    :
                    <TextField id="additional-price" label={t('Additional cost')} variant="standard"  
                    defaultValue={additionalItem.text?additionalItem.price:null}
                    onChange={(e) => { console.log("Additional price: "+e.target.value); dispatch({ type: 'setAdditionalPrice', payload: { additionalPrice: (1.0*e.target.value), additionalItemIndex:index }})}} />
                }
                </TableCell>
                }
                <TableCell component="td" align="right">{additionalItem.text?formatInEuros(calculatedPriceTables.additionalItemPrices[index][2]):null}</TableCell>
            </TableRow>
            ))}

          { showDealerPrices &&
            <React.Fragment>
            <TableRow sx={{ border: 1 }}>
                <TableCell component="th" align="left" colSpan={2}>{t('Dealer Margin %')}</TableCell>
                <TableCell componen="td" align="right">
                { enteringDealerMarginInEuros || readOnly?
                    <>
                    {dealerMarginPercentage.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+' %'}
                    {readOnly===false &&
                    <IconButton sx={{ textAlign: 'right', justifyContent: 'right', marginLeft: 0 }}  color="inherit" 
                    onClick={() => {
                      setEnteringDealerMarginInEuros(false);
                      }}><EditIcon />
                    </IconButton>
                    }
                    </>
                :
                <TextField id="dealer-margin-percentage" label={t('Dealer Margin %')} variant="standard"  
                    defaultValue={dealerMarginPercentage?.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                    onChange={(e) => { dispatch({ type: 'setDealerMarginPercentage', payload: { value: (1.0*e.target.value)}})}} />
                }
                </TableCell>
                <TableCell componen="td">&nbsp;</TableCell>
            </TableRow>
            <TableRow sx={{ border: 1 }}>
                <TableCell component="th" align="left" colSpan={2}>{t('Dealer Margin €')}</TableCell>
                <TableCell componen="td" align="right">
                  { enteringDealerMarginInEuros && readOnly===false?
                  <>
                <TextField key={'force'+dealerMarginPercentage}id="dealer-margin-euros" label={t('Dealer Margin €')} variant="standard"  
                    defaultValue={formatInEuros(dealerMarginPercentage/100.0*(calculatedPriceTables.subTotal[1]))}
                    onChange={(e)=>setEnteredDME(e.target.value)}
                     />
                                        
                    <IconButton sx={{ textAlign: 'right', justifyContent: 'right', marginLeft: 0 }}  color="inherit" 
                    onClick={() => {
                      if(enteredDME)
                        dispatch({ type: 'setDealerMarginPercentage', payload: { value: ((calculatedPriceTables.subTotal[1]+enteredDME*1.0)/calculatedPriceTables.subTotal[1])*100.0-100.0}})
                      }}><CalcIcon />
                    </IconButton>

                    </>
                    :
                    <>
                    {formatInEuros(dealerMarginPercentage/100.0*calculatedPriceTables.subTotal[1])}
                    {readOnly===false &&
                    <IconButton sx={{ textAlign: 'right', justifyContent: 'right', marginLeft: 0 }}  color="inherit" 
                    onClick={() => {
                      setEnteringDealerMarginInEuros(true);
                      }}><EditIcon />
                    </IconButton>
                    }
                    </>

                  }
                </TableCell>
                <TableCell componen="td">&nbsp;</TableCell>
            </TableRow>
            </React.Fragment>
          }

            <TableRow>
                <TableCell component="th" colSpan={showDealerPrices?3:2}>{t('Exchange Machinery')}</TableCell>
                <TableCell component="td" align="right">
                { readOnly?
                    formatInEuros(exchangeMachineryPrice)
                    :
                    <TextField id="exchange-machinery-price" label={t('Exchange Machinery')} variant="standard" defaultValue={exchangeMachineryPrice} onChange={(e) => { console.log("Exchange machinery price: "+e.target.value); dispatch({ type: 'setExchangeMachineryPrice', payload: { exchangeMachineryPrice: (1.0*e.target.value) }})}} />
                    }
                </TableCell>
                
            </TableRow>



            <TableRow sx={{ border: 5 }}>
                <TableCell component="th" align="left" colSpan={showDealerPrices?3:2}>{t('Total price')}</TableCell>
                <TableCell component="td" align="right">{formatInEuros(calculatedPriceTables.total[2])}</TableCell>
            </TableRow>

        </TableBody>

        </>
  }

      </Table>
    </TableContainer>    
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TenderDetailsPanel);