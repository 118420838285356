import { Box, Typography } from "@mui/material";
import React from "react";

import { useTranslation } from "react-i18next";

export default function CatalogCard({ catalogInfo, onClick }) {
  const { t } = useTranslation(["appcommon"]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column-reverse", 
      backgroundColor:"white",
      border:1, margin:2, boxShadow:"2px 2px",
      //maxHeight: "60px",
      height: "95px"

 }}
      onClick={onClick}
    >
      <Typography variant="caption">
        {t("pricesUpdatedText")} : {catalogInfo.pricesUpdatedAt}
      </Typography>
      <Typography variant="caption">
      {catalogInfo.name} v{catalogInfo.version + (catalogInfo.published?'':' '+t("draft"))}
      </Typography>
      {catalogInfo.logo !== undefined && (
        <Box
          component="img"
        sx={{ maxHeight:"54px", maxWidth:"96px", margin:'auto' }}
          src={
            (window.location.hostname === "localhost"
              ? "https://tenderer.cappgw.com"
              : "") +
            "/assets/catalogs/" +
            catalogInfo.name +
            "/assets/" +
            catalogInfo.logo
          }
          alt={catalogInfo.name}
        />
      )}
    </Box>
  );
}
