import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox } from "@mui/material";
import { FormGroup, FormControlLabel } from "@mui/material";
import { Box } from "@mui/material";
import { TextField } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { EntitySelectDropdown } from "./EntitySelectDropdown";
import { EntitySelectMultiChips } from "./EntitySelectMultiChips";

export function EntitySelectDialog(props) {
  const { t } = useTranslation();

  // props contains array of select dropdown parameters
  const { dialogPrompt, entityInputFields, selectionsChangedCallback } = props;
  const [open, setOpen] = useState(true);
  const [currentValuesTable, setCurrentValuesTable] = useState(initialFieldValues());

  function handleClose(event, reason) {
    if (reason !== 'backdropClick') {
      setOpen(false);
      if(currentValuesTable!==undefined)
        selectionsChangedCallback(reason==='Cancelled',currentValuesTable);
    }
  }

  function initialFieldValues() {
    const valarray=[];
    entityInputFields.forEach(eif => {
      if(eif.fieldType==='Select')
        valarray.push([].concat(eif.selectedOptions));
      else if(eif.fieldType==='TextInput')
        valarray.push([].concat([eif.defaultValue]));
      else if(eif.fieldType==='Checkbox')
        valarray.push([].concat([eif.checked]));
    });
    console.log("Initial field values for dialog:");
    console.log(valarray);
    return valarray;
  }


  function setTextInputValue(index, value) {
    const valsTable = currentValuesTable;
    valsTable[index] = [].concat([value]);
    setCurrentValuesTable(valsTable);
  }
  
  function setCheckboxChecked(index, value) {
    const valsTable = currentValuesTable;
    valsTable[index] = [].concat([value]);
    setCurrentValuesTable(valsTable);
  }

  function setSelectionsForSelect(index, selections) {
    const valsTable = currentValuesTable;
    valsTable[index] = [].concat(selections);
    setCurrentValuesTable(valsTable);
  }

  return (
    <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
      <DialogTitle>{dialogPrompt}</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {entityInputFields?.map((eif, i) =>
            {
              if(eif.fieldType==='TextInput')
                return (
              <TextField
              key={eif.entityPrompt}
              sx={{width:'100%', margin:1}}
              id={'dlg-'+eif.entityPrompt}
              label={eif.entityPrompt}
              disabled={eif.readOnly}                  
              multiline={eif.multilineRows!==undefined}
              rows={eif.multilineRows!==undefined?eif.multilineRows:1}
              defaultValue={eif.defaultValue}
              onChange={(e) => {
                setTextInputValue(i, e.target.value);
                }}
              />);
              else if(eif.fieldType==='Checkbox')
                return (
                  <FormGroup key={eif.entityPrompt} >
                    <FormControlLabel sx={{width:'100%', margin:1}}
                      control={
                              <Checkbox
                              key={eif.entityPrompt}
                              
                              id={'dlg-'+eif.entityPrompt}
                              disabled={eif.readOnly}                  
                              defaultChecked={eif.checked}
                              onChange={(e) => {
                                setCheckboxChecked(i, e.target.value);
                                }}
                              />
                      } label={eif.entityPrompt} />
                  </FormGroup>
                );
              else if(eif.fieldType==='Select')
                if(eif.multiselect)
                  return (
                    <EntitySelectMultiChips sx={{ width: '100%' }} 
                      entityPrompt={eif.entityPrompt}
                      key={eif.entityPrompt}
                      label={eif.entityPrompt}
                      availableOptions={eif.availableOptions}
                      selectedOptions={eif.selectedOptions}
                      multiselect={eif.multiselect}
                      selectionsChangedCallback={(p) => {
                        console.log("In EntitySelectDialog selections changed handler for " + eif.entityPrompt);
                        console.log(p);
                        setSelectionsForSelect(i, p);
                      }} />
                  );
                else
                  return (
                    <EntitySelectDropdown sx={{ width: '100%' }} 
                      entityPrompt={eif.entityPrompt}
                      key={eif.entityPrompt}
                      label={eif.entityPrompt}
                      availableOptions={eif.availableOptions}
                      selectedOptions={eif.selectedOptions}
                      multiselect={eif.multiselect}
                      selectionsChangedCallback={(p) => {
                        console.log("In EntitySelectDialog selections changed handler for " + eif.entityPrompt);
                        console.log(p);
                        setSelectionsForSelect(i, p);
                      }} />
      
                  );
              else return null;
            }
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e)=>handleClose(e,'Cancelled')}>{t('Cancel')}</Button>
        <Button onClick={handleClose}>{t('Ok')}</Button>
      </DialogActions>
    </Dialog>
  );
}
