import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TextField, Button, Typography, IconButton, Input } from "@mui/material";
import { EntitySelectDialog } from "./EntitySelectDialog";
import { StatusAlertService } from "react-status-alert";
import PlusIcon from "@mui/icons-material/AddCircleOutlined";
import MinusIcon from "@mui/icons-material/RemoveCircleOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUploadOutlined";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import UploadIcon from "@mui/icons-material/UploadOutlined";
import CancelIcon from '@mui/icons-material/CancelOutlined';
import OkIcon from '@mui/icons-material/CheckCircleOutlined';


import * as xlsx from "xlsx";
import { sanitizeString, formatISODateTime } from "./UtilFunctions";


import { uploadTranslationsToCloud, downloadTranslationsFromCloud, downloadCatalogFromCloud } from "./TendererDataService";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import Paper from "@mui/material/Paper";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useTranslation } from "react-i18next";

import { useUserContext } from "./UserContext";

/*
TranslatorScreen
- loads all translation data the user has authorization to

1. select section
- app common texts
- catalogs
    - catalog A
        - catalog info
        - products
            - product A1
                - product details (name, description etc)
                - product standard features
        - options
            - option O1
                - option details (name, description)
        - other
            - extra fields????


*/

const allAllowedLanguages = {
  en: { langLocalName: 'english', notloaded: true },
  de: { langLocalName: 'german', notloaded: true },
  es: { langLocalName: 'spanish', notloaded: true },
  fi: { langLocalName: 'finnish', notloaded: true },
  it: { langLocalName: 'italian', notloaded: true },
  sv: { langLocalName: 'swedish', notloaded: true },
  ru: { langLocalName: 'russian', notloaded: true },
  cimode: {langLocalName: 'String IDs', notloaded: false },
}



function TranslationsTable({ editingLanguages, setEditingLanguages, selectedNamespace }) {
  const [currentlyEditingCell, setCurrentlyEditingCell] = useState("");
  const {
    state: { token },
  } = useUserContext();
  const [textsChanged, setTextsChanged] = useState([]);
  //  const [forcedUpdate,setForcedUpdate] = useState(Date.now);
  const [translationDefaults, setTranslationDefaults] = useState([]); // will be overwritten
  const [translationTexts, setTranslationTexts] = useState(allAllowedLanguages); // will be overwritten
  const [showAddNewDialog, setShowAddNewDialog] = useState(false);

  const { t } = useTranslation();

  const [openingImportExcel, setOpeningImportExcel]=useState(false);

  const [diffReport,setDiffReport]=useState(undefined);
  const [diffLanguage, setDiffLanguage]=useState("ci-mode");
  const [reviewedTranslationsData,setReviewedTranslationsData]=useState(undefined);


  useEffect(() => {
    console.log("Effect: selectedNamespace changed.")

    if (selectedNamespace !== 'appcommon') {
      // must be catalog
      fetchCatalogData(selectedNamespace);
    } else if (editingLanguages.length === 0)
      editingLanguages.push('en');

    editingLanguages.forEach((el) =>
      fetchTranslationTexts(selectedNamespace, el)
    );
  }, [selectedNamespace]);


  async function fetchCatalogData(catalogName) {
    if (catalogName === undefined)
      return;
    downloadCatalogFromCloud(token, catalogName, undefined, (data) => {
      const catalogTranslationKeysData = {};
      const catalogData = (Array.isArray(data) ? data[0] : data);
      console.log("Returned catalogData from download:");
      console.log(catalogData);
      const optionsSet = {};
      catalogData.options.forEach((o) => {
        optionsSet[o.code]=o;
      });
      catalogData.products.forEach((p) => {
        catalogTranslationKeysData["PROD-" + p.code + "-TITLE"] = p.name;
        p.standardParts.forEach((s) => {
          catalogTranslationKeysData["PROD-" + p.code + "-PART-" + s.name + "-TITLE"] = s.name;
          catalogTranslationKeysData["PROD-" + p.code + "-PART-" + s.name + "-VALUE"] = s.value;
        });
        p.options.forEach((o) => optionsSet[o.code] = { code: o.code, name: o.name, price: o.price });
      });
      Object.keys(optionsSet).forEach((ok) => {
        catalogTranslationKeysData["OPT-" + optionsSet[ok].code + "-TITLE"] = optionsSet[ok].name;
      });
      console.log("Catalog translation keys fetched");
      //setCatalogTranslationKeys({ default: catalogTranslationKeysData });
      setTranslationDefaults(Object.entries(catalogTranslationKeysData));
      return;
    });
  }


  function fetchTranslationTexts(namespace, lang) {
    console.log("Fetching translations for " + namespace + " " + lang);
    downloadTranslationsFromCloud(token, namespace, lang, (translationsData) => {
      // translationsData is an object
      const newtranslationTexts = translationTexts;
      const langLocalName = translationTexts[lang].langLocalName;
      if (translationsData !== undefined)
        newtranslationTexts[lang] = translationsData;
      else
        newtranslationTexts[lang] = {};

      newtranslationTexts[lang]._changes = false;
      newtranslationTexts[lang].langLocalName = langLocalName;


      console.log(newtranslationTexts);
      setTranslationTexts(newtranslationTexts);
      if (namespace === 'appcommon' && lang === 'en')
        setTranslationDefaults(Object.entries(newtranslationTexts.en));
      setCurrentlyEditingCell("");
    });
  }


  function importTranslations(namespace, lang, data) {
    console.log("Importing translations from Excel");
    const workbook = xlsx.read(data, { type: "array" });

    // catalog
    const sheetName = workbook.SheetNames[0];
    let worksheet = workbook.Sheets[sheetName];
    const transTexts = xlsx.utils.sheet_to_json(worksheet, {
      range: 1,
      header: 1,
    });
    const newTexts={};
    transTexts.forEach((r) => {
      const stringId=r.shift();
      r.shift(); // default-EN
      const stringValue=sanitizeString(r.shift()); // SANITIZE!!!!
      if(stringValue!==undefined)
        newTexts[stringId]=stringValue;
    });
    diffTranslations(namespace,lang,newTexts);
  }

  function exportTranslations(namespace, lang) {
    console.log("Trying to export translations as excel");

    var workbook = xlsx.utils.book_new();
    //  metadata
    var data = [];
    data.push(["STRING ID","default-EN",lang]);
    // translation texts
    translationDefaults.forEach((strIdDefaultStr) => {
      data.push([strIdDefaultStr[0], strIdDefaultStr[1],translationTexts[lang][strIdDefaultStr[0]]]);
    });
    let worksheet = xlsx.utils.aoa_to_sheet(data);
    worksheet["!cols"] = [ { wch: 50 },{ wch: 50 },{ wch: 50 } ];
    xlsx.utils.book_append_sheet(workbook, worksheet, namespace + '_' + lang);


    xlsx.writeFileXLSX(
      workbook,
      `${namespace}_${lang}_export_${formatISODateTime("now")}.xlsx`
    );

    console.log("Succeeded.");
    StatusAlertService.showSuccess("Tranlations exported successfully.");
  }

  //
  // diffTranslations
  //
  // constructs diff report

  function diffTranslations(namespace, lang, newTranslationsData) {
    //const translationsData=translationTexts[lang];
    const diffReportRows=[];
    // diff every string
    Object.keys(translationTexts[lang]).forEach((str) =>  {
      let nstr=newTranslationsData[str];
      if(nstr===undefined)
      {
        // if not found then str is deleted from new
        diffReportRows.push({stringID: str, change: "-DELETED-", oldValue: translationTexts[lang][str], newValue: ""});
        nstr=str;
      }
    }
    );
    // every in new to find new ones not found in translationsData
    Object.keys(newTranslationsData).forEach((nstr) =>  {
      let str=translationTexts[lang][nstr];
      if(nstr && newTranslationsData[nstr] && str===undefined)
      {
        // if not found then str is deleted from new
        diffReportRows.push({stringID: nstr, change: "-ADDED-", oldValue: "", newValue: newTranslationsData[nstr]});
        nstr=str;
      }
    }
    );
    // every changed
    Object.keys(newTranslationsData).forEach((nstr) =>  {
      if(nstr) {
        let str=newTranslationsData[nstr];
        if(str!==undefined && translationTexts[lang][nstr] && translationTexts[lang][nstr]!==newTranslationsData[nstr])
        {
          // changed
          diffReportRows.push({stringID: nstr, change: "-CHANGED-", oldValue: translationTexts[lang][str], newValue: newTranslationsData[nstr]});
          nstr=str;
        }
      }
    }
    );


    // set diff report
    console.log(diffReportRows);
    setDiffReport(diffReportRows);
    setDiffLanguage(lang);
    setReviewedTranslationsData(newTranslationsData);
  }

  function applyChangesToTranslations(namespace, lang) {
    translationTexts[lang]=reviewedTranslationsData;
  }



  function storeTranslationsToCloud(namespace, lang, publish) {
    translationTexts[lang]['_changes'] = false;
    uploadTranslationsToCloud(
      token,
      translationTexts[lang],
      namespace,
      lang,
      publish,
      (success) => {
        if (success === false) {
          alert("ERROR: Failed to upload translations to cloud!");
          return;
        }
        console.log("Uploading translations to cloud succceeded.");
        setTranslationTexts(translationTexts);
        setTextsChanged(textsChanged.filter((l) => l !== lang));
      }
    );
  }

  function changeTranslatedTextChange(namespace, lang, id, value) {
    console.log(`Changing text at ${namespace} on ${id} (${lang}) to ${value}`);
    console.log("Changed texts before: " + textsChanged.toString());

    if (translationTexts[lang] === undefined) translationTexts[lang] = {};
    translationTexts[lang][id] = value;
    translationTexts[lang]['_changes'] = true;
    if (textsChanged.includes(lang) === false) {
      const newTextsChanged = textsChanged.concat(lang);
      console.log("Changed texts after: " + newTextsChanged.toString());
      setTextsChanged(newTextsChanged);
    }
    setTranslationTexts(translationTexts);
  }

  const entityInputFields = [
    {
      fieldType: "TextInput",
      entityPrompt: "String ID",
      defaultValue: "- enter ID -",
      readOnly: false
    },
    {
      fieldType: "TextInput",
      entityPrompt: "String EN",
      defaultValue: "- enter default value EN -",
      readOnly: false
    }];

    
  const menuButtonStyle={ width: '30%', textAlign: 'right', justifyContent: 'center', marginLeft: 0 };

  return (
    <>
    { diffReport &&
                            <div style={{ width: "100%", marginRight: 0, marginLeft: 0 }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
              
                            <div style={{ display: "flex", flexDirection: "column" }}>
                            <table>
                                    <thead><tr><th style={{width:"30%"}}>StringID</th><th style={{width:"10%"}}>Change</th><th style={{width:"30%"}}>Old value</th><th style={{width:"30%"}}>New value</th></tr></thead><tbody>
                                    { diffReport.map((r,i) => 
                                      <tr key={i}>
                                        <td style={{width:"30%"}}><Typography key={i} variant="caption">{ r.stringID }</Typography></td>
                                        <td style={{width:"10%"}}><Typography key={i} variant="caption">{ r.change  }</Typography></td>
                                        <td style={{width:"30%"}}><Typography key={i} variant="caption">{ r.oldValue  }</Typography></td>
                                        <td style={{width:"30%"}}><Typography key={i} variant="caption">{ r.newValue  }</Typography></td>
                                      </tr>
                                    )}
                                    </tbody>
                                  </table>
                                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                  <IconButton color="error" onClick={ () => { setDiffReport(undefined); setReviewedTranslationsData(undefined); }}><CancelIcon /> Cancel</IconButton>
                                  <IconButton color="success" onClick={ () => { applyChangesToTranslations(selectedNamespace,diffLanguage); setDiffReport(undefined); setReviewedTranslationsData(undefined);  }}><OkIcon /> Apply</IconButton>
                                  </div>
                                  </div>
                                  
              
                            </div>
                          </div>
      }
      {!diffReport && showAddNewDialog &&
        <EntitySelectDialog
          dialogPrompt="New localization string"
          entityInputFields={entityInputFields}
          selectionsChangedCallback={(cancelled, selectionValues) => {
            setShowAddNewDialog(false);
            console.log("Changes from dialog...");
            if (cancelled) {
              console.log('Cancelled');
            } else {
              const newStringID = selectionValues[0][0];
              // check if not unique
              if (translationDefaults.find((d) => (d[0] === newStringID)) !== undefined) {
                // exists
                console.log('Not unique ID');
                StatusAlertService.showError('Not unique String ID!');
                return;
              }
              // ID is in index 0                      
              setTranslationDefaults(translationDefaults.concat([[selectionValues[0][0], selectionValues[1][0]]]));

            }
          }
          }
        />
      }
      {!diffReport &&
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 400, maxWidth: "90%" }}
          aria-label="translations table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell component="th" sx={{ maxWidth: 200 }}>
                {t('String ID')}</TableCell>
              {editingLanguages.map((lang, index) => (
                <TableCell key={index} component="th" align="left">
                  { openingImportExcel &&
                                                    <>
                                                    <h1>Select catalog excel file</h1>
                                <FormControl>
                                    Update current catalog from Excel
                                    <Input
                                      type="file"
                                      name="excelImport"
                                      id="excelImport"
                                      onChange={(e) => {
                                        e.preventDefault();
                                        if (e.target.files) {
                                          const reader = new FileReader();
                                          reader.onload = (e) => {
                                            const data = e.target.result;
                                            importTranslations(selectedNamespace,lang,data);
                                          };
                                          reader.readAsArrayBuffer(e.target.files[0]);
                                        }
                                        setOpeningImportExcel(false);
                                      }}
                                    />
                                  </FormControl>
                                  </>
                          }
                  <Button
                    variant="text"
                    onClick={() => {
                      console.log("hide column");
                      setEditingLanguages(editingLanguages.filter((_, i2) => index !== i2));
                    }}
                    startIcon={<MinusIcon />}
                  >
                    {t("Hide")}
                  </Button>
                  <Button
                    variant="text"
                    disabled={textsChanged.includes(lang) === false}
                    onClick={() => {
                      storeTranslationsToCloud(
                        selectedNamespace,
                        lang,
                        true
                      );
                    }}
                    startIcon={<CloudUploadIcon />}
                  >
                    {t("Publish")}
                  </Button>
                  <IconButton sx={menuButtonStyle} color="inherit" onClick={() => {
                      console.log("Exporting translations to excel.");
                      exportTranslations(selectedNamespace,editingLanguages[index]);
                    }}><DownloadIcon /><Typography variant="body2">Export to Excel</Typography></IconButton>

                    <IconButton sx={menuButtonStyle} color="inherit" onClick={() => {
                      console.log("importing translations from excel.");
                      setOpeningImportExcel(true);
                      
                    }}><UploadIcon /><Typography variant="body2">Update from Excel</Typography></IconButton>

                  <FormControl fullWidth>
                    <InputLabel id="editing-lang-label">
                      {t('Language')}
                    </InputLabel>
                    <Select
                      labelId="editing-lang-label"
                      id="editing-lang-select"
                      value={editingLanguages[index]}
                      label={t('Language')}
                      onChange={async function (e) {
                        console.log(
                          `Selected language to inx ${index} ${e.target.value}`
                        );
                        // load translations for the language selected... will fail if not existing and at that time will initialize

                        //if(translationTexts[e.target.value].notloaded)
                        //{
                        console.log('Loading translations file.');
                        fetchTranslationTexts(
                          selectedNamespace,
                          e.target.value
                        );
                        //}
                        editingLanguages[index] = e.target.value;
                        setEditingLanguages([...editingLanguages]);
                        setCurrentlyEditingCell("_changes");
                      }}
                    >
                      {Object.keys(allAllowedLanguages).map((langCode, i) =>
                        <MenuItem key={i} value={langCode}>{langCode + ' - ' + allAllowedLanguages[langCode].langLocalName}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </TableCell>
              ))}
              <TableCell
                component="td"
                style={{ border: 0, width: 50 }}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    console.log("add column");
                    setEditingLanguages([
                      ...editingLanguages.concat([""]),
                    ]);
                  }}
                  startIcon={<PlusIcon />}
                >
                  {t("Add")}
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {translationDefaults &&
              translationDefaults.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Typography variant="caption">
                        {item[0]}<br />
                      </Typography>
                      <Button
                        variant="text"
                        onClick={() => {
                          console.log("delete translatable text item");
                          setTranslationDefaults(translationDefaults.filter((d) => d !== item));
                        }}
                        startIcon={<MinusIcon />}
                      >
                        {t("Delete")}
                      </Button>
                    </TableCell>
                    {editingLanguages.map((lang, index) => (
                      <TableCell
                        key={index}
                        align="left"
                        onClick={(e) => {
                          setCurrentlyEditingCell(
                            index + ":" + item[0]
                          );
                          console.log(
                            "Currently editing " + index + ":" + item[0]
                          );
                        }}
                      >
                        {lang !== "" &&
                          currentlyEditingCell ===
                          index + ":" + item[0] ? (
                          <TextField
                            id={index + ":" + item[0]}
                            label={item[1]}
                            fullWidth
                            multiline={true}
                            variant="standard"
                            defaultValue={
                              translationTexts[lang] !== undefined
                                ? translationTexts[lang][item[0]]
                                : ""
                            }
                            onChange={(e) =>
                              changeTranslatedTextChange(
                                selectedNamespace,
                                editingLanguages[
                                parseInt(e.target.id.substr(0, 1))
                                ],
                                e.target.id.substr(2),
                                e.target.value
                              )
                            }
                          />
                        ) : lang !== "" &&
                          translationTexts[lang] &&
                          translationTexts[lang][item[0]] ? (
                          <Typography variant="body2">
                            {translationTexts[lang][item[0]]}
                          </Typography>
                        ) : (
                          <Typography
                            variant="caption"
                            sx={{
                              fontStyle: "italic",
                              color: "lightblue",
                            }}
                          >
                            {translationTexts["en"][item[0]] ? translationTexts["en"][item[0]] : item[1]}
                          </Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            <TableRow>
              <TableCell colSpan={editingLanguages.length + 1}>
                <Button
                  variant="text"
                  onClick={() => {
                    console.log("adding translatable text");
                    setShowAddNewDialog(true);
                    //setTranslationDefaults(translationDefaults.concat([["-text id-","-text value-"]]));
                  }}
                  startIcon={<PlusIcon />}
                >
                  {t("Add new text item")}
                </Button>

              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      }
    </>
  );
}



export default function TranslatorScreen() {
  const {
    state: { catalogs },
  } = useUserContext();


  const [editingLanguages, setEditingLanguages] = useState(["en"]); // default show keys only
  const [selectedNamespace, setSelectedNamespace] = useState('appcommon');



  const [phase, setPhase] = useState("TRANSLATIONS_INDEX");


  const widthSmallerThan767 = useMediaQuery("(max-width: 767px)");
  const widthSmallerThan413 = useMediaQuery("(max-width: 413px)");
  const workWidth = widthSmallerThan413 ? 300 : widthSmallerThan767 ? 400 : 600;


  return (
    <div align="center">
      {
        {
          TRANSLATIONS_INDEX:
            <div style={{ boxShadow: 0, width: workWidth }}>
              <Button
                sx={{ flex: 1, width: '100%' }}
                border={1}
                color="inherit"
                onClick={() => {
                  //                  fetchTranslationTexts("appcommon", "en");
                  setSelectedNamespace('appcommon');
                  setPhase('EDIT_TRANSLATIONS');
                }} >TRANSLATE APP COMMON TEXTS</Button>
              {catalogs.map((c, index) => (
                <div key={index}>
                  <Button sx={{ flex: 1, width: '100%' }}
                    border={1}
                    key={index}
                    color="inherit"
                    onClick={() => {
                      //fetchCatalogData(c);
                      setSelectedNamespace(c.name);
                      setPhase('EDIT_TRANSLATIONS');
                    }}
                  >
                    Translate catalog: {c.name} v{c.version}
                  </Button>
                </div>
              ))}
            </div>,
          EDIT_TRANSLATIONS:
            <>
              <TranslationsTable
                editingLanguages={editingLanguages}
                setEditingLanguages={setEditingLanguages}
                selectedNamespace={selectedNamespace}
              />
            </>,
            
        }[phase]
      }
    </div>
  );
}
