
import React from "react";


export default function StatusMessagePopup({message}) {
    return (
        <>
        { message!=="" &&
        <div sx={{position:"absolute"}} onClick={() => { message="";}} >
            <h1>{message}</h1>
            Click to close
        </div>
        }
        </>
    );
}