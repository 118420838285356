import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n.use(HttpApi).use(initReactI18next).init({
  ns: ['appcommon'],
  backend: {

    loadPath: 'https://tenderer-api.cappgw.com/translations/{{lng}}/{{ns}}',
    allowMultiLoading: false,
    crossDomain: true
  },
  defaultNS: "appcommon",
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  },
  debug: false,
  react: {
    useSuspense: false,
  }
});



export default i18n;