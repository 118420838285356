import React from "react";
import {FormGroup, FormControlLabel, Checkbox} from '@mui/material';
import CatManOptionsPanel from "./CatManOptionsPanel";
import CatManAttachmentsPanel from "./CatManAttachmentsPanel";
import CatManEntityEditorPanel from "./CatManEntityEditorPanel";
import CatManProductStdFeaturesPanel from "./CatManProductStdFeaturesPanel";
import CatManRulesPanel from "./CatManRulesPanel";
import CatManUsersPanel from "./CatManUsersPanel";
import { connect } from 'react-redux';

import { mapStateToProps, mapDispatchToProps } from "./TenderStore";

const initialCatalog={
  catalogData:undefined, 
  editingCatalog:undefined, editingProduct:undefined, 
  editingOption:undefined, editingAttachment: undefined,
  productOptions:undefined, productAttachments:undefined,
  managingAccounts:undefined
};

function CatManPanel({tender,dispatch}) {
  const {  catalogData, selectedEntity, editingCatalog, editingProduct, editingOption, editingAttachment, productOptions, productAttachments, managingAccounts } = tender;

  return (
    <div style={{ flex: 3, marginLeft: 15, marginRight: 15 }}>
      {editingCatalog && catalogData && managingAccounts===false &&
        <CatManEntityEditorPanel
          entity={catalogData}
          entityType='catalog'
          entityTypeDisplayName='Catalog'
          attributes={[
            [ 'code', 'Code', 'HIDDEN' ],
            ['name','Catalog Name','STRINGID' ],
            ['manufacturer','Manufacturer','STRING'],
            [ 'description','Description','STRINGMULTILINE'],
            ['logo','Logo','READONLY'],
            ['version','Catalog Version', 'INT'],
            ['pricesUpdatedAt','Prices updated','ISODATESTRING'],
            ['modifiedBy','Modified by','READONLY'],
            ['modifiedAt','Modified at','READONLY']
            
          ]}
          subDetailPanels={[
            {
              name: 'Settings',
              content: <React.Fragment>
                <FormGroup>
                  { catalogData.published?
                    <FormControlLabel control={<Checkbox  checked={true}
                      onChange={() => dispatch({type:'saveEntity',payload: { entityType:'catalog', entity: {...catalogData, published: false}}})}                   
                      />} label="Published" />
                  :
                    <FormControlLabel control={<Checkbox  checked={false}
                      onChange={() => dispatch({type:'saveEntity',payload: { entityType:'catalog', entity: {...catalogData, published: true}}})}                   
                    />} label="Published" />
                  }
                  { catalogData.markedForDeletion?
                    <FormControlLabel control={<Checkbox  checked={true}
                      onChange={() => dispatch({type:'saveEntity',payload: { entityType:'catalog', entity: {...catalogData, markedForDeletion: false}}})}                   
                      />} label="Marked for Deletion" />
                  :
                    <FormControlLabel control={<Checkbox  checked={false}
                      onChange={() => dispatch({type:'saveEntity',payload: { entityType:'catalog', entity: {...catalogData, markedForDeletion: true}}})}                   
                    />} label="Marked for Deletion" />
                  }
                  { catalogData.includeProductOptions?
                    <FormControlLabel control={<Checkbox  checked={true}
                      onChange={() => dispatch({type:'saveEntity',payload: { entityType:'catalog', entity: {...catalogData,includeProductOptions: false}}})}                   
                      />} label="Include product options in tender" />
                  :
                    <FormControlLabel control={<Checkbox  checked={false}
                      onChange={() => dispatch({type:'saveEntity',payload: { entityType:'catalog', entity: {...catalogData,includeProductOptions: true}}})}                   
                    />} label="Include product options in tender" />
                  }
                  { catalogData.includeProductAttachments?
                    <FormControlLabel control={<Checkbox checked={true}
                      onChange={() => dispatch({type:'saveEntity',payload: { entityType:'catalog', entity: {...catalogData, includeProductAttachments: false}}})}                   
                      />} label="Include product attachments in tender" />
                  :
                    <FormControlLabel control={<Checkbox  checked={false}
                      onChange={() => dispatch({type:'saveEntity',payload: { entityType:'catalog', entity: {...catalogData, includeProductAttachments: true}}})}                   
                    />} label="Include product attachments in tender" />
                  }
                  
                </FormGroup>
              </React.Fragment>
            },
            /*
            {
              name: 'Access Rights',
              content: <CatManUsersPanel />
            },
            */
            {
              name: 'Rules',
              content: <CatManRulesPanel />
            }
          ]} />}
      {editingCatalog && catalogData && managingAccounts &&
        <CatManUsersPanel />
      }


      {editingProduct && catalogData?.products.map((product, i) => {
        if (editingProduct === product.code && selectedEntity ) {
          return (
            <CatManEntityEditorPanel key={product.code}
            entity={selectedEntity}
            entityType='product'
            entityTypeDisplayName='Product'
            attributes={[
              [ 'code', 'Code', 'STRINGID' ],
              ['name','Product Title','STRING' ],
              [ 'description','Description','STRINGMULTILINE'],
              ['category','Category','READONLY'],
              ['image','Image','READONLY'],
              ['price','Price','MONEY']
            ]}
              subDetailPanels={[
                {
                  name: 'Standard Features',
                  content: <CatManProductStdFeaturesPanel
                    product={product}
                    standardParts={product.standardParts} />
                },
                {
                  name: 'Allowed Options',
                  content: <CatManOptionsPanel product={product} productOptions={productOptions} />
                },
                {
                  name: 'Allowed Attachments',
                  content: <CatManAttachmentsPanel product={product} productAttachments={productAttachments} />
                },
                /*
                {
                  name: 'Validation Rules',
                  content: <h3>TODO: Rule mangement at product scope</h3>
                }
                */
              ]} />
          );
        }
        return (<React.Fragment key={i}></React.Fragment>);
      })}

      {editingOption && catalogData?.options.map((option, i) => {
        if (editingOption === option.code && selectedEntity) {
          return (
            <CatManEntityEditorPanel key={option.code}
              entity={selectedEntity}
              entityType='option'
              entityTypeDisplayName='Option'
              attributes={[
                [ 'code', 'Code', 'STRINGID' ],
                ['name','Option Name','STRING' ],
                [ 'description','Description','STRINGMULTILINE'],
                ['category','Category','READONLY'],
                ['image','Image','READONLY'],
                ['price','Price','MONEY']
              ]} />
          );
        }     
        return (<React.Fragment key={i}></React.Fragment>);
      })}


        {editingAttachment && catalogData?.attachments.map((attachment, i) => {
          if (editingAttachment === attachment.code) {
            return (
              <CatManEntityEditorPanel key={attachment.code}
                entity={selectedEntity}
                entityType='attachment'
                entityTypeDisplayName='Attachment'
                attributes={[
                  [ 'code', 'Code', 'STRINGID' ],
                  ['name','Attachment Name','STRING' ],
                  [ 'description','Description','STRINGMULTILINE'],
                  ['category','Category','READONLY'],
                  ['image','Image','READONLY'],
                  ['price','Price','MONEY']
                ]} />
            );
          }
          return (<React.Fragment key={i}></React.Fragment>);
          })}

    </div>

  );
}
/*
const mapStateToProps = state => ({
  catalog: state.catalog,
});

const mapDispatchToProps = dispatch => ({
  dispatch: (action) => dispatch({type:action.type, payload:action.payload})});
*/
export default connect(mapStateToProps, mapDispatchToProps)(CatManPanel);