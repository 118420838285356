import React, { useState } from "react";
import { Typography, IconButton } from "@mui/material";
import PlusIcon from "@mui/icons-material/AddCircleOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUploadOutlined";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import UploadIcon from "@mui/icons-material/UploadOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import DownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import UpIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ChangeIcon from '@mui/icons-material/ChangeCircleOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccountsOutlined';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import uuid from "react-uuid";
import { EntitySelectDialog } from './EntitySelectDialog';


function CatManMenu({ tender, storeCatalogCallback, exportCatalogCallback, importCatalogCallback, dispatch }) {
  const {t} = useTranslation();
  const { catalogData } = tender;
  const [openedProductList, setOpenedProductList] = useState(false);
  const [openedOptionList, setOpenedOptionList] = useState(false);
  const [openedAttachmentList, setOpenedAttachmentList] = useState(false);
  const [addingEntity, setAddingEntity] = useState(undefined);

  const menuButtonStyle={ width: '100%', textAlign: 'left', justifyContent: 'left', marginLeft: 0 };

  const entityInputFields=[
    {
      fieldType: "TextInput",
      entityPrompt: "ID",
      defaultValue: uuid(),
      readOnly: true
    },
    {
      fieldType: "TextInput",
      entityPrompt: "Code",
      defaultValue: "",
      readOnly: false
    }
  ];


  console.log("CatalogData");
  console.log(catalogData);
  catalogData.options?.sort((a,b) => { 
    // normalize codes by adding option code with trailing aaaa's
    return ((""+a.code+"                            ").substring(0,32) < (""+b.code+"                            ").substring(0,32)?-1:1);
  });


  function addEntity(entityType, entityCode) {
    console.log("Adding additional entity of type "+entityType);
    dispatch({ type: 'addEntity', payload: { entityType:entityType, setSelected: true, entityCode: entityCode } });
  }

  return (
    <div style={{ textAlign: 'left', flex: 1, overflowY: 'auto', overflowX: 'no', height: 800, paddingLeft: 0, backgroundColor: '#d8d8d8', border: '1px solid', borderColor: '#c8c8c8' }}>
      <IconButton color="inherit" onClick={() => {
        dispatch({type:'cancelCatalogEditing'});
      }}><ChangeIcon /><Typography variant="body2">Change Catalog</Typography></IconButton>


      <Typography variant='h6'>{t('Catalog')}</Typography>

      <IconButton  sx={menuButtonStyle} color="inherit" onClick={() => {
        console.log("Edit catalog...");
        dispatch({ type: 'selectEntity', payload: { entityType: 'catalog', subEntity: undefined } });
      }}><EditIcon />
        <Typography variant='body2'>{catalogData?.name}</Typography>
      </IconButton>

      <IconButton sx={menuButtonStyle} color="inherit" onClick={() => {
        console.log("Saving catalog to cloud.");
        storeCatalogCallback();
      }}><CloudUploadIcon /><Typography variant="body2">Save to Cloud</Typography></IconButton>

      <IconButton sx={menuButtonStyle} color="inherit" onClick={() => {
        console.log("Exporting catalog to excel.");
        exportCatalogCallback();
      }}><DownloadIcon /><Typography variant="body2">Export to Excel</Typography></IconButton>

      <IconButton sx={menuButtonStyle} color="inherit" onClick={() => {
        console.log("importing catalog from excel.");
        importCatalogCallback();
      }}><UploadIcon /><Typography variant="body2">Update from Excel</Typography></IconButton>

      <IconButton  sx={menuButtonStyle} color="inherit" onClick={() => {
        console.log("Manage users...");
        dispatch({ type: 'selectEntity', payload: { entityType: 'catalog', subEntity: 'managingAccounts' } });
      }}><ManageAccountsIcon />
        <Typography variant='body2'>{t('Manage accounts')}</Typography>
      </IconButton>


      <div style={{ flexDirection: 'row' }}>
        <Typography variant='button'>Products</Typography>
        {openedProductList ?
          <IconButton color="inherit" onClick={() => { setOpenedProductList(false); setOpenedOptionList(false); }}><UpIcon /></IconButton>
          :
          <IconButton color="inherit" onClick={() => { setOpenedProductList(true); setOpenedOptionList(false); }}><DownIcon /></IconButton>}
      </div>
      {openedProductList &&
        <>
          {catalogData?.products?.map((product, index) => {
            //console.log(product);
          return (
            <IconButton key={product.code} sx={menuButtonStyle} color="inherit" 
            onClick={() => {
                console.log("Edit product..." + product.name);
                dispatch({ type: 'selectEntity', payload: { entityType: 'product', entityCode: product.code } });
              }}><EditIcon />
              <Typography variant='body2'>{product.code + ' ' + product.name}</Typography>
            </IconButton>
          )})}
          <IconButton sx={menuButtonStyle} color="inherit" onClick={() => {
            setAddingEntity("product");
          }}><PlusIcon /><Typography variant="body2">Add product</Typography></IconButton>
        </>
      }



      <div style={{ flexDirection: 'row' }}>
        <Typography variant='button'>Options</Typography>
        {openedOptionList ?
          <IconButton color="inherit" onClick={() => { setOpenedOptionList(false); setOpenedProductList(false); }}><UpIcon /></IconButton>
          :
          <IconButton color="inherit" onClick={() => { setOpenedOptionList(true); setOpenedProductList(false); }}><DownIcon /></IconButton>}
      </div>
      {openedOptionList &&
        <>
          {catalogData?.options?.map((option, index) => (
            <IconButton key={option.code} sx={menuButtonStyle} color="inherit" onClick={() => {
              console.log("Edit Option...");
              //dispatch({ type: 'setEditingOption', payload: { optionCode: option.code } });
              dispatch({ type: 'selectEntity', payload: { entityType: 'option', entityCode: option.code } });

            }}><EditIcon />
              <Typography variant='body2'>{option.code + ' - ' + option.name}</Typography>
            </IconButton>
          ))}
          <IconButton sx={menuButtonStyle} color="inherit" onClick={() => {
            console.log("Adding option.");
            setAddingEntity("option");

          }}><PlusIcon /><Typography variant="body2">Add option</Typography></IconButton>
        </>}



      <div style={{ flexDirection: 'row' }}>
        <Typography variant='button'>Attachments</Typography>
        {openedAttachmentList ?
          <IconButton color="inherit" onClick={() => { setOpenedAttachmentList(false); setOpenedOptionList(false); setOpenedProductList(false); }}><UpIcon /></IconButton>
          :
          <IconButton color="inherit" onClick={() => { setOpenedAttachmentList(true); setOpenedOptionList(false); setOpenedProductList(false); }}><DownIcon /></IconButton>}
      </div>
      {openedAttachmentList &&
        <>
          {catalogData?.attachments?.map((attachment, index) => (
            <IconButton key={attachment.code} sx={menuButtonStyle} color="inherit" onClick={() => {
              console.log("Edit attachment...");
              //dispatch({ type: 'setEditingOption', payload: { optionCode: option.code } });
              dispatch({ type: 'selectEntity', payload: { entityType: 'attachment', entityCode: attachment.code } });

            }}><EditIcon />
              <Typography variant='body2'>{attachment.code + ' - ' + attachment.name}</Typography>
            </IconButton>
          ))}
          <IconButton sx={menuButtonStyle} color="inherit" onClick={() => {
            console.log("Adding attachment.");
            setAddingEntity("attachment");
          }}><PlusIcon /><Typography variant="body2">Add attachment</Typography></IconButton>
        </>}

        {addingEntity &&
                <EntitySelectDialog
                  dialogPrompt={"Add "+addingEntity}
                  entityInputFields={entityInputFields}
                  selectionsChangedCallback={(cancelled,selectionValues) => {
                    console.log("Changes from dialog...");
                    if(cancelled) {
                      console.log('Cancelled');
                    } else {
                      console.log("Adding entity with values:");
                      console.log(selectionValues);
                      addEntity(addingEntity,selectionValues[1][0]);
                    }
                    setAddingEntity(undefined);  
                  }
                }
                />
        }
    </div>
  );
}
const mapStateAndOwnPropsToProps = (state, ownProps) => {
  const tender = state.tender;
  return {
    tender,
    storeCatalogCallback: ownProps.storeCatalogCallback,
    exportCatalogCallback: ownProps.exportCatalogCallback,
  }
};

/*const mapStateToProps = state => {
  const tender=state.tender;
  return { tender, }
};
*/

const mapDispatchToProps = dispatch => ({
  dispatch: (action) => dispatch({type:action.type, payload:action.payload})});

export default connect(mapStateAndOwnPropsToProps, mapDispatchToProps)(CatManMenu);