import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUploadOutlined";
import { useTranslation } from "react-i18next";
import { formatISODateTime, asciiOnlyString } from "./UtilFunctions";


export default function TenderSaveAsPanel({
  defaultName = `Tender ${formatISODateTime("now")}`,
  defaultStatus = "NEW",
  width = "80%",
  lite,
  saveTenderCallback,
}) {

  const { t } = useTranslation();

  const [tenderName, setTenderName] = useState(defaultName);
  const [tenderStatus, setTenderStatus] = useState(
    lite ? "INQUIRY" : defaultStatus
  );
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerMessage, setCustomerMessage] = useState("");
  const [customerCountry, setCustomerCountry] = useState(undefined);
  const [sendCopyByEmail, setSendCopyByEmail] = useState(false);


  const [saved, setSaved] = useState(false);

  const countryValues = [
    { code: undefined, name: t("Other") },
    { code: "dk", name: t("Denmark") },
    { code: "de", name: t("Germany") },
    { code: "fi", name: t("Finland") },
    { code: "fr", name: t("France") },
    { code: "it", name: t("Italy") },
    { code: "pl", name: t("Poland") },
    { code: "sv", name: t("Sweden") },
  ];
  
  const statusValues = [
    { code: "NEW", name: t("New") },
    { code: "TENDERED", name: t("Tendered") },
    { code: "WON", name: t("Won") },
    { code: "LOST", name: t("Lost") },
    { code: "CANCELLED", name: t("Cancelled") },
    { code: "ARCHIVED", name: t("Archived") },
    { code: "INQUIRY", name: t("Inquiry") },
  ];
  


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "auto",
        margin: "auto",
        
      }}
    >
          <FormGroup key={1} padding={3}>
      {lite && saved===false ? (
        <>
          <Typography variant="h5" color="primary" component="div">
            {t(
              "Enter your contact details and send your inquiry to closest dealer."
            )}
          </Typography>
          <Typography variant="caption" color="primary" component="div">
            {t(
              "By sending your details you accept our privacy policy. Read more about how we handle your data from "
            )}{" "}
            <a
              href="https://www.machinerydealerpro.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              {t("here")}
            </a>
          </Typography>
            <TextField
              id="tender-customer-name"
              label={t("Company name")}
              variant="standard"
              sx={{ flex: 1, width:"auto" }}
              defaultValue={""}
              onChange={(e) => {
                // sanitize name
                const customerSanitizedName = e.target.value
                  .replace("/", " ")
                  .replace("\n", " ");
                setCustomerName(customerSanitizedName);
              }}
            />
            <FormControl fullWidth>
              <InputLabel id="tender-customer-country-label">
                Country
              </InputLabel>
              <Select
                labelId="tender-customer-country-label"
                id="tender-customer-country"
                value={customerCountry}
                label={t("Country")}
                onChange={(e) => {
                  setCustomerCountry(e.target.value);
                }}
              >
                {countryValues.map((country, index) => (
                  <MenuItem value={country.code}>{t(country.name)}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="tender-customer-email"
              label={t("Email address")}
              variant="standard"
              sx={{ flex: 1 }}
              defaultValue={""}
              onChange={(e) => {
                // sanitize name
                const customerSanitizedEmail = e.target.value
                  .replace("/", " ")
                  .replace("\n", " ");
                setCustomerEmail(customerSanitizedEmail);
              }}
            />
            <FormControlLabel
              sx={{ width: "100%", margin: 1 }}
              control={
                <Checkbox
                  key={2}
                  id={"sendcopy"}
                  defaultChecked={sendCopyByEmail}
                  onChange={(e) => {
                    setSendCopyByEmail(e.target.value);
                  }}
                />
              }
              label={t("Send me a copy by email")}
            />
            <TextField
              id="tender-customer-phone"
              label={t("Phone number")}
              variant="standard"
              sx={{ flex: 1,width:"auto" }}
              defaultValue={""}
              onChange={(e) => {
                // sanitize name
                const customerSanitizedPhone = e.target.value
                  .replace("/", " ")
                  .replace("\n", " ");
                setCustomerPhone(customerSanitizedPhone);
              }}
            />
            <TextField
              id="tender-customer-message"
              label={t("Message (optional)")}
              variant="standard"
              multiline
              rows={5}
              sx={{ flex: 1, width:"auto" }}
              defaultValue={""}
              onChange={(e) => {
                // sanitize name
                const customerSanitizedMessage = asciiOnlyString(e.target.value)
                  .replace("/", " ")
                  .replace("\n", " ");
                setCustomerMessage(customerSanitizedMessage);
              }}
            />
        </>
      ) : (
        <>
          <TextField
            id="tender-name"
            label={t("Tender name")}
            variant="standard"
            sx={{ flex: 1, marginBottom: 3,width:"auto" }}
            defaultValue={tenderName}
            onChange={(e) => {
              // sanitize name
              const saveAsTenderName = e.target.value
                .replace("/", " ")
                .replace("\n", " ");
              setTenderName(saveAsTenderName);
            }}
          />
        </>
      )}

      {lite === false && (
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="tender-status-label">{t("Tender status")}</InputLabel>
          <Select
            labelId="tender-status-label"
            id="tender-status"
            value={tenderStatus}
            label={t("Tender status")}
            onChange={(e) => {
              setTenderStatus(e.target.value);
            }}
          >
            {statusValues.map((status, index) => (
              <MenuItem key={status.code} value={status.code}>{t(status.name)}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <IconButton
        sx={{ flex: 1, padding:3 }}
        color="inherit"
        disabled={saved}
        onClick={() => {
          const savingWithName =
            lite === false
              ? tenderName.replace("/", " ")
              : "inquiry_" +
                customerCountry +
                "_" +
                customerName +
                "_" +
                formatISODateTime("now");

          saveTenderCallback(
            savingWithName,
            tenderStatus,
            lite === false
              ? undefined
              : {
                  customerName: customerName,
                  customerEmail: customerEmail,
                  customerCountry: customerCountry,
                  customerPhone: customerPhone,
                  //customerAddress:customerAddress
                }
          );
          setSaved(true);
        }}
      >
        <CloudUploadIcon />
        {lite ? t("Send inquiry") : t("Store to Cloud")}
      </IconButton>
      </FormGroup>

    </Box>
  );
}
