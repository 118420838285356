import React from "react";
import TextField from  "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import PlusIcon from "@mui/icons-material/AddCircleOutlined";
import MinusIcon from "@mui/icons-material/RemoveCircleOutlined";

import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';

import { mapStateToProps, mapDispatchToProps } from "./TenderStore";




function CatManProductStdFeaturesPanel({tender, dispatch}) {
    const {t} = useTranslation();
    const { catalogData, productStandardParts, selectedEntity, selectedEntityModified } = tender;

    
    function flagModified() {
      if(selectedEntityModified===false)
        dispatch({type: 'flagEntityModified'});
    }

    return (
        <React.Fragment>
      { productStandardParts && productStandardParts.map((s,is) => (
      <div key={s.name!==undefined && s.name!==''?s.name:is} style={{display:'flex',flexDirection:'row', marginRight:5, marginLeft:5}}> 
      <TextField
      sx={{flex:1}}
      id={'product-'+selectedEntity.code+'-part-'+s.name+'-title'}
      label={t('Feature')}
      
      multiline={false}
      variant="standard"
      defaultValue={s.name}
      onChange={(e) => {
          s.name=e.target.value;
          flagModified();
        }}
      />
      <TextField
      sx={{flex:1}}
      id={'product-'+selectedEntity.code+'-part-'+s.value+'-value'}
      label={t('Value')}
      
      multiline={false}
      variant="standard"
      defaultValue={s.value}
      onChange={(e) => {
          s.value=e.target.value;
          flagModified();
        }}
      />
    <IconButton id={s.name} color="inherit" onClick={ () => {
      console.log("Deleting standard part row.");
      const newProd=JSON.parse(JSON.stringify(selectedEntity));
      console.log("Deleting std part "+s.name);
      console.log(newProd.standardParts);
      newProd.standardParts=newProd.standardParts.filter(rs=> rs.name!==s.name);
      //setStandardParts(newProd.standardParts);
      console.log("Deleted std part "+s.name);
      console.log(newProd);
      const catData=catalogData;
      const replaceProductAtIndex=catData.products.findIndex(p => p.code===newProd.code);
      if(replaceProductAtIndex>-1) {
        catData.products[replaceProductAtIndex]=newProd;
        dispatch({ type: 'stageProductStandardParts', payload: { productStandardParts: newProd.standardParts } });
      }

    }} ><MinusIcon/></IconButton>

    </div>
    ))
    }
            <IconButton color="inherit" onClick={() => {
                console.log("Adding additional standard part row.");
                const newProd = JSON.parse(JSON.stringify(selectedEntity)); // force deep copy
                console.log(newProd);
                newProd.standardParts = newProd.standardParts.concat([{ title: '', value: '' }]);
                //setStandardParts(newProd.standardParts);
                console.log(newProd);
                const catData = catalogData;
                const replaceProductAtIndex = catData.products.findIndex(p => p.code === newProd.code);
                if (replaceProductAtIndex > -1) {
                    catData.products[replaceProductAtIndex] = newProd;
                    dispatch({ type: 'stageProductStandardParts', payload: { productStandardParts: newProd.standardParts } });
                    flagModified();
                }
            }} ><PlusIcon /><Typography variant="body2">Add standard feature</Typography></IconButton>

    </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CatManProductStdFeaturesPanel);