// used for all backend data requests

const baseURL="https://tenderer-api.cappgw.com";

//
// USER PREFS
//

export function fetchUserPreferences(token, updateUserPreferencesCallback) {
    console.log("Fetching user preferences...");
    // calls server to validate token and fetch all user preferences
    const url=baseURL+"/userprefs";
    return fetch(url,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        method: "POST",
        body: JSON.stringify({token: token})
    })
    .then(function(res){ 
        console.log(res);
        if(res.status===401) {
            // login not successfull or expired
            return;
        }
        res.json().then(function(data) {
            console.log(data);
            console.log("Catalogs: ");
            if(data.catalogs!==undefined) {
                updateUserPreferencesCallback({type: 'setCatalogs', payload: { username: data.claims.username, token:token, email: data.claims.email, catalogs: data.catalogs }});
            } else {
                // login expired or some error
                updateUserPreferencesCallback({type: 'setCatalogs', payload: { username:'', token:'',email:'',catalogs:[]}});
            }
          });
     })
    .catch(function(res){ console.log(res) });    
}

//
// TENDERS
//

export function fetchUsersStoredTenders(token, updateUsersStoredTendersCallback) {
    console.log("Fetching user stored tenders...");
    // calls server to validate token and fetch all user preferences
    const url=baseURL+"/tenders";
    return fetch(url,{
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        method: "GET"
    })
    .then(function(res){ 
        console.log(res);
        res.json().then(function(data) {
            console.log(data);
            updateUsersStoredTendersCallback(data.tenders);
          });
     })
    .catch(function(res){ console.log(res) });    
}

export function uploadTenderToCloud(token, tender, tenderName, uploadedCallback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token },
        body: JSON.stringify( tender )
    };
    fetch(baseURL+(token===undefined||token===null?'/inquiries':'/tenders'), requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data); uploadedCallback(true); })
        .catch(error => { console.log(error); uploadedCallback(false); });   
}

export function deleteTenderFromCloud(token, tenderId, deletedCallback) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token }
    };
    fetch(baseURL+'/tenders/'+tenderId, requestOptions)
        .then(response => {
            if(response.status===200) 
                return response.json();
            else
                throw new Error(response.error);
        })
        .then(data => { console.log(data); deletedCallback(true); })
        .catch(error => { console.log(error); deletedCallback(false); });   
}

//
// CATALOG OPERATIONS
//

export function downloadCatalogFromCloud(token, catalogName, catalogVersion, downloadedCallback) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token, 'Cache-Control': 'no-cache' }
    };
    let url=`${baseURL}/catalogs/${encodeURIComponent(catalogName)}`;
    if(catalogVersion!==undefined)
        url=url+'/'+catalogVersion;
    fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => { console.log("Downloaded:"); console.log(data); downloadedCallback(data); })
        .catch(error => { console.log(error); downloadedCallback(undefined); });   
}

export function uploadCatalogToCloud(token, catalogData, uploadedCallback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token },
        body: JSON.stringify( catalogData )
    };
    fetch(baseURL+'/catalogs', requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data); uploadedCallback(true); })
        .catch(error => { console.log(error); uploadedCallback(false); });   
}

export function uploadImageToCloud(token,catalogName,entityType,filename,imageData, uploadedCallback) {
    console.log("Uploading image to cloud.");
    let requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': imageData.type, 'Authorization': 'Bearer '+token, 'Cache-Control': 'no-cache' }
        //,body: JSON.stringify({imgfilename: filename })
    };
    fetch(baseURL+'/imageUpload/'+catalogName+'/'+entityType+'/'+filename, requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data);  console.log(data.uploadURL);
            requestOptions= {
                method: 'PUT',
                headers: { 'Content-Type': (imageData.type?imageData.type:imageData.data), 'Cache-Control': 'no-cache' },
                body: imageData
            };
            fetch(data.uploadURL, requestOptions)
            .then(data => {
                console.log(data);
                uploadedCallback(true); })})
        .catch(error => { console.log(error); uploadedCallback(false); });   
}

export function fetchStatisticsFromCloud(token, downloadedCallback) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token, 'Cache-Control': 'no-cache' }
    };
    fetch(`${baseURL}/statistics/overview`, requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data); downloadedCallback(data); })
        .catch(error => { console.log(error); downloadedCallback(undefined); });   
}

export function fetchCatalogsFromCloud(token, includeDeleted, downloadedCallback) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token, 'Cache-Control': 'no-cache' }
    };
    fetch(`${baseURL}/catalogs/${includeDeleted?'all':'drafts'}`, requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data); downloadedCallback(data); })
        .catch(error => { console.log(error); downloadedCallback(undefined); });   
}

export function downloadTranslationsFromCloud(token, namespace, lang, downloadedCallback) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token, 'Cache-Control': 'no-cache' }
    };
    fetch(`${baseURL}/translations/${encodeURIComponent(lang)}/${encodeURIComponent(namespace)}`, requestOptions)
        .then(response => (response.status==='404'?{}:response.json()))
        .then(data => { console.log("Downloaded data:"); console.log(data); downloadedCallback(data); })
        .catch(error => { console.log(error); downloadedCallback(undefined); });   
}

export function uploadTranslationsToCloud(token, translations, namespace, lang, publish, uploadedCallback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token },
        body: JSON.stringify( { namespace: namespace, lang: lang, translations: translations, publish} )
    };
    fetch(baseURL+'/translations', requestOptions)
        .then(response => response.json())
        .then(data => { console.log(data); uploadedCallback(true); })
        .catch(error => { console.log(error); uploadedCallback(false); });   
}


export function downloadCatalogUsersFromCloud(token, catalogName, downloadedCallback) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token, 'Cache-Control': 'no-cache' }
    };
    fetch(`${baseURL}/catalogusers/${encodeURIComponent(catalogName)}`, requestOptions)
        .then(response => response.json())
        .then(data => { console.log("Downloaded:"); console.log(data); downloadedCallback(data); })
        .catch(error => { console.log(error); downloadedCallback(undefined); });   
}

export function updateCatalogUserToCloud(token, catalogName, catalogUser, uploadedCallback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token },
        body: JSON.stringify( catalogUser )
    };
    fetch(`${baseURL}/catalogusers/${encodeURIComponent(catalogName)}`, requestOptions)
        .then(response => response.json())
        .then(data => { console.log("update returned:"); console.log(data); uploadedCallback(true); })
        .catch(error => { console.log(error); uploadedCallback(false); });   
}

export function removeCatalogUserFromCloud(token, catalogName, catalogUser, removedCallback) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token }
    };
    fetch(`${baseURL}/catalogusers/${encodeURIComponent(catalogName)}/${catalogUser}`, requestOptions)
        .then(response => response.json())
        .then(data => { console.log("delete returned:"); console.log(data); removedCallback(true); })
        .catch(error => { console.log(error); removedCallback(false); });   
}

export function sendUserInvitation(token, catalogName, username, email, invitation, invitedCallback) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+token },
        body: JSON.stringify( { username: username, email: email, invitation: invitation, catalogName: catalogName } )
    };
    fetch(`${baseURL}/users/invite`, requestOptions)
        .then(response => response.json())
        .then(data => { console.log("invite returned:"); console.log(data); invitedCallback(true); })
        .catch(error => { console.log(error); invitedCallback(false); });   

}