import React, { useEffect, useState } from "react";
import { Typography, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import PlusIcon from "@mui/icons-material/AddCircleOutlined";
import MinusIcon from "@mui/icons-material/RemoveCircleOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import Paper from "@mui/material/Paper";

import uuid from "react-uuid";
import { EntitySelectDialog } from "./EntitySelectDialog";

import { mapStateToProps, mapDispatchToProps } from "./TenderStore";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CatManRulesPanel({ tender = undefined, dispatch }) {
  const { t } = useTranslation();
  const { catalogData, editingProduct } = tender;

  const [rules, setRules] = useState(
    catalogData.rules ? catalogData.rules : []
  );

  const [editingRule, setEditingRule] = useState(undefined);

  useEffect(() => {
    console.log("Registering changes in rules data.");
  }, [catalogData, editingProduct, rules]);

  return (
    <>
      {editingRule !== undefined &&
        rules?.map((r, ir) => {
          console.log(editingRule);
          const entityInputFields = [
            {
              fieldType: "Select",
              entityPrompt: "Product",
              availableOptions: catalogData.products.map((p, index) => ({
                code: p.code,
                name: p.name,
              })),
              selectedOptions: [r.productCode],
              multiselect: false,
            },
            {
              fieldType: "Select",
              entityPrompt: "Option",
              availableOptions: catalogData.options,
              selectedOptions: [r.optionCode],
              multiselect: false,
            },
            {
              fieldType: "Select",
              entityPrompt: "Condition",
              availableOptions: [
                { code: "REQUIRES", name: "" },
                { code: "NOTWITH", name: "" },
                { code: "ONLYONE", name: "" },
                { code: "ATLEASTONE" },
              ],
              selectedOptions: [r.oper],
              multiselect: false,
            },
            {
              fieldType: "Select",
              entityPrompt: "Options",
              availableOptions: catalogData.options,
              selectedOptions: r.optionCodes,
              multiselect: true,
            },
          ];
          return (
            <React.Fragment key={r.uuid}>
              {editingRule === r.uuid + "EDIT" && (
                <EntitySelectDialog
                  entityInputFields={entityInputFields}
                  selectionsChangedCallback={(cancelled, selectionValues) => {
                    console.log("Changes from dialog...");
                    if (cancelled) {
                      console.log("Cancelled");
                    } else {
                      console.log("Rule " + r.uuid + " set new selections: ");
                      console.log(selectionValues);
                      r.productCode = selectionValues[0][0];
                      r.optionCode = selectionValues[1][0];
                      r.oper = selectionValues[2][0];
                      r.optionCodes = selectionValues[3];
                      const newRules = JSON.parse(JSON.stringify(rules));
                      setRules(newRules);
                      dispatch({
                        type: "saveEntity",
                        payload: { entityType: 'rule', entity: r },
                      });
                      setEditingRule("NONEEDIT");
                    }
                  }}
                />
              )}
            </React.Fragment>
          );
        })}

      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 400, maxWidth: "100%" }}
          aria-label="rules table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell component="th">
                <FormControl fullWidth>
                  <InputLabel id="scope-label">{t("Scope")}</InputLabel>
                  <Select
                    labelId="scope-label"
                    id="scope-select"
                    value="all"
                    label={t("Scope")}
                    onChange={async function (e) {
                      console.log(`Selected scope ${e.target.value}`);
                    }}
                  >
                    {["all", "catalog", "product", "option"].map((scope, i) => (
                      <MenuItem key={i} value={scope}>
                        {scope}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell component="th" style={{ width: 100 }}>
                Entity
              </TableCell>
              <TableCell component="th" style={{ width: 100 }}>
                Oper
              </TableCell>
              <TableCell component="th" style={{ width: 200 }}>
                Target Entities
              </TableCell>
              <TableCell component="th"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rules?.map((r, ir) => (
              <TableRow key={r.uuid}>
                <TableCell style={{ verticalAlign: "top", width: 100 }}>
                  <>
                    {r.scope === "product" && r.productCode}
                    {r.scope === "catalog" && r.catalogCode}
                  </>
                </TableCell>
                <TableCell
                  width="10%"
                  style={{ width: 100, verticalAlign: "top" }}
                >
                  <>
                    {r.scope === "product" && r.optionCode && (
                      <Tooltip
                        title={
                          catalogData.options.find(
                            (o) => o.code === r.optionCode
                          )?.name
                        }
                      >
                        <Button>{r.optionCode}</Button>
                      </Tooltip>
                    )}
                    {r.scope === "catalog" && r.optionCode && (
                      <Tooltip
                        title={
                          catalogData.options.find(
                            (o) => o.code === r.optionCode
                          )?.name
                        }
                      >
                        <Button>{r.optionCode}</Button>
                      </Tooltip>
                    )}
                    {r.scope === undefined && r.optionCode && r.optionCode}
                  </>
                </TableCell>
                <TableCell style={{ verticalAlign: "top", width: 100 }}>
                  <>{r.oper}</>
                </TableCell>
                <TableCell style={{ verticalAlign: "top" }}>
                  <>
                    {r.optionCodes?.map((oc, ioc) => (
                      <Tooltip
                        key={r.uuid + oc}
                        title={
                          catalogData.options.find((o) => o.code === oc)?.name
                        }
                      >
                        <Button>{oc}</Button>
                      </Tooltip>
                    ))}
                  </>
                </TableCell>
                <TableCell style={{ verticalAlign: "top", width: 100 }}>
                  <IconButton
                    id={r.uuid + "DEL"}
                    color="inherit"
                    onClick={() => {
                      console.log("Deleting rule row.");
                      const newRules = rules.filter((rr) => rr.uuid !== r.uuid);
                      setRules(newRules);
                      dispatch({
                        type: "stageCatalogRules",
                        payload: { catalogRules: newRules },
                      });
                      console.log("Deleted rule " + r.uuid);
                    }}
                  >
                    <MinusIcon />
                  </IconButton>
                  <IconButton
                    id={r.uuid + "EDIT"}
                    color="inherit"
                    onClick={() => {
                      console.log("Editing rule row " + r.uuid + "EDIT");
                      setEditingRule(r.uuid + "EDIT");
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan="5">
                <IconButton
                  color="inherit"
                  onClick={() => {
                    console.log("Adding rule row.");
                    const newRuleUuid = uuid();

                    setRules(
                      rules.concat([
                        {
                          uuid: newRuleUuid,
                          scope: "product",
                          oper: "REQUIRES",
                          optionCodes: [],
                        },
                      ])
                    );
                    setEditingRule(newRuleUuid + "EDIT");
                  }}
                >
                  <PlusIcon />
                  <Typography variant="body2">Add new rule</Typography>
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CatManRulesPanel);
