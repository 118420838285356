import React, {useEffect, useState} from 'react';
import { Box, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { FormControl, FormControlLabel, Switch, Select, MenuItem } from "@mui/material";
import AgricultureIcon from '@mui/icons-material/AgricultureOutlined';
import BallotIcon from '@mui/icons-material/BallotOutlined';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import ArticleIcon from '@mui/icons-material/ArticleOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined';
import FwdIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import BackIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ExcelIcon from "@mui/icons-material/DownloadOutlined";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";

import useMediaQuery from '@mui/material/useMediaQuery';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'
import ProductCard from './ProductCard';
import { useTranslation } from 'react-i18next'

import { exportTender } from "./ExcelUtils";
import ExportExcel from "./ExportExcel";



import './ProductSelectScreen.css';
import ProductOptionsPanel from './ProductOptionsPanel';
import TenderDetailsPanel from './TenderDetailsPanel';
import TenderSaveAsPanel from './TenderSaveAsPanel';
import { formatISODateTime } from "./UtilFunctions";
import { useUserContext } from './UserContext';
import uuid from 'react-uuid';
import { connect } from 'react-redux';

import { uploadTenderToCloud,downloadCatalogFromCloud } from './TendererDataService';

import {DebugLog} from './DebugLog';
import { ConstructionOutlined } from '@mui/icons-material';
import StatusMessagePopup from './StatusMessagePopup';

const initialProdList=[];

//export default 
function ProductSelectScreen({tender=undefined, dispatch }) {
    const isTenderPreloaded=(tender!==undefined && tender.tenderingProduct!==null);
    const {
        state: { username, token, catalogs, roles },
      } = useUserContext();
    const {
        tenderId, tenderName, catalogName, catalogVersion, tenderStatus, customerRef, tenderingProduct, selectedOptions,
            dealerDiscountPercentage, dealerMarginPercentage, dealerProfitMarginEuros,
            dealerFreightPrice, additionalItems,
            exchangeMachineryPrice, calculatedPriceTables,
            createdAt,
            liteOn, preSelectedProductCode } = tender;
    const loadCatalogName=catalogName;
    const { t } = useTranslation();
    const [products,setProducts]=useState(initialProdList);
    const [currentProduct,setCurrentProduct]=useState(undefined);//products?.find((p)=>p.code===preSelectedProductCode));
    const [currentProductIndex, setCurrentProductIndex] = useState(-1);//products?.findIndex((p)=>p.code===preSelectedProductCode)>-1?products?.findIndex((p)=>p.code===preSelectedProductCode):-1);
    const [showingDownloadButton, setShowingDownloadButton] = useState(false);
    const [phase, setPhase] = useState(isTenderPreloaded?'REVIEWING_TENDER':'SELECTING_PRODUCT');


    // fetch catalog products
    useEffect(() => {
        console.log("Initializing for preselect "+preSelectedProductCode);
        async function fetchProductData() {
            if(false) { // always fetch from server to get current catalog liteOn) {
                // return local
                return await fetch('/assets/catalogs/'+loadCatalogName+'/catalog.json').then(function (response) {
                    
                    return response.json().then(function (catalogData) {
                        setProducts(catalogData.products);
                        dispatch({type: 'initValidationRules', payload: { rules: catalogData.rules}});
                        console.log("RULES:");
                        console.log(catalogData.rules);
                        if(preSelectedProductCode!==undefined)
                            setCurrentProduct(products?.find((p)=>p.code===preSelectedProductCode));
                        else
                            setCurrentProduct(catalogData.products.length>0?catalogData.products[0]:undefined);
                        return;
                    });
                });
            }
            else
            {
                console.log("Fetching catalog "+catalogName+" version "+catalogVersion+" from dataservice with token: "+token);
                // fetch from dataservice
                downloadCatalogFromCloud(token, catalogName, catalogVersion, ((data) => {
                    if(data!==undefined) {
                        console.log("Downloaded from dataservice:");
                        const catalogData=(Array.isArray(data)?data[0]:data);
                        console.log(catalogData);
                        setProducts(catalogData.products);
                        dispatch({type: 'initValidationRules', payload: { rules: catalogData.rules}});
                        console.log("RULES:");
                        console.log(catalogData.rules);
                        
                        if(preSelectedProductCode===undefined)
                            if( tenderingProduct===undefined || tenderingProduct===null)
                                  setCurrentProduct(catalogData.products.length>0?catalogData.products[0]:undefined);
                            else
                                setCurrentProduct(catalogData.products?.find((p)=>p.code===tenderingProduct.code));
                
                    } else {
                        // failure to load.... show
                        StatusAlertService.showError(t('Loading catalog failed!'));
                    }
                    return;
                }                
                ));
            }
        }
        fetchProductData();

          // force scroll to top when loading
                window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
                });

    },[]);

    function refreshTenderingProductPrices() {
        // find product from products in the catalog
        const prod=products.find((p) => p.code === tenderingProduct.code);
        if(!prod)
            {
                DebugLog("Refreshing fails.. product not found in catalog!")
                StatusMessagePopup(t("Product not found in catalog!"));
                return;
            }

        // update product price
        if(tenderingProduct.price!==prod.price)
            DebugLog(`Price changed prod ${prod.code}: ${tenderingProduct.price} -> ${prod.price}`);
        tenderingProduct.price=prod.price;
        // update option prices
        tenderingProduct.options.forEach(opt => {
            const option=prod.options.find((o) => ''+o.code === ''+opt.code);
            if(!option) {
                opt.price=undefined;
                opt.error="NOTINCAT";
                DebugLog("Product option does not exist in catalog: "+opt.code);
            }
            else if (option.price!==opt.price)
                opt.price=option.price;
        });

        // update selectedOption prices
        selectedOptions.map((opt) => {
            // find option
            console.log("price update for opt?");
            console.log(opt);
            const option=prod.options.find((o) => ''+o.code === ''+opt.code);
            console.log(option);
            if(!option) {
                StatusMessagePopup(t("At least one option not available in catalog"));
                opt.price=undefined;
                opt.error="NOTINCAT";
                DebugLog("Selected product option does not exist in catalog: "+opt.code);
            }
            if(opt.price!==option.price)
                DebugLog(`Price changed prod ${prod.code} opt ${opt.code}: ${opt.price} -> ${option.price}`);
            opt.price=option.price;
            });
        
        // update dealer discout to tender?
        const cat=catalogs.find(c => c.name===catalogName && c.version===catalogVersion);
        if(cat) {
            DebugLog(cat);
            DebugLog("User has dealer discount of "+cat.dealerDiscountPercentage);
            dispatch({type: 'setDealerDiscountPercentage', payload: { value: cat.dealerDiscountPercentage }});
        }
        else
            {
                StatusMessagePopup(t("User has no access to catalog"));
                return;
            }
        
        // freight and additionals do not come from catalog


        // force calculations (should move to new action)
        dispatch({type: 'setProductAndOptions', payload: { product: tenderingProduct, options: selectedOptions }});
    }

    // force caroucel to selected product (especially for preselected)
    useEffect(() => {
        if(preSelectedProductCode!==undefined && currentProduct?.code!==preSelectedProductCode) {
            console.log("Changing current prod selection to preSelected")
            setCurrentProduct(products?.find((p)=>p.code===preSelectedProductCode));
            return;
        }
    },[preSelectedProductCode, products]);
    useEffect(() => {
        if(currentProduct!==undefined) {
            console.log("Current Product changed to "+currentProduct?.code);
            // force scroll to top when loading
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
            let curProdInx=products?.findIndex((p)=>p.code===currentProduct?.code);
            if (curProdInx===-1 && currentProduct!==undefined)
                curProdInx=0;
            console.log("CURRENT PRODUCT CHANGED TO INDEX "+curProdInx);
            setCurrentProductIndex(curProdInx);
        }
    },[currentProduct]);

    // DEBUGGING ONLY
    useEffect(() => {
        console.log("Tender changed to:");
        console.log(tender);
    },[tender]);

/*NOT IN LITE    useEffect(() => {
        console.log("Changing phase if preloaded tender is already created ("+createdAt+") and thus loaded stored tender");
        if(createdAt!==undefined)
            setPhase('REVIEWING_TENDER');
    },[createdAt]);
*/
    function saveTender(tenderName, tenderStatus, customerDetails) {
        const nowStr=(new Date()).toISOString();

        const saveWithCustomerRef=customerDetails!==undefined?customerDetails:customerRef;

        console.log("Saving tender "+tenderName+" or inquiry with token: "+token);

        uploadTenderToCloud(
            token,
            {
                tenderId: tenderId!==undefined?tenderId:uuid(),
                tenderName: tenderName,
                catalogName: catalogName,
                catalogVersion: catalogVersion,
                tenderStatus: tenderStatus,
                customerRef: saveWithCustomerRef,
                tenderingProduct: tenderingProduct,    // notice this contains whole project object from the catalog for archival reasons. If catalog changes it is necessary to know what was the product and it's options at the time when tender was made.
                selectedOptions: selectedOptions, 
                additionalItems: additionalItems,
                calculatedPriceTables: calculatedPriceTables,
                dealerFreightPrice: dealerFreightPrice,
                dealerDiscountPercentage: dealerDiscountPercentage,
                dealerMarginPercentage: dealerMarginPercentage,
                dealerProfitMarginEuros: dealerProfitMarginEuros,
                exchangeMachineryPrice: exchangeMachineryPrice,
                createdAt: (createdAt?createdAt:nowStr),
                createdBy: username,
                modifiedAt: nowStr,
                modifiedBy: username
            },
            tenderName,
            (succeeded)=> 
                {
                    //setUploadStatusText
                    console.log((succeeded?t('Tender uploaded to cloud.'):t('Upload failed')));
                    if(succeeded) {
                        StatusAlertService.showSuccess('Tender uploaded successfully.');
                    } else {
                        StatusAlertService.showError('Tender upload failed!');
                    }
                }
            );
    }
    


    function prepareTenderExcelExport() {
    }

    function onChange(index) {
        console.log("onChange carousel index: "+index);
        setCurrentProduct(products[index]);
        setCurrentProductIndex(index);
    }
    function selectProduct(product) {
        console.log("Product selected: ")
        console.log(product!==undefined?product:'');
        dispatch({ type: 'setProduct', payload: { product: product, catalogName: loadCatalogName, createdBy: username, createdAt: formatISODateTime('now') }});
        //not product specific dispatch({type: 'setDealerDiscountPercentage', payload: { value:  }});

    }
    const widthSmallerThan767=useMediaQuery('(max-width: 767px)');
    const widthSmallerThan413=useMediaQuery('(max-width: 413px)');
    const workWidth=widthSmallerThan413?'100%':widthSmallerThan767?'100%':800;

    const [showDealerPrices, setShowDealerPrices] = useState(true);
        
    return (
        <div align="center" style={{ margin:0 }}>
           <StatusAlert/>

        {
            {
            'SELECTING_PRODUCT':
                <div style={{ boxShadow: 0, marginTop: 0, width: workWidth } }>

                { preSelectedProductCode===undefined &&
                    <>
                    <FormControl sx={{ width: '100%', marginTop: 5 }}>
                    <Select
                        labelId="prodselect-label"
                        id="prod-select"
                        variant="standard"
                        multiple={false}
                        value={ (currentProductIndex>-1?currentProductIndex:"") }
                        label={t('Select')}
                        onChange={async function (e) {
                            console.log(`Selected product from dropdown ${e.target.value}`);
                            console.log(e.target);
                            setCurrentProductIndex(e.target.value);
                        }}
                    >
                        {products.map((p, i) => <MenuItem key={p.code} value={i}>{`${p.name} (${p.code})` }</MenuItem>
                        )}
                    </Select>
                    </FormControl>
                    </>
                }


                    <Carousel
                        autoPlay={false} 
                        interval={60000}
                        dynamicHeight={false}
                        swipeable={true}
                        emulateTouch={true}
                        showThumbs={false}
                        showIndicators={false}
                        onChange={onChange} 
                        width={workWidth}
                        style={{height: '100%'}}
                        selectedItem={currentProductIndex}
                        showArrows={true}
                        preventMovementUntilSwipeScrollTolerance={true}
                        >
                        { products.map((product, index) => {
                            return (
                                <div key={index}>
                                    <img src={ `${window.location.hostname === 'localhost' ? 'https://tenderer.cappgw.com' : ''}/assets/catalogs/${loadCatalogName}/assets/productimages/${product.image}` } alt=""/>
                                <ProductCard catalog={loadCatalogName} product={product} key={index} size={{width: 
                                workWidth,
                                height:'100%'}}/>
                                </div>
                            );
                            })
                        }
                    </Carousel>
                </div>,
            'SELECTING_OPTIONS':
                <>
                                <ProductOptionsPanel product={ tenderingProduct} />
                </>,
            'FILLING_TENDER':
                <TenderDetailsPanel  width={workWidth} />,
            'REVIEWING_TENDER':
                <TenderDetailsPanel readOnly={true} showDealerPrices={showDealerPrices} width={workWidth} />,
            'SAVING_TENDER':
                <TenderSaveAsPanel 
                    defaultName={ tenderName!==undefined?tenderName:(customerRef?.customerName!==undefined?customerRef.customerName+' ':'' ) + 
                                (tenderingProduct && tenderingProduct.name && tenderingProduct.name +' ') +
                                formatISODateTime(createdAt) }
                    defaultStatus= { tenderStatus }
                    lite={liteOn}
                    saveTenderCallback={saveTender}
                />
            }[phase]
        }
    

            <Box sx={{ flexGrow: 1 }}>
            <Toolbar />
                <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
                    <Toolbar>
                        <>
                        { 
                            {
                                'SELECTING_PRODUCT': null,
                                'SELECTING_OPTIONS':                                 
                                    <IconButton color="inherit" onClick={ () => {  setPhase('SELECTING_PRODUCT'); dispatch({ type: 'clearOptions' }); }} ><BackIcon/><AgricultureIcon/></IconButton>,
                                'FILLING_TENDER':
                                    <IconButton color="inherit" onClick={ () => {  setPhase('SELECTING_OPTIONS'); }} ><BackIcon/><BallotIcon/></IconButton>,
                                'REVIEWING_TENDER':
                                    <IconButton color="inherit" onClick={ () => {  setPhase(liteOn?'SELECTING_OPTIONS':'FILLING_TENDER'); }} ><AssignmentIcon/><BackIcon/></IconButton>,
                                'SAVING_TENDER':
                                    <IconButton color="inherit" onClick={ () => {  setPhase('REVIEWING_TENDER'); }} ><AssignmentIcon/><BackIcon/></IconButton>
                            }[phase]
                        }
                        { phase==='SELECTING_OPTIONS'?
                            <Typography sx={{ flexGrow: 1 }} color="inherit">{t('Product')}: { tenderingProduct!==undefined?tenderingProduct?.name:'-' }</Typography>
                            :
                            <>
                            <Typography sx={{ flexGrow: 1 }} color="inherit"> </Typography>
                            </>
                        }
                        {
                            {
                                'SELECTING_PRODUCT': 
                                    <IconButton color="inherit" onClick={ () => {  setPhase('SELECTING_OPTIONS'); selectProduct(currentProduct); }} ><BallotIcon/><FwdIcon/></IconButton>,
                                'SELECTING_OPTIONS':                                 
                                    <IconButton color="inherit" onClick={ () => {   setPhase(liteOn?'REVIEWING_TENDER':'FILLING_TENDER'); }} ><AssignmentIcon/><FwdIcon/></IconButton>,
                                'FILLING_TENDER':
                                <>
                                <IconButton color="inherit" onClick={ () => {  setPhase('REVIEWING_TENDER'); }} ><ArticleIcon/><FwdIcon/></IconButton>
                                </>,
                                'REVIEWING_TENDER':
                                <>
                                                      {liteOn === false && 
                                                        <>
                      <FormControlLabel
                          size="small"
                          labelPlacement="top"
                          control={<IconButton
                        color="inherit"
                        disabled={tenderStatus!=="NEW" && tenderStatus!=="INQUIRY"}
                        onClick={() => {
                            refreshTenderingProductPrices();
                        }}
                      >
                        <RefreshIcon />
                      </IconButton>}
                          label={t("Refresh Prices")}
                        />

                      <FormControlLabel
                          size="small"
                          labelPlacement="top"
                          control={
                            <Switch
                              defaultChecked={
                                showDealerPrices !== null &&
                                showDealerPrices === true
                              }
                              color="default"
                              onChange={(event) => {
                                console.log(
                                  "Setting showDealerPrices:" +
                                    event.target.checked
                                );
                                setShowDealerPrices(event.target.checked);
                              }}
                            />
                          }
                          label={t("Dealer Prices")}
                        />
                        </>
                    }
                      <FormControlLabel
                          size="small"
                          labelPlacement="top"
                          control={<IconButton
                        color="inherit"
                        onClick={() => {
                          exportTender(
                            tender,
                            t
                          ); /*prepareTenderExcelExport(); setShowingDownloadButton(true);*/
                        }}
                      >
                        <ExcelIcon />
                      </IconButton>}
                          label={t("Excel export")}
                        />
                      {showingDownloadButton && <ExportExcel tender={tender} />}
                                    <IconButton color="inherit" onClick={ () => {  setPhase('SAVING_TENDER'); }} ><CloudUploadIcon/><FwdIcon/></IconButton>
                                </>
                            }[phase]
                        }
                        </>
                   </Toolbar>
                </AppBar>
            </Box>
        </div>
    );
}


const mapStateToProps = state => ({
    tender: state.tender,
});

const mapDispatchToProps = dispatch => ({
    dispatch: (action) => dispatch({type:action.type, payload:action.payload})});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelectScreen);