import React from 'react';

import { Typography, Box, IconButton, Tooltip } from '@mui/material';
import ArticleIcon from '@mui/icons-material/ArticleOutlined';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import OpenCopyIcon from '@mui/icons-material/ContentCopyOutlined';
import QuestionIcon from '@mui/icons-material/QuestionAnswerOutlined';
import AnswerIcon from '@mui/icons-material/QuestionAnswer';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from 'react-i18next';
import { formatISODateTime } from './UtilFunctions';


export default function StoredTendersPanel({showPopup=false, sx, storedTenders=[], selectStoredTenderCallback}) {

  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [rowCount, setRowCount] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    if(storedTenders!==null) {
      //console.log(storedTenders.length);
      setRowCount(storedTenders.length);
    }
  },[storedTenders]);

    return (
      <TableContainer component={Paper} sx={sx} style={{ height: 'auto' }}>
      <Table
        sx={{ maxWidth:'100%', margin: 'auto', paddingLeft:2, paddingRight:2}}
        aria-label="stored tenders table"
        size="small"
        stickyHeader 
      >
        <TableHead>
          <TableRow>
          <TableCell sx={{width:'20%'}} align="center"><Typography variant="subtitle2">{t('Tender')}</Typography></TableCell>
            <TableCell sx={{width:'50%'}}><Typography variant="subtitle2">{t('Details')}</Typography></TableCell>
            <TableCell sx={{width:'25%'}} align="center"><Typography variant="subtitle2">{t('Modified')}</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        { storedTenders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tender,index) => {
          //console.log(tender.tenderId);
          return (
            <TableRow
            key={tender.tenderId}
          >
            <TableCell component="td" sx={{width:'20%', justifyContent: "center"}}>
              <Typography variant="caption">{tender.tenderStatus===undefined?'TENDERED':tender.tenderStatus}</Typography>
              <Box sx={{display: 'flex', flexDirection: 'column'}} >
            <Tooltip title={t('Open')}>
            <IconButton color="inherit" touch="true" onClick={ () => {  selectStoredTenderCallback(tender.tenderId,'OPEN'); }} ><ArticleIcon/></IconButton>
            </Tooltip>
            <Tooltip title={t('Open a Copy')}>
                        <IconButton color="inherit" touch="true" onClick={ () => {  selectStoredTenderCallback(tender.tenderId,'OPENCOPY'); }} ><OpenCopyIcon/></IconButton>
                        </Tooltip>
                        <Tooltip title={t('Delete')}>
            <IconButton color="inherit" touch="true" onClick={ () => {  selectStoredTenderCallback(tender.tenderId,'DELETE'); }} ><DeleteIcon/></IconButton>
            </Tooltip>
            </Box>

            </TableCell>
            <TableCell component="td" >
            <Box sx={{display: 'flex', flexDirection: 'column'}} >
              <Typography variant="body2">{tender.tenderName}</Typography>
              <Typography variant="caption">{t('Catalog')}: {tender.catalogName}</Typography>
              </Box>
            </TableCell>
            <TableCell component="td">
                  <Box sx={{display: 'flex', flexDirection: 'column'}} >
                <Typography variant="caption">{formatISODateTime(tender.modifiedAt,false)}</Typography>
                <Typography variant="caption">{tender.modifiedBy}</Typography>
                </Box>
            </TableCell>
            </TableRow>
          )
        })
        }
        </TableBody>
        </Table>
        <TablePagination
        sx={{ verticalAlign: 'bottom',
        display: 'inline-flex', height:"auto" }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={rowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
        </TableContainer>
    );
}  
