import React, { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {Checkbox, IconButton, Typography, Box} from "@mui/material";
import DownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import UpIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import WarningIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorIcon from '@mui/icons-material/WarningOutlined';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Paper from "@mui/material/Paper";

import "./ProductCard.css";

import { useTranslation } from "react-i18next";

//import { useTender } from './TenderContext';
import { connect } from 'react-redux';

import { getLocalizedCatTextIfExists } from "./UtilFunctions";

import ImagePanel from './ImagePanel';
import useMediaQuery from '@mui/material/useMediaQuery';

import {DebugLog} from './DebugLog';

//export default
 function ProductOptionsPanel({
   tender=[],dispatch
}) {
  //const {dispatch} = useTender();
  const { t } = useTranslation();
  const widthSmallerThan767=useMediaQuery('(max-width: 767px)');
  const widthSmallerThan413=useMediaQuery('(max-width: 413px)');
  const workWidth=widthSmallerThan413?'100%':widthSmallerThan767?'100%':800;

  const {
    catalogName, tenderingProduct, selectedOptions, validationRules, liteOn }=tender;

  const [validationErrors,setValidationErrors] = useState([]);

  const [openedOptionDetails, setOpenedOptionDetails] = useState('none');

  // force scroll to top when loading
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
  // evaluate validation rules
  // for each selected option check their validation rules
  DebugLog("Running validation checks...");

  DebugLog("For product "+tenderingProduct.name+" and options:");
  DebugLog(selectedOptions);
  DebugLog("Validation rules:");
  DebugLog(validationRules);
    const newValidationErrors=[];
    selectedOptions.forEach((o) => { 
      DebugLog("");

      validationRules && validationRules.forEach((r) => {
        if(r.scope==='product' && r.productCode===tenderingProduct.code && r.optionCode===o.code) {
          DebugLog(r);
          r.optionCodes.forEach((targetOptionCode) => {
            if(r.oper==='REQUIRES' && selectedOptions.find(topt => topt.code===targetOptionCode)===undefined) {
              // add warning
              newValidationErrors.push({severity:'WARNING', optionCode: r.optionCode, oper: 'REQUIRES', targetOptionCode: targetOptionCode});
            } else if(r.oper==='NOTWITH' && selectedOptions.find(topt => topt.code===targetOptionCode)!==undefined) {
              // add error
              newValidationErrors.push({severity:'ERROR', optionCode: r.optionCode, oper: 'NOTWITH', targetOptionCode: targetOptionCode});
            }
          });
        }

      });
    }
    ); 
    DebugLog("Validation Errors:");
    DebugLog(newValidationErrors);
    setValidationErrors(newValidationErrors);

  }, [selectedOptions]);


  function setOptionSelected(optionCode,selected) {
    if(selected===false) {
      // find from selectedOptions and remove
      dispatch({type: 'setProductAndOptions',payload: { product: tenderingProduct, options: selectedOptions.filter(to => to.code!==optionCode) }});
    } else {
      // find option from product options and add to selectedOptions
      dispatch({type: 'setProductAndOptions', payload: { product: tenderingProduct, options: selectedOptions.concat([tenderingProduct.options.find(o => o.code===optionCode)]) }});
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ width: workWidth }}
        aria-label="options table"
        size="medium"
      >
        <TableHead>
          { liteOn===false?
          <TableRow>
          <TableCell width="30%">{t('Code')} / {t('Select')}</TableCell>            
            <TableCell width="40%" align="left">{t('Option')}</TableCell>
            <TableCell width="30%" align="right">{t('Price')}</TableCell>
            </TableRow>
            :
            <TableRow>
            <TableCell width="40%">{t('Code')} / {t('Select')}</TableCell>            
            <TableCell width="60%" align="left">{t('Option')}</TableCell>
            </TableRow>
            }
        </TableHead>
        <TableBody>
          {tenderingProduct.options.map((option,index) => (
            <TableRow
              key={option.code}
            >
              <TableCell component="th" scope="row"  style={{
                      whiteSpace: "normal",
                      wordBreak: "break-word"
                    }} >
                {option.code}
                <Box style={{flexDirection:'column'}}>
                <Checkbox
                  color="primary"
                  checked={(selectedOptions.some(o => o.code === option.code))}
                  onChange={ (event) => {
                      DebugLog("Checkbox change for "+option.code);
                      if(selectedOptions.includes(option)) {
                        dispatch({type: 'setProductAndOptions', payload: { product: tenderingProduct, options: selectedOptions.filter(o => o.code !== option.code) }});
                      } else {
                        dispatch({type: 'setProductAndOptions', payload: { product: tenderingProduct, options: selectedOptions.concat([option]) }});
                      }
                  }}
                />
                { validationErrors.filter(e => e.optionCode===option.code && e.severity==='WARNING').length>0 &&
                <WarningIcon style={{color:'#F6BE00'}}/>
                }
                { validationErrors.filter(e => e.optionCode===option.code && e.severity==='ERROR').length>0 &&
                <ErrorIcon style={{color:'#AB2328'}} />
                }
                </Box>
              </TableCell>
              <TableCell align="left">
                {getLocalizedCatTextIfExists(option.name,t,catalogName,{code:option.code},'OPT-TITLE') }
                { option.image && openedOptionDetails===option.code &&
                <>
                <IconButton color="inherit" onClick={ () => { setOpenedOptionDetails('none') }}><UpIcon /></IconButton>
                <ImagePanel width={300} imageSrc={(window.location.hostname==='localhost'?'https://tenderer.cappgw.com':'')+'/assets/catalogs/'+catalogName+'/assets/optionimages/'+option.image} />
                </>
                }
                { option.image && openedOptionDetails==='none' &&
                <IconButton color="inherit" onClick={ () => { setOpenedOptionDetails(option.code) }}><DownIcon /></IconButton>
                }
                { validationErrors.filter(e => e.optionCode===option.code && e.oper==='REQUIRES').map((e,einx) => 
                  <Typography key={einx} variant="body1" style={{color: 'rgba(171,35,40,0.9)' }}>
                    { t('Requires')+' '+e.targetOptionCode }<IconButton color="inherit" onClick={ () => { setOptionSelected(e.targetOptionCode,true) }}><AddIcon /></IconButton>
                  </Typography>
                )}
                { validationErrors.filter(e => e.optionCode===option.code && e.oper==='NOTWITH').map((e,einx) => 
                  <Typography key={einx} variant="body1" style={{color: 'rgba(171,35,40,0.9)' }}>
                    { t('Not compatible with')+' '+e.targetOptionCode }<IconButton color="inherit" onClick={ () => { setOptionSelected(e.targetOptionCode,false) }}><RemoveIcon /></IconButton>
                  </Typography>
                )}
                
              </TableCell>
              { liteOn===false &&
              <TableCell align="right">{(option.price*1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+' €'}</TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}





const mapStateToProps = state => ({
  tender: state.tender,
});

const mapDispatchToProps = dispatch => ({
  dispatch: (action) => dispatch({type:action.type, payload:action.payload})});


export default connect(mapStateToProps, mapDispatchToProps)(ProductOptionsPanel);