import React, { useEffect, useState } from "react";
import { TextField, Button, Typography,IconButton } from "@mui/material";
import SaveIcon from '@mui/icons-material/SaveOutlined';
import UndoIcon from '@mui/icons-material/ReplayOutlined';
import PlusIcon from "@mui/icons-material/AddCircleOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import CancelIcon from "@mui/icons-material/CancelOutlined";

import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { useUserContext } from "./UserContext";
//import CatManImagesPanel from "./CatManImagesPanel";
import CatManImagesSimplePanel from "./CatManImagesSimplePanel";

import { mapStateToProps, mapDispatchToProps } from "./TenderStore";


/* depends on:
- CatalogManagerContext 
- Translation

Props:
entity
entityType
entityDisplayNames array of strings used instead of attr names in ordered list (entitytitle, code, name, description, attr1...)
catalogData
catalogDispatch
*/

function CatManEntityEditorPanel({tender, dispatch, subDetailPanels, entity, entityType, attributes}) {
    const {state: { username }} = useUserContext();
    const { catalogData, selectedEntity, productOptions, productAttachments, productStandardParts, selectedEntityModified} = tender;
    const {t} = useTranslation(); 
    const [newCodeKey, setNewCodeKey] = useState('');
    const [showEnterNewCodeKey, setShowEnterNewCodeKey] = useState(false);
    const [errorInCodeValue,setErrorInCodeValue] = useState(false);

    const [showSubDetailPanel,setShowSubDetailPanel] = useState(subDetailPanels?.length>0?0:-1);  // default open first panel if exists

    const [showImageUploadPanel, setShowImageUploadPanel] = useState(true);  // always since using simple panel

    useEffect(()=> {
            if(selectedEntity!==undefined) {
                console.log("entity change registered.");
                console.log(selectedEntity);
//NO NEED                setEntity(JSON.parse(JSON.stringify(selectedEntity))); // local copy
            }
        },[selectedEntity,productOptions,productStandardParts,productAttachments,selectedEntityModified]
    );

    /*
    useEffect(()=>
    {
        dispatch({type:'flagEntityModified'});
    },[entity]);
    */
    function resetEntity() {
        console.log("Resetting");
        dispatch({type:'resetStagedChanges'});
    }

    function saveEntity(entity) {
        dispatch({type:'saveEntity', payload: {entityType: entityType, entity: entity, modifiedBy: username}});
        // SELECTED ALREADY dispatch({type:'selectEntity', payload: { entityType: entityType, entityCode: selectedEntity.code }});
    }

    return (
    <React.Fragment>
        <IconButton color="inherit" 
            disabled={selectedEntityModified?false:true}
            onClick={ () => {
                console.log("Saving changes to memory model.");
                saveEntity(selectedEntity);
                //setCatalogDataChanged(false);
              }} >
            <SaveIcon/><Typography variant="body2">Commit changes</Typography>
        </IconButton>

{ /* FOR NOW NO PROD LEVEL UNDO */ false &&
        <IconButton color="inherit" 
            disabled={selectedEntityModified?false:true}
            onClick={ () => {
                console.log("Undoing changes to memory model.");
                // dispatch reset changes (init entity)
                //setCatalogDataChanged(false);
                resetEntity();
              }} >
              <UndoIcon/><Typography variant="body2">Undo changes</Typography>
        </IconButton>
}
            { attributes[0][0]==='code' && attributes[0][2]==='STRINGID' &&
                <React.Fragment>
                    <IconButton color="inherit" onClick={() => {
                    console.log("Show alter code value dialog");    
                    setShowEnterNewCodeKey(true);
                  }}><EditIcon /><Typography variant="body2">Change code key</Typography></IconButton>
                </React.Fragment>
            }


        <div style={{display:'flex', flexDirection:'column'}}>

        { showEnterNewCodeKey &&
        <>
            <TextField
            id={'entity-enternewcodekey'}
            label={t('Enter new code key')}
            helperText={t('Warning: changing code key will break rules and relations for the entity. Code may not contain underscore (_)!')}
            multiline={false}
            fullWidth={true}
            type={'text'}
            variant="standard"
            defaultValue={newCodeKey}
            error={errorInCodeValue}
            onChange={(e) => {
                if(e.target.value.includes("_")) {
                    setErrorInCodeValue(true);
                } else {
                    setErrorInCodeValue(false);
                    setNewCodeKey(e.target.value);
                }
            }}
            />
            <div style={{display:'flex', flexDirection:'row', justifyContent:'right'}}>
            <IconButton color="inherit" onClick={() => {
                setNewCodeKey('');
                setShowEnterNewCodeKey(false);
              }}><CancelIcon /><Typography variant="body2">Cancel</Typography></IconButton>
            <IconButton color="inherit" onClick={() => {
                const changedEntity=entity;
                changedEntity.code=newCodeKey;
                saveEntity({...changedEntity});
                setShowEnterNewCodeKey(false);
              }}><SaveIcon /><Typography variant="body2">Save</Typography></IconButton>
            </div>
            </>
        }

        { entity && attributes.map((attr,i) =>
            <React.Fragment key={attr[0]}>
            <TextField
            id={'entity-'+entity[attr[0]]+'-'+attr[0]}
            label={t(attr[1]?attr[1]:attr[0])}
            fullWidth
            disabled={attr[2]==='READONLY' || attr[2]==='STRINGID'}
            multiline={attr[2]==='STRINGMULTILINE'?true:false}
            type={attr[2]==='NUMBER' || attr[2]==='MONEY'?'number':'text'}
            variant="standard"
            value={entity[attr[0]]}
            onChange={(e) => {
                const changedEntity=entity;
                const val=(attr[2]==='NUMBER' || attr[2]==='MONEY'?Number(e.target.value):e.target.value);
                changedEntity[attr[0]]=val;
                //dispatch({type:'flagEntityModified'});
                saveEntity({...changedEntity});
                entity={...entity};
            }}
            />
            { attr[0]==='image' &&
                <React.Fragment>
                {entity['image']!==undefined &&
                    <img key="imagefile" width={200} src={(window.location.hostname==='localhost'?'https://tenderer.cappgw.com':'')+'/assets/catalogs/'+catalogData.name+'/assets/'+entityType+'images/'+entity['image']} alt="" />
                }
                { !showImageUploadPanel?
                <IconButton color="inherit" onClick={() => {
                    console.log("Show add image panel");
                    setShowImageUploadPanel(true);
                  }}><PlusIcon /><Typography variant="body2">Change image</Typography></IconButton>
                :
                  <CatManImagesSimplePanel entity={entity} entityType={entityType} onUploadDone={
                      (imgname) => {
                        if(imgname!==undefined)
                        {
                            const changedEntity=entity;
                            changedEntity['image']=imgname;
                            dispatch({type:'flagEntityModified'});
                            saveEntity({...changedEntity});
                        }
                        //setShowImageUploadPanel(false);      
                      }
                  }/>
                }
                </React.Fragment>
          }
            { attr[0]==='logo' &&
                <React.Fragment>
                {entity['logo']!==undefined &&
                    <img key="imagefile" width={200} src={(window.location.hostname==='localhost'?'https://tenderer.cappgw.com':'')+'/assets/catalogs/'+catalogData.name+'/assets/'+entity['logo']} alt=""/>
                }
                
                { !showImageUploadPanel?
                <IconButton color="inherit" onClick={() => {
                    console.log("Show add image panel");
                    setShowImageUploadPanel(true);
                  }}><PlusIcon /><Typography variant="body2">Change logo image</Typography></IconButton>

                  :

                  <CatManImagesSimplePanel entityType="catalog" onUploadDone={
                      (imgname) => {
                        if(imgname!==undefined)
                        {
                            const changedEntity=entity;
                            changedEntity['logo']=imgname;
                            dispatch({type:'flagEntityModified'});
                            saveEntity({...changedEntity});
                        }
                        //setShowImageUploadPanel(false);      
                      }
                  }/>
                }
                </React.Fragment>
          }

            </React.Fragment>
        )

        }





        </div>
        <div style={{marginTop:10, flexDirection:'row'}}>
        { subDetailPanels && subDetailPanels.map((panel,index) =>
        <React.Fragment key={index} >
            <Button  variant={showSubDetailPanel===index?'contained':'outlined'} onClick={(e)=>{ setShowSubDetailPanel(index); }}>
                { subDetailPanels[index].name }
            </Button>
        </React.Fragment>
        )}
        </div>

        { subDetailPanels && showSubDetailPanel>-1 &&
        <div style={{border:'1px solid', borderRadius: 10, backgroundColor: '#c8c8c8', borderColor: '#a8a8a8', marginBottom:5}} >
            {subDetailPanels[showSubDetailPanel].content}
        </div>
        }
        </React.Fragment>
    );
}

const mapStateAndOwnPropsToProps = (state, ownProps) => {
    const tender = state.tender;
    return {
      tender,
      subDetailPanels:ownProps.subDetailPanels, 
      entity:ownProps.entity, 
      entityType:ownProps.entityType, 
      attributes:ownProps.attributes,
    }
  };
  

export default connect(mapStateAndOwnPropsToProps, mapDispatchToProps)(CatManEntityEditorPanel);